import { Link as RouterLink, useParams } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { Loading, LoadingFailed } from "../components/loading";
import { Space } from "~/lib/types";
import {
  AggregateScoreType,
  DocumentType,
  FindingType,
  ScoreRating,
  ScoreStateFilter,
  TestIamActionsQuery,
  useGetAggregateScoresQuery,
  useLoadCveQuery,
} from "~/operations";
import { HealingIcon, HomeIcon } from "~/components/icons";
import { CveRiskFactors, CveSources } from "~/components/vulnerabilities";
import { FlagOutlined, Radar } from "@mui/icons-material";
import { useInventory } from "./inventory/hooks/useInventory";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { SectionHeading } from "~/components/DetailsPage/components";

import { useFindingRiskFactors } from "./space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { ScoreBlock } from "~/pages/space/security/components/Check/ScoreBlock";
import { isFeatureEnabled } from "~/login/features";
import { AdvisoriesTable } from "~/pages/space/vulnerabilities/components/Vulnerability/AdvisoriesTable";

import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { DetectedBy } from "~/components/vulnerabilities/DetectedBy/DetectedBy";
import { DetailPageTopSection } from "~/components/DetailPageLayouts/DetailPageTopSection";
import { Fragment } from "react/jsx-runtime";
import { ExportButton } from "./compliance/components/export-framework/export-button";
import {
  AffectedAssetsAdapter,
  AssetContextualLinkType,
} from "~/pages/space/vulnerabilities/components/AffectedAssets";

export type SpaceVulnerabilityPageProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const SpaceVulnerabilityPage = ({
  space,
  scope,
}: SpaceVulnerabilityPageProps) => {
  const { id = "" } = useParams();
  const { assetsTotalCount } = useInventory({ scope });

  const { data, loading, error } = useLoadCveQuery({
    variables: { id },
  });

  const { data: aggScoreData, loading: aggScoreLoading } =
    useGetAggregateScoresQuery({
      variables: {
        entityMrn: scope.mrn || "",
        filter: {
          findingMrn: data?.cve?.mrn,
        },
      },
      skip: !data?.cve?.mrn || !scope.mrn,
    });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: String(data?.cve?.mrn || ""),
    scoreType: AggregateScoreType.Vulnerability,
  });

  if (loading || aggScoreLoading || riskFactorsLoading) {
    return <Loading what="Vulnerability" />;
  }

  const cve = data?.cve;
  const aggScore =
    aggScoreData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? aggScoreData.aggregateScores.edges?.at(0)?.node
      : undefined;

  // When no aggregateScore exists, we fallback to the cvssScore of the CVE.
  let fallbackCvssScore = undefined;
  if (!aggScore) {
    if (cve?.cvssScore.value) {
      // If the CVE has no cvssScore - the scoreblock will fallback further to UNKNOWN
      fallbackCvssScore = cve?.cvssScore;
    }
  }

  if (error || !cve) {
    return <LoadingFailed what="Vulnerability" />;
  }

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/vulns"
      component={RouterLink}
      to={`/space/findings/cves?${scope.params}`}
      display="flex"
    >
      Vulnerabilities
    </Link>,
    <Typography key={"/space/vulns/cve/:cve"}>{cve.id}</Typography>,
  ];

  const totalScanned = assetsTotalCount;
  const totalAffected = aggScore?.blastRadius?.affected || 0;
  const totalRemediated =
    aggScore?.blastRadius?.assets && aggScore?.blastRadius?.affected
      ? (
          aggScore?.blastRadius?.assets - aggScore.blastRadius?.affected
        ).toString()
      : "---";
  const totalExceptions = (
    (aggScore?.blastRadius?.snoozed || 0) +
    (aggScore?.blastRadius?.disabled || 0)
  ).toString();

  const stats: StatsProps["stats"] = [
    {
      label: "Scanned",
      value: totalScanned < 0 ? "---" : totalScanned.toString(),
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Affected",
      value: totalAffected < 0 ? "---" : totalAffected.toString(),
      icon: <FlagOutlined fontSize="inherit" />,
      onClick: () => {
        document
          .querySelector(`#affected-assets`)
          ?.scrollIntoView({ behavior: "smooth" });
      },
    },
    {
      label: "Remediated",
      value: totalRemediated,
      icon: <HealingIcon fontSize="inherit" />,
    },
    // {
    //   label: "Exceptions",
    //   value: totalExceptions,
    //   icon: <BlockOutlined fontSize="inherit" />,
    // },
    // {
    //   label: "Updated",
    //   count: -1, // TODO
    //   icon: <ZoomOutMap fontSize="inherit" />,
    // },
  ];

  document.title = `${cve.id} · CVEs · Mondoo`;

  return (
    <Box>
      <DetailPageTopSection
        content={{
          breadcrumbs: breadcrumbs,
          header: {
            id: "space-vulnerability-header",
            title: cve.id,
            riskFactors,
            rightSection: (
              <Fragment>
                {isFeatureEnabled("Reporting") && (
                  <ExportButton
                    documentType={DocumentType.VulnerabilityReport}
                    title={cve.id || "Generated Vulnerability Report"}
                    space={space}
                    vulnerabilityId={cve.id}
                  />
                )}
              </Fragment>
            ),
          },
          summary: cve.summary,
          stats: stats,
          left: {
            upper: <DetectedBy detectedBy={aggScore?.detectionSources} />,
            lower: (
              <CveSources
                id="cve-sources"
                url={cve.url}
                source={cve.source}
                references={cve.references}
              />
            ),
          },
          right: (
            <ScoreBlock
              hasScore={Boolean(aggScore)}
              mainScore={aggScore?.riskScore}
              cvssScore={aggScore?.cvss || fallbackCvssScore}
              epssScore={aggScore?.epss}
              rating={aggScore?.rating || ScoreRating.None}
              blastRadius={aggScore?.blastRadius}
              riskFactors={aggScore?.riskFactors}
              hasError={
                Boolean(aggScore) && aggScore?.__typename !== "AggregateScore"
              }
            />
          ),
        }}
      />

      <Box id="risk-factors" className="section">
        <SectionHeading heading="Risk assessment" />
        <Box className="section-content">
          <CveRiskFactors
            id="cve-risk-factors"
            cvssScore={cve.cvssScore}
            epssScore={cve.epssScore}
            riskFactors={riskFactorsWithDocs}
          />
        </Box>
      </Box>

      {/* TODO: Need API support */}
      {/* <Box id="remediation" className="section">
        <SectionHeading heading="Remediation" />
        <Box className="section-content">
          <CveRemediation />
        </Box>
      </Box> */}

      <Box id="cve-advisories" className="section">
        <SectionHeading heading="Related advisories" />
        <Box className="section-content">
          <AdvisoriesTable space={space} scope={scope} />
        </Box>
      </Box>

      <AffectedAssetsAdapter
        scope={scope}
        contextId={id}
        urlContextType={AssetContextualLinkType.Cve}
        filter={{
          mrn: String(cve.mrn),
          types: [FindingType.Cve],
          state: ScoreStateFilter.Open,
          queryTerms: [cve.id],
        }}
        emptyStateMessage="There are currently no affected assets for this CVE."
      />
    </Box>
  );
};
