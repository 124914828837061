import { Route, Routes } from "react-router-dom";
import { InventoryPage } from "~/pages/inventory/inventory";
import { Space } from "~/lib/types";
import { AggregateScoreType, TestIamActionsQuery } from "~/operations";
import { AssetRoutes } from "./assetRoutes/assetRoutes";
import { QueryPacksRoutes } from "./query-packs-routes";
import { SpaceOrWorkspaceScope, SpaceScope } from "~/hooks/useScope";
import { FirewatchPage } from "~/components/FirewatchPage";
import { Software } from "~/pages/space/software/Software/Software";

type Props = {
  scope: SpaceOrWorkspaceScope;
  spaceScope: SpaceScope;
  space: Space; // TODO: Deprecated prop
  availablePermissions: TestIamActionsQuery["testIamActions"]; // TODO: Deprecated prop
};

export function InventoryRoutes({
  scope,
  spaceScope,
  space,
  availablePermissions,
}: Props) {
  return (
    <Routes>
      <Route
        index
        element={<InventoryPage scope={scope} spaceScope={spaceScope} />}
      />
      <Route
        path="/query-packs/*"
        element={
          <QueryPacksRoutes {...{ space, scope, availablePermissions }} />
        }
      />
      <Route path="software">
        <Route
          index
          element={
            <FirewatchPage
              {...{
                space: spaceScope,
                scope,
                pageType: AggregateScoreType.Software,
              }}
            />
          }
        />
        <Route
          path=":softwareId"
          element={<Software {...{ space: spaceScope, scope }} />}
        />
      </Route>
      <Route path="/*" element={<AssetRoutes {...{ space, scope }} />} />
    </Routes>
  );
}
