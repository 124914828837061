import { AggregateScoreType } from "~/operations";
import { AdjustedAggregateScoreType } from "..";

export const pageNameByType = (type: AdjustedAggregateScoreType) => {
  switch (type) {
    case AggregateScoreType.Asset:
      return "Affected Assets";
    case AggregateScoreType.Vulnerability:
      return "CVEs";
    case AggregateScoreType.Advisory:
      return "Advisories";
    case AggregateScoreType.Check:
      return "Security checks";
    case AggregateScoreType.Software:
      return "Software";
    default:
      return "";
  }
};
