import {
  alpha,
  Box,
  Container,
  Grid2,
  List,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Stats } from "~/components/DetailsPage/components";
import { spacing } from "../SharedVariables";
import { SimpleReportHeader } from "../../components";
import { useSecurityReport } from "./hooks/useSecurityReport";
import { RiskFactors } from "../../AssetReport/RiskFactors";
import { SectionHeading } from "~/pages/reporting/components";
import { Assets } from "./Assets";
import { PieChart, PieChartProps } from "@mui/x-charts";
import { getColor } from "~/lib/colors";
import { Flex } from "~/components/Flex";
import { AggregateScoresEdges } from "./types";
import { RankCircle } from "~/components/Cells";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

export function SecurityReport() {
  const {
    assets,
    title,
    totalScanned,
    stats,
    groupSummary,
    riskFactorsWithDocs,
    inventoryBreakdown,
  } = useSecurityReport();
  return (
    <Container sx={{ p: 4 }}>
      <Grid2 container sx={{ mb: spacing[25] }}>
        <SimpleReportHeader title={title} />
        <Stats id="detail-content-stats" stats={stats} />
        <GroupSummary groupSummary={groupSummary} />
      </Grid2>
      <Grid2 size={12} sx={{ mb: spacing[25] }}>
        <RiskFactors dataType="security" riskFactors={riskFactorsWithDocs} />
      </Grid2>
      <Grid2 size={12} sx={{ mb: spacing[50] }}>
        <SectionHeading
          heading="Inventory breakdown"
          dividerContent={
            totalScanned ? `${totalScanned} Assets total` : undefined
          }
        />
        <InventoryList softwareEdges={inventoryBreakdown} />
      </Grid2>
      <Assets assets={assets} />
    </Container>
  );
}

type GroupSummaryProps = {
  groupSummary: {
    name: string;
    description: string;
    data: PieChartProps["series"][0]["data"];
  }[];
};
export function GroupSummary({ groupSummary }: GroupSummaryProps) {
  let theme = useTheme();
  const totalItems = (data: PieChartProps["series"][0]["data"]) => {
    return data.reduce((acc, item) => acc + item.value, 0);
  };

  return (
    <Grid2
      size={12}
      sx={{
        mt: spacing[25],
        "& .group-summary-container:not(:last-child)": { mb: spacing[25] },
      }}
    >
      {groupSummary.map((group) => {
        const chartData = group.data.filter(
          (dataPoint) => dataPoint.id !== "NONE",
        );
        return (
          <Grid2
            container
            className="group-summary-container"
            size={12}
            columnSpacing={7}
            rowSpacing={3}
            key={group.name}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid2 size={{ xs: 12, sm: "grow" }}>
              <Typography fontWeight={700} sx={{ mb: 1, lineHeight: "16px" }}>
                {group.name}
              </Typography>
              <Typography sx={{ lineHeight: "24px" }}>
                {group.description}
              </Typography>
            </Grid2>
            <Grid2
              size={{ xs: 12, sm: 5 }}
              sx={{
                maxWidth: 300,
              }}
            >
              <Stack key={group.name} direction="row" spacing={2}>
                <Box flexGrow={1} sx={{ position: "relative" }}>
                  <Typography
                    variant="caption"
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: 24,
                      fontWeight: 700,
                      letterSpacing: ".48px",
                    }}
                  >
                    {totalItems(chartData)}
                  </Typography>
                  <PieChart
                    series={[
                      {
                        data: chartData,
                        innerRadius: 38,
                      },
                    ]}
                    height={120}
                    margin={{ right: 0 }}
                    slotProps={{ legend: { hidden: true } }}
                  />
                </Box>

                <Stack
                  direction="column"
                  rowGap={0.5}
                  flexGrow={1}
                  sx={{
                    justifyContent: "center",
                  }}
                  width="35%"
                >
                  {chartData.map((finding) => {
                    const isZero = finding.value === 0;
                    const color = isZero
                      ? theme.palette.text.disabled
                      : finding.color;

                    return (
                      <Flex
                        key={finding.id}
                        alignItems="center"
                        sx={{
                          gap: 0.5,
                          ...(isZero && { color }),
                        }}
                      >
                        <Box
                          sx={{
                            width: 16,
                            height: 16,
                            borderRadius: 50,
                            border: 4,
                            borderColor: color,
                            backgroundColor: color ? alpha(color, 0.5) : "",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: 12,
                            fontWeight: 700,
                            color: "inherit",
                          }}
                        >
                          {finding.id}{" "}
                          <Typography
                            component="span"
                            sx={{ fontSize: "inherit", color: "inherit" }}
                          >
                            {finding.value}
                          </Typography>
                        </Typography>
                      </Flex>
                    );
                  })}
                </Stack>
              </Stack>
            </Grid2>
          </Grid2>
        );
      })}
    </Grid2>
  );
}

type InventoryListProps = { softwareEdges: AggregateScoresEdges };

export function InventoryList({ softwareEdges }: InventoryListProps) {
  let theme = useTheme();
  const top5 = softwareEdges?.slice(0, 5) || [];

  return (
    <List sx={{ columns: 2 }}>
      {top5.map((edge) => {
        const { node } = edge;
        const { rank, rating, riskScore, title } = node || {};

        let UNKNOWN_RATING = "";
        if (!rating) {
          UNKNOWN_RATING = "UNKNOWN";
        }

        const color = getColor(theme, rating || UNKNOWN_RATING);
        return (
          <ListItem
            sx={{
              display: "inline-flex",
              justifyContent: "space-between",
            }}
          >
            <Stack direction="row" gap={5}>
              <RankCircle id={rank || "NA"} />
              {rating && riskScore != null ? (
                <ImpactUpdated
                  impact={{ rating, value: riskScore }}
                  isRiskScore
                  hideTooltip={true}
                />
              ) : (
                <Typography>{UNKNOWN_RATING}</Typography>
              )}
              <Typography>{title}</Typography>
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 1,
                py: 0.5,
                width: 48,
                borderRadius: 1,
                backgroundColor: alpha(color, 0.1),
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 600, color }}>
                865
              </Typography>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
}
