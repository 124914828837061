import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  ApplyExceptionForScopesInput,
  ExceptionMutationAction,
  ExceptionMutationInput,
  ListExceptionGroupsDocument,
  useApplyExceptionMutation,
  useApplyExceptionForScopesMutation,
  ExceptionType,
} from "~/operations";
import { InternalRefetchQueryDescriptor } from "@apollo/client/core/types";
import {
  ExceptionFormInput,
  ExceptionFormValidUntil,
} from "~/components/exceptions/set-exception-dialog";
import { add } from "~/lib/date";
import { getGraphQLErrors } from "../ui-library";

type UseExceptionsParams = {
  onSetException?: () => void;
  onRemoveException?: () => void;
  onSetExceptionModalClose?: () => void;
  onRemoveExceptionModalClose?: () => void;
  scopeMrns: string[];
  advisoryMrns?: Array<string>;
  controlMrns?: Array<string>;
  queryMrns?: Array<string>;
  refetchQueries: InternalRefetchQueryDescriptor[];
  multipleScopes?: boolean;
  applyToCves?: boolean;
};

const validUntilDateString = (
  value: ExceptionFormValidUntil,
): string | undefined => {
  const now = new Date();
  switch (value) {
    case ExceptionFormValidUntil.Week1:
      return add(now, { weeks: 1 }).toISOString();
    case ExceptionFormValidUntil.Month1:
      return add(now, { months: 1 }).toISOString();
    case ExceptionFormValidUntil.Month3:
      return add(now, { months: 3 }).toISOString();
    case ExceptionFormValidUntil.Month6:
      return add(now, { months: 6 }).toISOString();
    case ExceptionFormValidUntil.Year1:
      return add(now, { years: 1 }).toISOString();
    case ExceptionFormValidUntil.Indefinitely:
    default:
      return undefined;
  }
};

export function useExceptions({
  onSetException,
  onRemoveException,
  onSetExceptionModalClose,
  onRemoveExceptionModalClose,
  scopeMrns,
  advisoryMrns,
  queryMrns,
  controlMrns,
  refetchQueries,
  multipleScopes = false,
  applyToCves = false,
}: UseExceptionsParams) {
  const [isSettingException, setIsSettingException] = useState(false);
  const [isRemovingException, setIsRemovingException] = useState(false);

  const [applyException, applyExceptionResult] = useApplyExceptionMutation();
  const [applyExceptionForScope, applyExceptionForScopeResult] =
    useApplyExceptionForScopesMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoveExceptionModalOpen = () => {
    setIsRemovingException(true);
  };

  const handleRemoveExceptionModalClose = () => {
    setIsRemovingException(false);
  };

  const handleSetExceptionModalOpen = () => {
    setIsSettingException(true);
  };

  const handleSetExceptionModalClose = () => {
    setIsSettingException(false);
  };

  const baseSetException = async ({
    input,
  }: {
    input: ExceptionMutationInput | ApplyExceptionForScopesInput;
  }) => {
    try {
      if (multipleScopes) {
        await applyExceptionForScope({
          variables: { input: { ...input, scopeMrns } },
          refetchQueries: [ListExceptionGroupsDocument, ...refetchQueries],
        });
      } else {
        await applyException({
          variables: {
            input: { ...input, scopeMrn: scopeMrns[0], applyToCves },
          },

          refetchQueries: [ListExceptionGroupsDocument, ...refetchQueries],
        });
      }
      onSetException?.();
    } catch (error) {
      enqueueSnackbar(
        getGraphQLErrors({
          error,
          errorMessage: "Failed to set exception",
        }),
        { variant: "error" },
      );
      onSetExceptionModalClose?.();
    }
  };

  const handleSetException = async (formData: ExceptionFormInput) => {
    const input: ExceptionMutationInput = {
      scopeMrn: scopeMrns[0],
      controlMrns,
      queryMrns,
      advisoryMrns,
      action: formData.action,
      justification: formData.justification,
      validUntil: validUntilDateString(formData.validUntil),
    };

    return baseSetException({ input });
  };

  const handleSetExceptionForScopes = async (formData: ExceptionFormInput) => {
    const input: ApplyExceptionForScopesInput = {
      scopeMrns,
      controlMrns,
      queryMrns,
      action: formData.action,
      justification: formData.justification,
      validUntil: validUntilDateString(formData.validUntil),
    };

    return baseSetException({ input });
  };

  const handleRemoveException = async () => {
    const input = {
      controlMrns,
      queryMrns,
      advisoryMrns,
      action: ExceptionMutationAction.Enable,
      justification: "",
    };

    try {
      if (multipleScopes) {
        await applyExceptionForScope({
          variables: {
            input: { ...input, scopeMrns },
          },
          refetchQueries: [ListExceptionGroupsDocument, ...refetchQueries],
        });
      } else {
        await applyException({
          variables: {
            input: { ...input, scopeMrn: scopeMrns[0], applyToCves },
          },
          refetchQueries: [ListExceptionGroupsDocument, ...refetchQueries],
        });
      }
      onRemoveException?.();
    } catch (error) {
      enqueueSnackbar(`Failed to remove exception`, {
        variant: "error",
      });
      onRemoveExceptionModalClose?.();
    }
    setIsRemovingException(false);
  };

  const handleRemoveExceptionForScopes = async () => {
    const input: ApplyExceptionForScopesInput = {
      scopeMrns: scopeMrns,
      controlMrns,
      queryMrns,
      action: ExceptionMutationAction.Enable,
      justification: "",
    };

    await baseSetException({ input });
  };

  const baseSetScope = async ({
    input,
  }: {
    input: ExceptionMutationInput | ApplyExceptionForScopesInput;
  }) => {
    try {
      if (multipleScopes) {
        await applyExceptionForScope({
          variables: {
            input: { ...input, scopeMrns },
          },
          refetchQueries: [ListExceptionGroupsDocument, ...refetchQueries],
        });
      } else {
        await applyException({
          variables: { input: { ...input, scopeMrn: scopeMrns[0] } },
          refetchQueries: [ListExceptionGroupsDocument, ...refetchQueries],
        });
      }
      onSetException?.();
    } catch (error) {
      enqueueSnackbar(`Failed to set exception`, {
        variant: "error",
      });
      onSetExceptionModalClose?.();
    }
    setIsSettingException(false);
  };

  const handleSetScope = async (isInScope: boolean) => {
    const input: ExceptionMutationInput = {
      scopeMrn: scopeMrns[0],
      controlMrns: controlMrns,
      action: isInScope
        ? ExceptionMutationAction.Enable
        : ExceptionMutationAction.OutOfScope,
    };

    await baseSetScope({ input });
  };

  const handleSetScopeForMultipleScopes = async (isInScope: boolean) => {
    const input: ApplyExceptionForScopesInput = {
      scopeMrns: scopeMrns,
      controlMrns: controlMrns,
      action: isInScope
        ? ExceptionMutationAction.Enable
        : ExceptionMutationAction.OutOfScope,
    };

    await baseSetScope({ input });
  };

  return {
    isSettingException,
    isRemovingException,
    handleRemoveExceptionModalOpen,
    handleSetExceptionModalOpen,
    handleRemoveException,
    handleRemoveExceptionForScopes,
    handleSetException,
    handleSetExceptionForScopes,
    handleSetScope,
    handleSetScopeForMultipleScopes,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalClose,
    loading:
      applyExceptionResult.loading || applyExceptionForScopeResult.loading,
  };
}
