import { BlastRadiusFieldsFragment } from "~/operations";
import { BlockOutlined, FlagOutlined, Radar } from "@mui/icons-material";
import { HealingIcon } from "~/components/icons";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";

type GetCheckStatsParams = {
  blastRadius: BlastRadiusFieldsFragment | null | undefined;
};

export function getCheckStats({
  blastRadius,
}: GetCheckStatsParams): StatsProps["stats"] {
  return [
    {
      label: "Scanned",
      value: blastRadius?.assets?.toString() || "---",
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Affected",
      value: blastRadius?.affected?.toString() || "---",
      icon: <FlagOutlined fontSize="inherit" />,
      onClick: () => {
        document
          .querySelector(`#affected-assets`)
          ?.scrollIntoView({ behavior: "smooth" });
      },
    },
    {
      label: "Remediated",
      value:
        blastRadius?.assets && blastRadius?.affected
          ? (blastRadius?.assets - blastRadius?.affected).toString()
          : "---",
      icon: <HealingIcon fontSize="inherit" />,
    },
    {
      label: "Exceptions",
      value: (
        (blastRadius?.snoozed || 0) + (blastRadius?.disabled || 0)
      ).toString(),
      icon: <BlockOutlined fontSize="inherit" />,
    },
  ];
}
