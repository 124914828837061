import { OverflowHelper } from "~/components/OverflowHelper";
import { Box, TableCell, Typography } from "@mui/material";
import { CommonCellProps } from "../types";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";

export const TitleCell = ({ cve, ...props }: CommonCellProps) => (
  <TableCell width="70%" data-testid={props["data-testid"]}>
    <OverflowHelper>
      <Typography fontSize="14px" data-testid="cve-id">
        <Typography fontSize="14px" fontWeight="bold" variant="caption">
          {cve.id}:{" "}
        </Typography>
        {cve.title}
      </Typography>
    </OverflowHelper>
    <Box display="flex" gap={1} mt={1}>
      {cve.tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
    </Box>
  </TableCell>
);
