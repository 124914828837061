import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { alpha, Typography } from "@mui/material";
import { CircularIconButton } from "~/pages/compliance/components/CircularIconButton";
import { IconButtonType } from "~/pages/compliance/components/DynamicButtonIcon";
import { ConfirmationDialog } from "../ConfirmationDialog";
import {
  GetActivePoliciesDocument,
  TestIamActionsQuery,
  useDeleteCustomPolicyMutation,
  useDeleteCustomQueryPackMutation,
  useUnassignPolicyMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { generate } from "@graphql-codegen/cli";
import { usePolicyPermissions } from "~/pages/security/policies/hooks/usePolicyPermissions";

type IsQueryPack = {
  policyMrn?: never;
  queryPackMrn: string;
};

type IsPolicy = {
  policyMrn: string;
  queryPackMrn?: never;
};

type PolicyDeleteButtonProps = {
  space: Space;
  isCustom?: boolean;
  availablePermissions: TestIamActionsQuery["testIamActions"];
} & (IsPolicy | IsQueryPack);

// This component is used for deleting policies OR querypacks from a space
export function PolicyDeleteButton({
  space,
  policyMrn,
  queryPackMrn,
  isCustom,
  availablePermissions,
}: PolicyDeleteButtonProps) {
  let navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const { queryPackDeletePermission, policyDeletePermission } =
    usePolicyPermissions({
      availablePermissions,
    });

  // Deactivate a policy or querypack from a single space
  const [disablePolicy, disablePolicyReturn] = useUnassignPolicyMutation({
    variables: {
      input: {
        assetMrn: space.mrn,
        policyMrn: policyMrn || queryPackMrn,
      },
    },
    refetchQueries: [GetActivePoliciesDocument],
  });

  // Delete a custom policy from a single space.  Policy must be deactivated first.
  const [deleteCustomPolicy, deleteCustomPolicyReturn] =
    useDeleteCustomPolicyMutation({
      variables: {
        input: {
          policyMrn: policyMrn || "",
        },
      },
    });

  const [deleteCustomQueryPack, deleteCustomQueryPackReturn] =
    useDeleteCustomQueryPackMutation({
      variables: {
        input: {
          queryPackMrn: queryPackMrn || "",
        },
      },
    });

  // When a user clicks the confirm button, the policy will be disabled and if custom, deleted
  const handleConfirm = async () => {
    try {
      await disablePolicy();
      // custom policies and querypacks must be disabled before they can be deleted
      if (isCustom && policyMrn) {
        if (policyDeletePermission) {
          await deleteCustomPolicy();
        } else {
          throw new Error("User does not have permission to delete Policies");
        }
      }
      if (isCustom && queryPackMrn) {
        if (queryPackDeletePermission) {
          await deleteCustomQueryPack();
        } else {
          throw new Error(
            "User does not have permission to delete Query Packs",
          );
        }
      }
      setModalOpen(false);
      const url = policyMrn
        ? "/space/findings/policies"
        : "/space/inventory/query-packs";
      navigate(`${url}?spaceId=${space.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const generateTitle = () => {
    if (isCustom && policyMrn) {
      return "DELETE THIS CUSTOM POLICY FROM THE SPACE?";
    }

    if (isCustom && queryPackMrn) {
      return "DELETE THIS CUSTOM QUERY PACK FROM THE SPACE?";
    }

    if (policyMrn) {
      return "DELETE THIS POLICY FROM THE SPACE?";
    }

    if (queryPackMrn) {
      return "DELETE THIS QUERY PACK FROM THE SPACE?";
    }

    return "DELETE THIS FROM THE SPACE?";
  };

  const generateContentText = () => {
    if (isCustom && policyMrn) {
      return "Uploaded policies will be deleted permanently, alongside all data connected to them. You can only restore them by re-uploading them, so make sure to download a backup first.";
    }

    if (isCustom && queryPackMrn) {
      return "Uploaded query packs will be deleted permanently, alongside all data connected to them. You can only restore them by re-uploading them, so make sure to download a backup first.";
    }

    return "You can add and run this policy again any time, but any data collected or stored in connection to it will be deleted, and cannot be restored.";
  };

  const loading =
    disablePolicyReturn.loading ||
    deleteCustomPolicyReturn.loading ||
    deleteCustomQueryPackReturn.loading;

  return (
    <Fragment>
      <CircularIconButton
        title="Delete"
        iconType={IconButtonType.Delete}
        sx={{ color: (theme) => alpha(theme.palette.error.main, 0.5) }}
        onClick={() => setModalOpen(true)}
      />
      <ConfirmationDialog
        isOpen={modalOpen}
        loading={loading}
        onConfirm={() => handleConfirm()}
        onClose={() => setModalOpen(false)}
        title={generateTitle()}
        content={
          <Typography variant="body2">{generateContentText()}</Typography>
        }
      />
    </Fragment>
  );
}
