import { Flex } from "~/components/Flex";
import { BarChartIcon } from "~/components/ui-library";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { DataTable, SelectionToolbar } from "~/components/data-table";
import { usePolicies } from "./hooks/usePolicies";
import { ActivePolicyEdge, SelectedPolicies } from "./types";
import { AccessTimeIcon, NavDrawerFleetIcon } from "~/components/icons";
import { OrderDirection, PageInfo, PolicyAction } from "~/operations";
import { PreviewIcon } from "~/components/icons/mondoo/preview";
import { PreviewFlag } from "~/components/flags/preview-flag";
import { pluralize } from "~/lib/pluralize";
import { BlockOutlined } from "@mui/icons-material";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";
import { Pagination, PaginationRange } from "~/components/pagination";
import { ObservableQuery } from "@apollo/client";
import { isEmpty } from "lodash";
import { EmptyState } from "~/components/empty-state/empty-state";
import { AssetResultsCell } from "./AssetResultsCell";
import { FormatDate, isWithinInterval, subDays } from "~/lib/date";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type ListProps = {
  scope: SpaceOrWorkspaceScope;
  selectedItems: SelectedPolicies;
  handleCheckAll: (edges: ActivePolicyEdge[]) => void;
  activePolicies: ActivePolicyEdge[];
  totalPolicies: number;
  permissions: ReturnType<typeof usePolicies>["permissions"];
  handle: ReturnType<typeof usePolicies>["handle"];
  state: ReturnType<typeof usePolicies>["state"];
  orderBy: ReturnType<typeof usePolicies>["orderBy"];
  PaginationProps: {
    fetchMore: ObservableQuery["fetchMore"];
    pageInfo?: PageInfo;
    setPageItems: React.Dispatch<React.SetStateAction<PaginationRange>>;
    totalCount: number;
  };
};

type Header = {
  id: string;
  label: string;
  options?: {
    minWidth?: number;
    align?: "inherit" | "left" | "center" | "right" | "justify";
    colSpan?: number;
    pl?: number;
  };
};
const tableHeaders: Header[] = [
  { id: "NAME", label: "Policy", options: { colSpan: 2, pl: 1.5 } },
  // {
  //   id: "AVERAGE_SCORE",
  //   label: "Average Score",
  //   options: { minWidth: 160 },
  // },
  { id: "ASSET_RESULTS", label: "Asset Results", options: { minWidth: 300 } },
];

export function List({
  scope,
  permissions,
  selectedItems,
  handle,
  state,
  orderBy,
  handleCheckAll,
  activePolicies,
  totalPolicies,
  PaginationProps,
}: ListProps) {
  if (isEmpty(activePolicies)) {
    return <EmptyState contentType="policies" />;
  }

  return (
    <Fragment>
      <DataTable
        selectable={permissions.edit}
        selection={selectedItems}
        id="enabled-policies-list"
      >
        <TableHead>
          <TableRow>
            {permissions.edit && (
              <TableCell>
                <Checkbox
                  checked={selectedItems.length === activePolicies?.length}
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < activePolicies.length
                  }
                  onChange={() => handleCheckAll(activePolicies)}
                />
              </TableCell>
            )}
            {tableHeaders.map((header: Header) => (
              <TableCell
                key={header.id}
                align={header.options?.align}
                colSpan={header.options?.colSpan}
                sx={{ minWidth: header.options?.minWidth }}
              >
                <TableSortLabel
                  onClick={() => handle.sortClick(header.id)}
                  direction={
                    orderBy.direction === OrderDirection.Asc ? "asc" : "desc"
                  }
                  active={orderBy.field === header.id}
                  sx={{ pl: header.options?.pl }}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* Enabled Policies List */}
        <TableBody>
          {activePolicies.map((edge) => {
            if (!edge.node) return undefined;
            const { action, blastRadius, mrn, name, updatedAt, lastAppliedAt } =
              edge.node;

            const inPreviewMode = action === PolicyAction.Ignore;
            const isNew = lastAppliedAt
              ? isWithinInterval(new Date(lastAppliedAt), {
                  start: subDays(new Date(), 7),
                  end: new Date(),
                })
              : false;

            const href = `/space/findings/policies/${PolicyMrnToURIEncodedId(
              mrn,
            )}?${scope.params}`;
            const isSelected = selectedItems.find((i) => i.policyMrn === mrn);
            const className = isSelected ? "selected" : "";

            return (
              <TableRow
                key={mrn}
                onClick={() => handle.navigate(href)}
                className={className + " " + "policy-row"}
              >
                {permissions.edit && (
                  <TableCell>
                    <Checkbox
                      checked={Boolean(
                        selectedItems.find((i) => i.policyMrn === mrn),
                      )}
                      onChange={(e) =>
                        handle.check(e, {
                          policyMrn: mrn,
                          action,
                        })
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {policyIcon(name, "large")}
                  </Box>
                </TableCell>

                <TableCell sx={{ maxWidth: "1px!important", width: "100%" }}>
                  <Box>
                    <Flex alignItems="center" gap={1} mb={1}>
                      <Typography
                        fontWeight={700}
                        className="policy-name"
                        sx={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          color: inPreviewMode
                            ? "text.disabled"
                            : "text.primary",
                        }}
                      >
                        {name}
                      </Typography>
                      {isNew && (
                        <Typography
                          className="new-badge"
                          variant="caption"
                          sx={{
                            color: "warning.main",
                            fontWeight: 600,
                            verticalAlign: "middle",
                          }}
                        >
                          NEW
                        </Typography>
                      )}
                    </Flex>

                    <Grid
                      item
                      container
                      xs={12}
                      sx={{ columnGap: 3, rowGap: 0.5 }}
                    >
                      {inPreviewMode && (
                        <Grid
                          item
                          xs={12}
                          sm="auto"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <PreviewFlag />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm="auto"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          className="policy-asset-count"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: (theme) => theme.spacing(1.5),
                            color: "text.secondary",
                          }}
                        >
                          <NavDrawerFleetIcon fontSize="small" sx={{ mr: 1 }} />
                          {blastRadius.assets} Assets
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm="auto"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          className="policy-last-updated"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: (theme) => theme.spacing(1.5),
                            color: "text.secondary",
                          }}
                        >
                          <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                          Last Update: {FormatDate(updatedAt)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </TableCell>
                {/* <TableCell>
                  <AverageRiskScoreBox inPreviewMode={inPreviewMode} />
                </TableCell> */}
                <AssetResultsCell
                  blastRadius={blastRadius}
                  inPreviewMode={inPreviewMode}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </DataTable>
      {PaginationProps.pageInfo && <Pagination {...PaginationProps} />}

      {selectedItems.length > 0 && (
        <SelectionToolbar>
          <Typography>
            Selected {selectedItems.length} of {totalPolicies} policies
          </Typography>
          {(state.hasSelectedBothEnabledAndPreviewedPolicies ||
            state.hasSelectedPreviewedPolicy) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handle.enablePolicies}
              startIcon={<BarChartIcon />}
            >
              Enable {pluralize("Policy", selectedItems.length)}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handle.disablePolicies}
            startIcon={<BlockOutlined />}
          >
            Disable {pluralize("Policy", selectedItems.length)}
          </Button>
          {(state.hasSelectedBothEnabledAndPreviewedPolicies ||
            state.hasSelectedEnabledPolicy) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handle.previewPolicies}
              startIcon={<PreviewIcon />}
            >
              Preview {pluralize("Policy", selectedItems.length)}
            </Button>
          )}
          <Button onClick={handle.unCheckAll}>Cancel</Button>
        </SelectionToolbar>
      )}
    </Fragment>
  );
}
