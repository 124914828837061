import { Checkbox } from "@mui/material";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";
import { IamActions } from "~/lib/iam";
import { Space } from "~/lib/types";
import {
  ExceptionMutationAction,
  ExceptionType,
  GetAdvisoriesDocument,
  LoadAssetDocument,
  ScoreState,
  TestIamActionsQuery,
} from "~/operations";
import { Header } from "~/types/table";
import { AdvisoryFindingsNode } from "~/pages/inventory/components/Advisories/types";

type Scope = "advisory" | "cve";

type UseAssetVulnerabilitiesTableProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  scope: Scope;
  space: Space;
  entityMrn: string;
  items: AdvisoryFindingsNode[];
};

export function useAssetVulnerabilitiesTable({
  availablePermissions,
  scope,
  space,
  entityMrn,
  items,
}: UseAssetVulnerabilitiesTableProps) {
  const {
    isGroupChecked,
    isGroupIndeterminate,
    onGroupCheckChange,
    selectedEntities,
    setSelectedEntities,
    handleNodeClick,
    handleNodeChange,
    handleCancelClick,
  } = useExceptionsSelection();

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedEntities([]);
    },
    onRemoveException: () => {
      setSelectedEntities([]);
    },
    scopeMrns: [entityMrn],
    advisoryMrns: mapSelectedEntitiesToString(selectedEntities),
    applyToCves: true,
    refetchQueries: [GetAdvisoriesDocument, LoadAssetDocument],
  });

  const { exceptionGroups } = useFetchExceptions({
    scopeMrn: entityMrn,
    types:
      scope === "advisory" ? [ExceptionType.Advisory] : [ExceptionType.Cve],
  });

  const targetPaginatedGroup = items.map((item) => {
    const exception =
      item.state === ScoreState.Snoozed
        ? {
            id: item.mrn,
            justification: "",
            action: ExceptionMutationAction.Snooze,
          }
        : item.state === ScoreState.Disabled
          ? {
              id: item.mrn,
              justification: "",
              action: ExceptionMutationAction.Disable,
            }
          : null;

    return {
      mrn: item.mrn as string,
      exception: exception,
      groupId: item.mrn,
    };
  });

  const hasApplyExceptionPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  const AssetVulnerabilitiesTableHeaders = {
    SELECT: {
      id: "SELECT",
      label: (
        <Checkbox
          checked={isGroupChecked(targetPaginatedGroup)}
          indeterminate={isGroupIndeterminate(targetPaginatedGroup)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onGroupCheckChange(event, targetPaginatedGroup)
          }
        />
      ),
      sortable: false,
    },
    RISK_SCORE: {
      id: "RISK_SCORE",
      label: "Risk",
      options: { width: "10%" },
    },
    ID: {
      id: "ID",
      label: "Advisory",
      options: { width: "50%" },
      sortable: false,
    },
    RISKFACTORS: {
      id: "RISKFACTORS",
      label: "Risk factors",
      sortable: false,
    },
    PUBLISHED_DATE: {
      id: "PUBLISHED_DATE",
      label: "Published",
      // Backend does not support sorting by this column yet
      sortable: false,
    },
  };

  const getTableHeaders = (scope: Scope, hasPermission: boolean) => {
    if (scope === "advisory") {
      // todo lock in other scopes
      return AdvisoryTableHeaders(hasPermission);
    }
    return [];
  };

  const AdvisoryTableHeaders = (hasPermission: boolean): Header[] => {
    return [
      ...(hasPermission ? [AssetVulnerabilitiesTableHeaders.SELECT] : []),
      AssetVulnerabilitiesTableHeaders.RISK_SCORE,
      AssetVulnerabilitiesTableHeaders.ID,
      AssetVulnerabilitiesTableHeaders.RISKFACTORS,
      AssetVulnerabilitiesTableHeaders.PUBLISHED_DATE,
    ].flatMap((h) => h ?? []);
  };

  // get the appropriate table headers
  const tableHeaders = getTableHeaders(scope, hasApplyExceptionPermission);

  return {
    hasApplyExceptionPermission,
    tableHeaders,
    exceptionGroups,
    targetPaginatedGroup,
    exception: {
      isRemovingException,
      isSettingException,
      handleSetExceptionModalOpen,
      handleSetExceptionModalClose,
      handleRemoveExceptionModalOpen,
      handleRemoveExceptionModalClose,
      handleRemoveException,
      handleSetException,
      loading: exceptionsLoading,
    },
    exceptionsSelection: {
      isGroupChecked,
      isGroupIndeterminate,
      onGroupCheckChange,
      selectedEntities,
      setSelectedEntities,
      handleNodeClick,
      handleNodeChange,
      handleCancelClick,
    },
  };
}
