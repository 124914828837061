import { Route, Routes } from "react-router-dom";
import { TestIamActionsQuery } from "~/operations";
import { Space } from "~/lib/types";
import { Framework } from "~/pages/compliance/framework/framework";
import { Control } from "~/pages/compliance/control/control";
import {
  Assets as ControlAssets,
  Checks,
  DataQueries,
  ExceptionsWrapper as ControlExceptions,
} from "~/pages/compliance/control/control-outlets";
import {
  Assets,
  Controls,
  ExceptionsWrapper,
  Policies,
} from "~/pages/compliance/framework/framework-outlets";
import { DataQuery } from "~/pages/compliance/control/control-outlets/data-queries/data-query";
import { Overview as DataQueryOverview } from "~/pages/compliance/control/control-outlets/data-queries/overview";
import { Assets as DataQueryAssets } from "~/pages/compliance/control/control-outlets/data-queries/assets";
import { Check } from "~/pages/space/security/components/Check";
import { ComplianceEntryPage } from "~/pages/compliance/EntryPage/ComplianceEntryPage";
import { Redirect } from "../redirect";

type ComplianceRouteProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function ComplianceRoutes({
  space,
  availablePermissions,
}: ComplianceRouteProps) {
  return (
    <Routes>
      <Route
        index
        element={
          <ComplianceEntryPage
            space={space}
            availablePermissions={availablePermissions}
          />
        }
      />
      <Route
        path=":frameworkId"
        element={<Framework {...{ space, availablePermissions }} />}
      >
        <Route
          index
          element={<Controls availablePermissions={availablePermissions} />}
        />
        <Route
          path="controls"
          element={<Controls availablePermissions={availablePermissions} />}
        />
        <Route path="policies" element={<Policies />} />
        <Route
          path="assets"
          element={<Assets availablePermissions={availablePermissions} />}
        />
        <Route
          path="exceptions"
          element={
            <ExceptionsWrapper availablePermissions={availablePermissions} />
          }
        />
      </Route>
      <Route
        path=":frameworkId/controls/:controlId"
        element={<Control {...{ space, availablePermissions }} />}
      >
        <Route index element={<DataQueries />} />
        <Route path="data-queries" element={<DataQueries />} />
        <Route
          path="checks"
          element={<Checks availablePermissions={availablePermissions} />}
        />
        <Route
          path="assets"
          element={
            <ControlAssets availablePermissions={availablePermissions} />
          }
        />
        <Route
          path="exceptions"
          element={
            <ControlExceptions availablePermissions={availablePermissions} />
          }
        />
      </Route>
      <Route
        path=":frameworkId/controls/:controlId/check"
        element={<Check {...{ space, scope: space, availablePermissions }} />}
      />
      <Route
        path=":frameworkId/controls/:controlId/data-query"
        element={<DataQuery {...{ space, availablePermissions }} />}
      >
        <Route index element={<DataQueryOverview />} />
        <Route path="overview" element={<DataQueryOverview />} />
        <Route path="assets" element={<DataQueryAssets {...{ space }} />} />
      </Route>

      <Route
        path="reports"
        element={
          <Redirect from={"space/compliance/reports"} to={"space/reporting"} />
        }
      />
    </Routes>
  );
}
