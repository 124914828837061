import {
  AssetGroupStatistics,
  IntegrationStatistics,
  IntegrationType,
  useGetAssetGroupStatsQuery,
  useGetSpaceInventoryStatsQuery,
  useGetWorkspaceInventoryStatsQuery,
} from "~/operations";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { Metrics, parseAssetsGrades } from "~/utils/arrow";
import { useTheme } from "@mui/material";

export type AssetGroupStatsItem = AssetGroupStatistics;
export type AssetGroupStats = AssetGroupStatsItem[];

export type IntegrationStatsItem = IntegrationStatistics;
export type IntegrationStats = IntegrationStatsItem[];

export type UseInventoryProps = {
  scope: SpaceOrWorkspaceScope;
  searchFilters?: string[];
};

export type Inventory = {
  assetGroupStats: AssetGroupStats;
  assetsTotalCount: number;
  workspaceAssetsTotalCount: number;
  spaceAssetsTotalCount: number;
  hasJiraIntegrationEnabled: boolean;
  refetch: () => void;
  loading: boolean;
};

export function useInventory({
  scope,
  searchFilters = [],
}: UseInventoryProps): Inventory {
  const theme = useTheme();
  const spaceResult = useGetSpaceInventoryStatsQuery({
    variables: {
      spaceMrn: scope.mrn,
      metricMrns: [Metrics.SecurityAssetsStats],
    },
    skip: scope.type !== ScopeType.Space,
  });

  const workspaceResult = useGetWorkspaceInventoryStatsQuery({
    variables: {
      workspaceMrn: scope.mrn,
      metricMrns: [Metrics.SecurityAssetsStats],
    },
    skip: scope.type !== ScopeType.Workspace,
  });

  const { data: assetGroupStatsData, refetch: refetchAssetGroupStats } =
    useGetAssetGroupStatsQuery({
      variables: { scopeMrn: scope.mrn, queryTerms: searchFilters },
      skip: !scope.mrn,
    });

  const workspace =
    workspaceResult?.data?.workspace?.__typename === "Workspace"
      ? workspaceResult?.data?.workspace
      : undefined;

  const space =
    spaceResult?.data?.space?.__typename === "Space"
      ? spaceResult.data.space
      : undefined;

  const workspaceMetricsEntries = workspace?.metrics?.entries || [];
  const spaceMetricsEntries = space?.metrics?.entries || [];

  const workspaceAssetsGrades = parseAssetsGrades(
    workspaceMetricsEntries,
    theme,
  );
  const spaceAssetsGrades = parseAssetsGrades(spaceMetricsEntries, theme);

  const workspaceAssetsTotalCount = workspaceAssetsGrades.reduce(
    (acc, item) => {
      return acc + item.value;
    },
    0,
  );

  const spaceAssetsTotalCount = spaceAssetsGrades.reduce((acc, item) => {
    return acc + item.value;
  }, 0);

  const refetch =
    scope.type === ScopeType.Space
      ? spaceResult.refetch
      : workspaceResult.refetch;

  function refetchQueries() {
    refetch();
    refetchAssetGroupStats();
  }

  // https://www.figma.com/file/ks8K3oC02Li9Xautp2jPeU/4.-Registry%2C-Security%2C-Vulnerabilities?node-id=60%3A22425
  // "Below, there can be up to 5 most important integrations (as discussed in the call:
  // Cloud providers first, so K8s, AWS, GCP – then OS & Containers; possibly based on
  // # of assets in each; not when there’s 0 of a given type of course)"
  const sortByImportant = (a: AssetGroupStatsItem, b: AssetGroupStatsItem) => {
    const cloudGroup = ["k8s", "aws", "gcp", "azure", "vmware", "oci"];
    const osContainerGroup = ["os", "container"];
    const supplyChainGroup = ["terraform"];
    const saasGroup = [
      "ms365",
      "okta",
      "google-workspace",
      "saas_services",
      "gitlab",
      "github",
      "slack",
    ];
    const networkGroup = ["network_devices", "domains_and_hosts"];

    // least important first
    const groupsByImportance = [
      networkGroup,
      saasGroup,
      supplyChainGroup,
      osContainerGroup,
      cloudGroup,
    ];

    const aIdx = groupsByImportance.findIndex((g) => g.includes(a.groupType));
    const bIdx = groupsByImportance.findIndex((g) => g.includes(b.groupType));

    if (aIdx === bIdx) {
      return b.count - a.count;
    }

    return bIdx - aIdx;
  };

  const assetGroupStats = (assetGroupStatsData?.assets?.assetGroupStats || [])
    .filter((g) => g.count > 0)
    .sort(sortByImportant);

  const integrationStats = space?.stats?.integrationsStatistics;
  const hasJiraIntegrationEnabled =
    integrationStats?.some(
      (ai) => ai.total > 0 && ai.type === IntegrationType.TicketSystemJira,
    ) || false;

  return {
    assetGroupStats,
    assetsTotalCount:
      scope.type === ScopeType.Workspace
        ? workspaceAssetsTotalCount
        : spaceAssetsTotalCount,
    workspaceAssetsTotalCount,
    spaceAssetsTotalCount,
    hasJiraIntegrationEnabled,
    refetch: refetchQueries,
    loading: spaceResult.loading || workspaceResult.loading,
  };
}
