import { Fragment, useState } from "react";
import {
  alpha,
  Box,
  Grid,
  IconButton,
  IconButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { LastOperations } from "~/components/asset-last-operations";
import {
  DeleteIcon,
  FileCopyIcon,
  MoreHorizIcon,
  PrintIcon,
} from "~/components/icons";
import {
  AssetForwardPaginationDocument,
  DocumentType,
  IntegrationStatistics,
  LoadSpaceStatsDocument,
  useDeleteAssetsMutation,
} from "~/operations";
import { Asset, Project } from "~/pages/inventory/asset/types";
import { Space } from "~/lib/types";
import { IamActions } from "~/lib/iam";
import { copyToClipboard } from "~/lib/clipboard";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useViewer } from "~/providers/viewer";
import { DeleteConfirmDialog } from "~/components/delete-confirm-dialog";
import { getColor } from "~/lib/colors";
import { useWarnings } from "../../../../hooks/useWarnings";
import { AssetInsights } from "./assetInsights";
import { ImpactData } from "~/components/impact/types";
import { ImpactRating } from "~/components/impact/impact-rating";
import { WarningMessage } from "~/components/user-warning";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { isFeatureEnabled } from "~/login/features";
import { ExportButton } from "~/pages/compliance/components/export-framework";
import { CasesSummary } from "~/components/cases/components/CasesSummary";
import { RiskFactorsIcons } from "~/pages/space/security/components/RiskFactors/RiskFactorsIcons";

type AssetOverviewHeaderProps = {
  asset: Asset;
  activeIntegrations?: IntegrationStatistics[];
  space: Space;
  scope: SpaceOrWorkspaceScope;
  exceptionsCount: number;
  project?: Project | null;
  isCicd?: boolean | null;
  casesCount: number;
};

export function AssetOverviewHeader({
  asset,
  space,
  scope,
  exceptionsCount,
  project,
  isCicd = false,
  casesCount,
}: AssetOverviewHeaderProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { viewerSettings } = useViewer();
  const { isEndOfLife, warnings } = useWarnings({ asset });
  const [moreActionsAnchorEl, setMoreActionsAnchorEl] =
    useState<HTMLElement | null>(null);
  const moreActionsOpen = Boolean(moreActionsAnchorEl);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [deleteConfirmIsOpen, setDeleteConfirmIsOpen] =
    useState<boolean>(false);

  const assetId = asset.mrn?.split("/").pop() || id;

  const assetMrn =
    asset.mrn ||
    "//assets.api.mondoo.app/spaces/" + space.id + "/assets/" + assetId;

  const [deleteAssets] = useDeleteAssetsMutation({
    refetchQueries: [AssetForwardPaginationDocument, LoadSpaceStatsDocument], // another refetch query for fleet
  });

  const hasDeleteAssetPermission = space.iamActions.includes(
    IamActions.ASSETS_DELETEASSET,
  );

  const handleDeleteAsset = async () => {
    setIsDeleting(true);

    try {
      await deleteAssets({
        variables: { input: { spaceMrn: space.mrn, assetMrns: [assetMrn] } },
        update: (cache) =>
          cache.evict({ id: "ROOT_QUERY", fieldName: "assets" }),
      });
      enqueueSnackbar("Successfully deleted asset", { variant: "success" });
      isCicd && project
        ? navigate(
            `/space/cicd/jobs?spaceId=${space.id}&projectId=${project.id}`,
          )
        : isCicd
          ? navigate(`/space/cicd?spaceId=${space.id}`)
          : navigate(`/space/inventory?spaceId=${space.id}`);
    } catch (error) {
      setIsDeleting(false);
      enqueueSnackbar("Failed to delete asset", { variant: "error" });
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmIsOpen(false);
    setIsDeleting(false);
  };

  const handleDeleteIconClick = () => {
    if (viewerSettings?.assetDoNotAskToDelete) {
      handleDeleteAsset();
    } else {
      setDeleteConfirmIsOpen(true);
    }
  };

  const handlePopoverOpen: IconButtonProps["onClick"] = (event) => {
    setMoreActionsAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setMoreActionsAnchorEl(null);
  };

  const handleCopyClick = () => {
    copyToClipboard(assetMrn);
    setMoreActionsAnchorEl(null);
  };

  const titleCharacterCount = asset.name?.length || 0;

  const lastScan = asset.updatedAt;
  const grade = asset?.score.grade || "U";
  const color = getColor(theme, grade);
  const rating = getRating(asset?.score.weight || 0);

  return (
    <Fragment>
      <DeleteConfirmDialog
        title="Are you Sure?"
        content="If you delete an asset from Mondoo, you delete all data related to the asset. You can re-add the asset, but doing so does not recover historical data."
        dividers={false}
        open={deleteConfirmIsOpen}
        onConfirm={handleDeleteAsset}
        onClose={handleCancelDelete}
        isDeleting={isDeleting}
        includeDoNotAskCheck={true}
      />
      <Grid container className="asset-overview-header">
        <Grid container item>
          <Grid
            item
            xs={12}
            md={titleCharacterCount > 45 ? 9 : "auto"}
            sx={{ display: "flex" }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              sx={{
                wordBreak: "break-word",
                overflowWrap: "anywhere",
              }}
            >
              {asset.name}{" "}
              <RiskFactorsIcons
                riskFactors={asset.riskFactors}
                shouldLimit={false}
                showBackgroundColor
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: "text.secondary",
            mb: 1.625,
          }}
        >
          <Grid item xs={12} sm="auto">
            <Typography display="inline" fontSize={12} lineHeight={"31.92px"}>
              {asset.platform?.title}
            </Typography>
          </Grid>
          <Grid item xs={9} sm="auto">
            <LastOperations lastScan={lastScan} updatedAt={asset.updatedAt} />
          </Grid>

          <Grid item xs display="flex" justifyContent="end">
            {hasDeleteAssetPermission && (
              <Tooltip title="Delete asset" arrow placement="top">
                <IconButton
                  size="large"
                  sx={{
                    color: theme.palette.error.main,
                    "&:hover": {
                      backgroundColor: alpha(theme.palette.error.main, 0.15),
                    },
                  }}
                  onClick={handleDeleteIconClick}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            <Fragment />
            {isFeatureEnabled("Reporting") ? (
              <ExportButton
                documentType={DocumentType.AssetReport}
                title={asset.name || "Generated Asset Report"}
                space={space}
                assetMrn={assetMrn}
                size="large"
                IconProps={{
                  fontSize: undefined,
                }}
              />
            ) : (
              <Tooltip title="Print asset report" arrow placement="top">
                <IconButton
                  size="large"
                  href={`/print?what=asset&assetMrn=${asset.mrn}`}
                  target="_blank"
                >
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="More" arrow placement="top">
              <IconButton
                size="large"
                aria-owns={moreActionsOpen ? "more-actions-menu" : undefined}
                aria-haspopup="true"
                onClick={handlePopoverOpen}
              >
                <MoreHorizIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="more-actions-menu"
              open={moreActionsOpen}
              onClose={handlePopoverClose}
              anchorEl={moreActionsAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleCopyClick}>
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText>Copy MRN</ListItemText>
              </MenuItem>
            </Menu>
          </Grid>

          {casesCount > 0 && (
            <Grid item xs={12} mt={2}>
              <CasesSummary count={casesCount} scope={scope} />
            </Grid>
          )}

          {warnings && (
            <Grid item xs={12} mb={3}>
              <Grid container>
                {warnings.map((warning) => {
                  const { severity, msg } = warning;
                  return (
                    <Grid item xs={12} key={msg}>
                      <WarningMessage {...{ severity, msg }} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}

          {asset && (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={3}>
                <Box
                  sx={{
                    p: 3,
                    background: alpha(color, 0.1),
                    borderRadius: 1,
                    boxShadow: `inset 0 0 0 2px ${color}`,
                    [theme.breakpoints.up("sm")]: {
                      maxWidth: 264,
                    },
                  }}
                >
                  <Grid container columnSpacing={1}>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 96,
                          fontWeight: 700,
                          color: color,
                          lineHeight: "100%",
                        }}
                      >
                        {grade}
                      </Typography>
                    </Grid>
                    <Grid container item xs={6}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mb: 3,
                        }}
                      >
                        <Box my={0.5}>
                          <ImpactRating
                            impactData={{ rating }}
                            color={color}
                            options={{ size: 16, border: 2 }}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: color,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 40,
                            fontWeight: 700,
                            width: 96,
                            lineHeight: "48px",
                          }}
                        >
                          {asset.score.value}
                          <Typography
                            component="span"
                            sx={{
                              fontSize: (theme) => theme.spacing(3),
                              fontWeight: 700,
                              lineHeight: "48px",
                            }}
                          >
                            %
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={9}>
                <AssetInsights
                  assetMrn={asset.mrn}
                  insights={asset.insights}
                  exceptionsCount={exceptionsCount}
                  space={space}
                  scope={scope}
                  isEol={isEndOfLife}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export const getRating = (value: number): ImpactData["rating"] => {
  if (value >= 1 && value <= 100) {
    const step = 100 / 5; // Divide the range into 5 equal steps
    let rating = Math.ceil(value / step); // Round up to the nearest whole number
    return rating as ImpactData["rating"];
  }
  return 1;
};
