import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Dropzone, DropzoneProps, DropzoneText } from "~/components/dropzone";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegration,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { GreenCheckCircleIcon } from "~/pages/invitation";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { useNavigate } from "react-router-dom";
import { getError } from "~/lib/handle-error";
import {
  GUID_PATTERN,
  helperTextStyles,
  ID_PLACEHOLDER,
  MATCHING_ID_ERROR_MESSAGE,
  ValidationMessage,
} from "../../validations/helpers";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { PasswordField } from "~/components/Form/components/PasswordField";

type CloudFormInput = {
  integrationName: string;
  tenantId: string;
  secretRadio: "clientSecret" | "pemKey";
  clientId: string;
  clientSecret: string;
  pemKey: string | null;
};

const defaultValues: CloudFormInput = {
  integrationName: "",
  tenantId: "",
  secretRadio: "clientSecret",
  clientId: "",
  clientSecret: "",
  pemKey: null,
};

export function MsftDefenderIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [createdIntegrationMrn, setCreatedIntegrationMrn] = useState<
    ClientIntegration["mrn"] | undefined
  >(undefined);
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [tenantId, clientId, clientSecret, secretRadio, pemKey] = watch([
    "tenantId",
    "clientId",
    "clientSecret",
    "secretRadio",
    "pemKey",
  ]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data.createClientIntegration.integration) {
        setCreatedIntegrationMrn(data.createClientIntegration.integration.mrn);
      }
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [TriggerClientIntegrationScan] = useTriggerActionLazyQuery({
    variables: {
      input: { mrn: createdIntegrationMrn!, type: ActionType.RunImport },
    },
  });

  const handleTriggerScan = async () => {
    try {
      await TriggerClientIntegrationScan();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow?.integration.configurationOptions?.__typename !==
        "MicrosoftDefenderConfigurationOptions"
      ) {
        return;
      }

      reset({
        integrationName: updateFlow.integration.name,
        tenantId: updateFlow.integration.configurationOptions?.tenantId,
        clientId: updateFlow.integration.configurationOptions?.clientId,
        clientSecret: "", // <-- do we store the client secret?
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    trigger("clientId");
  }, [tenantId]);

  useEffect(() => {
    trigger("tenantId");
  }, [clientId]);

  const onSubmit: SubmitHandler<CloudFormInput> = async (data) => {
    if (!updateFlow) {
      if (!pemKey && !clientSecret) {
        throw new Error(
          "You must supply a valid .pem certificate or client secret",
        );
      }
    }

    const splitAndTrim = (string: string): string[] => {
      const replaceNewlinesRegex = new RegExp(/\r?\n|\r/g);
      return string
        .replace(replaceNewlinesRegex, ",")
        .split(",")
        .map((x) => x.trim())
        .filter((x) => x !== "");
    };

    const microsoftDefenderConfigurationOptions = {
      clientId: data.clientId,
      clientSecret: data.clientSecret,
      tenantId: data.tenantId,
      ...(updateFlow ? {} : { certificate: data.pemKey }),
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.MicrosoftDefender,
              configurationOptions: {
                microsoftDefenderConfigurationOptions,
              },
            },
          },
        });
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/defender/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        const integration = await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.MicrosoftDefender,
              longLivedToken: false,
              configurationOptions: {
                microsoftDefenderConfigurationOptions,
              },
            },
          },
        });

        const integrationId =
          integration.data?.createClientIntegration.integration.mrn
            ?.split("/")
            .pop();

        navigate(
          `/space/integrations/defender/${integrationId}/?spaceId=${space.id}`,
        );
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = async (files) => {
    setValue("pemKey", await files[0].text());
  };

  return (
    <Fragment>
      <IntegrationAddHeader {...{ type: IntegrationType.MicrosoftDefender }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Step 1 */}
          <Box pb={4}>
            <Command
              number={1}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Create an app registration in Microsoft Entra
            </Command>
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                To integrate your Microsoft account with Mondoo, you must create
                a new app registration and give it the needed permissions. To
                learn how, read the{" "}
                <Link
                  href="https://mondoo.com/docs/platform/infra/imports/defender/"
                  target="_blank"
                  rel="noopener"
                >
                  Mondoo documentation
                </Link>
                .
              </Typography>

              <Button
                href="https://entra.microsoft.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade/quickStartType~/null/sourceType/Microsoft_AAD_IAM"
                target="_blank"
                variant="outlined"
                color="secondary"
                endIcon={<OpenInNewIcon />}
              >
                Go to Microsoft Entra
              </Button>
            </Box>
          </Box>
          {/* Step 2 */}
          <Box pb={4}>
            <Command
              number={2}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Choose an integration name
            </Command>
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Use a descriptive name that lets you easily identify the
                integration later.
              </Typography>
              <Controller
                name="integrationName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Your integration name..."
                    error={Boolean(errors.integrationName)}
                    helperText={
                      Boolean(errors.integrationName) && (
                        <ValidationMessage error={errors.integrationName} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>

          {/* step 3 */}
          <Box pb={4}>
            <Command
              number={3}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Enter the application (client) ID
            </Command>
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                This is the name you gave the app registration you created for
                Mondoo. Find it in the app details in{" "}
                <Link
                  href="https://entra.microsoft.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade/quickStartType~/null/sourceType/Microsoft_AAD_IAM"
                  target="_blank"
                  rel="noopener"
                >
                  the Microsoft Entra admin console
                </Link>
                .
              </Typography>
              <Controller
                name="clientId"
                control={control}
                rules={{
                  required: true,
                  pattern: GUID_PATTERN,
                  validate: {
                    matchingIds: (value: CloudFormInput["clientId"]) =>
                      value !== tenantId || MATCHING_ID_ERROR_MESSAGE,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder={ID_PLACEHOLDER}
                    error={
                      getFieldState("clientId").isTouched &&
                      Boolean(errors.clientId)
                    }
                    helperText={
                      Boolean(errors.clientId) &&
                      getFieldState("clientId").isTouched && (
                        <ValidationMessage error={errors.clientId} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>
          {/* step 4 */}
          <Box pb={4}>
            <Command
              number={4}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Enter the directory (tenant) ID
            </Command>
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Find it in the app details in{" "}
                <Link
                  href="https://entra.microsoft.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade/quickStartType~/null/sourceType/Microsoft_AAD_IAM"
                  target="_blank"
                  rel="noopener"
                >
                  the Microsoft Entra admin console
                </Link>
                .
              </Typography>
              <Controller
                name="tenantId"
                control={control}
                rules={{
                  required: true,
                  pattern: GUID_PATTERN,
                  validate: {
                    matchingIds: (value: CloudFormInput["tenantId"]) =>
                      value !== clientId || MATCHING_ID_ERROR_MESSAGE,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder={ID_PLACEHOLDER}
                    error={
                      getFieldState("tenantId").isTouched &&
                      Boolean(errors.tenantId)
                    }
                    helperText={
                      Boolean(errors.tenantId) &&
                      getFieldState("tenantId").isTouched && (
                        <ValidationMessage error={errors.tenantId} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>
          <Box pb={4}>
            <Command
              number={5}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Provide a client secret or a certificate and private key
            </Command>
            <Box sx={{ ml: 2, mt: 1 }}>
              <Controller
                name="secretRadio"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    aria-label="client secret or certificate"
                  >
                    <FormControlLabel
                      value="clientSecret"
                      control={<Radio />}
                      label="Client secret"
                    />
                    <FormControlLabel
                      value="pemKey"
                      control={<Radio />}
                      label="Certificate and private key"
                    />
                  </RadioGroup>
                )}
              />
            </Box>
            <Box>
              {secretRadio === "clientSecret" && (
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Enter the client secret
                  </Typography>
                  <Controller
                    name="clientSecret"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }) => (
                      <PasswordField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="e.g. 1234abcd5678efgh9012ijkl3456mnop"
                        error={
                          getFieldState("clientSecret").isTouched &&
                          Boolean(errors.clientSecret)
                        }
                        helperText={
                          Boolean(errors.clientSecret) &&
                          getFieldState("clientSecret").isTouched && (
                            <ValidationMessage error={errors.clientSecret} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              )}

              {secretRadio === "pemKey" && (
                <Box>
                  {!updateFlow && (
                    <Box pb={4}>
                      <Box>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mb: 2, mt: 2 }}
                        >
                          Upload a valid PEM file containing your certificate
                          and private key. To learn more, read the{" "}
                          <Link
                            href="https://mondoo.com/docs/platform/infra/imports/defender/"
                            target="_blank"
                            rel="noopener"
                          >
                            Mondoo documentation
                          </Link>
                          .
                        </Typography>
                        <AnimatePresence mode="wait">
                          {!pemKey ? (
                            <Box
                              key="dropzone"
                              component={motion.div}
                              {...fadeInOut}
                            >
                              <Dropzone
                                className="mondoo-dropzone-area"
                                accept={{
                                  "application/x-x509-ca-cert": [".pem"],
                                  "application/x-x509-user-cert": [".pem"],
                                  "application/x-pkcs12": [".pem"],
                                  "application/x-pem-file": [".pem"],
                                }}
                                multiple={false}
                                showAlerts={["error"]}
                                onDropAccepted={onDropAccepted}
                              >
                                <DropzoneText>
                                  Drag and drop your .pem file here.
                                </DropzoneText>
                              </Dropzone>
                            </Box>
                          ) : (
                            <Box
                              key="success"
                              component={motion.div}
                              {...fadeInOut}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                py: 5,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  mb: 2,
                                }}
                              >
                                <Typography variant="h6">
                                  Successfully loaded certificate
                                </Typography>
                                <GreenCheckCircleIcon
                                  fontSize="large"
                                  sx={{ ml: 2 }}
                                />
                              </Box>

                              <Button
                                variant="outlined"
                                color="error"
                                onClick={() => setValue("pemKey", "")}
                              >
                                Remove certificate
                              </Button>
                            </Box>
                          )}
                        </AnimatePresence>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              // setValue doesn't trigger the required validation so we're doing it manually here
              disabled={
                updateFlow ? !isValid : !isValid || (!pemKey && !clientSecret)
              }
            >
              {updateFlow ? "update configuration" : "start importing"}
            </Button>
          </Box>
        </form>
      </Box>
    </Fragment>
  );
}
