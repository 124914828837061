import { ReactNode } from "react";
import { Collapse, TableCell, TableRow } from "@mui/material";

export type DetailRowProps = {
  children: ReactNode;
  colSpan: number;
  open: boolean;
  id?: string;
  backgroundColor?: string;
};

export function DetailRow({
  children,
  colSpan,
  open,
  id,
  backgroundColor,
}: DetailRowProps) {
  return (
    <TableRow
      id={id}
      className="detail-row"
      sx={{
        borderBottom: "none",
        ...(backgroundColor && { backgroundColor }),
        "&:hover": { backgroundColor: backgroundColor ?? "unset" },
      }}
    >
      <TableCell colSpan={colSpan} sx={{ p: 0, maxWidth: 0 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
