import { Route, Routes } from "react-router-dom";
import { Space } from "~/lib/types";
import { SpaceSettings } from "~/pages/space-settings";
import { GeneralSpaceSettings } from "~/pages/space-settings/general-settings";
import { GenerateApiTokenForm } from "~/pages/space-settings/api-tokens/generate-api-token-form";
import { AuditLog } from "~/pages/space-settings/settings-auditlog";
import { Members } from "~/pages/space-settings/settings-members";
import { RegistrationTokensPage } from "~/pages/space-settings/settings-registration-tokens";
import { ApiTokensPage } from "~/pages/space-settings/api-tokens/settings-api-tokens";
import { GenerateServiceAccountsForm } from "~/pages/organization/settings/service-accounts-form";
import { ServiceAccounts } from "~/pages/organization/settings";
import { Cases } from "~/pages/organization/settings/cases";
import { SecurityModel } from "~/pages/space-settings/SecurityModel";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type Props = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: string[];
};

export function SettingsRoutes({ space, scope, availablePermissions }: Props) {
  return (
    <Routes>
      <Route
        element={
          <SpaceSettings
            space={space}
            availablePermissions={availablePermissions}
          />
        }
      >
        <Route index element={<GeneralSpaceSettings />} />
        <Route path="general" element={<GeneralSpaceSettings />} />
        <Route path="security-model" element={<SecurityModel />} />
        <Route
          path="cases"
          element={
            <Cases
              space={space}
              availablePermissions={availablePermissions}
              scope={scope}
            />
          }
        />
        <Route
          path="members/*"
          element={<Members {...{ space, availablePermissions }} />}
        />
        <Route
          path="auditlog"
          element={
            <AuditLog {...{ resourceMrn: space.mrn, availablePermissions }} />
          }
        />
        <Route
          path="serviceaccounts"
          element={<ServiceAccounts {...{ space, availablePermissions }} />}
        />
        <Route
          path="serviceaccounts/add"
          element={<GenerateServiceAccountsForm {...{ space }} />}
        />
        <Route
          path="registration-tokens"
          element={
            <RegistrationTokensPage {...{ space, availablePermissions }} />
          }
        />
        <Route
          path="api-tokens"
          element={<ApiTokensPage {...{ space, availablePermissions }} />}
        />

        <Route
          path="api-tokens/add"
          element={<GenerateApiTokenForm {...{ space }} />}
        />
      </Route>
    </Routes>
  );
}
