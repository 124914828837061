import { AffectedAssetsTable } from "~/pages/space/vulnerabilities/components/AffectedAssets/AffectedAssetsTable";
import { useAffectedAssets } from "~/pages/space/vulnerabilities/components/AffectedAssets/hooks/useAffectedAssets";
import {
  AssetContextualLinkType,
  SelectionProps,
} from "~/pages/space/vulnerabilities/components/AffectedAssets/types";
import { SectionHeading } from "~/components/DetailsPage/components";
import { EmptySection } from "~/components/vulnerabilities";
import { Grid2 } from "@mui/material";
import { Loading, LoadingFailed } from "~/components/loading";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { FindingsFilter } from "~/operations";

type AffectedAssetsAdapterProps = {
  scope: SpaceOrWorkspaceScope;
  selectionProps?: SelectionProps | undefined;
  emptyStateMessage?: string;
  urlContextType: (typeof AssetContextualLinkType)[keyof typeof AssetContextualLinkType];
  contextId: string;
  filter: FindingsFilter;
};

export const AffectedAssetsAdapter = ({
  scope,
  selectionProps,
  emptyStateMessage = "There are currently no affected assets.",
  urlContextType,
  contextId,
  filter,
}: AffectedAssetsAdapterProps) => {
  const {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount,
    pageInfo,
    fetchMore,
    error,
    loading,
  } = useAffectedAssets({
    scope,
    filter,
  });

  if (error) {
    return <LoadingFailed what="Affected Assets" />;
  }

  if (loading) {
    return <Loading what="Affected Assets" />;
  }

  return (
    <Grid2 id="affected-assets" size={12}>
      <SectionHeading heading="Affected assets" />
      {(assets?.length || 0) > 0 && !error ? (
        <AffectedAssetsTable
          assets={assets}
          orderBy={orderBy}
          handleSortClick={handleSortClick}
          scopeParams={scope.params}
          filteredTotalCount={filteredTotalCount}
          pageInfo={pageInfo}
          fetchMore={fetchMore}
          selectionProps={selectionProps}
          urlContextType={urlContextType}
          contextId={contextId}
        />
      ) : (
        <EmptySection
          id="affected-assets-list-empty"
          text={emptyStateMessage}
        />
      )}
    </Grid2>
  );
};
