import { SelectionProps } from "~/pages/space/vulnerabilities/components/AffectedAssets/types";
import { FindingType, ScoreStateFilter } from "~/operations";
import { useSearch } from "~/components/search/useSearch";
import { usePolicyOutletContext } from "~/components/policy-gql/policy-gql";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { useAffectedAssets } from "~/pages/space/vulnerabilities/components/AffectedAssets/hooks";
import { Loading, LoadingFailed } from "~/components/loading";
import { Grid } from "@mui/material";
import { AffectedAssetsTable } from "./AffectedAssetsTable";
import { EmptySection } from "~/components/vulnerabilities";

type SecurityPolicyAffectedAssetsAdaptorProps = {
  scope: SpaceOrWorkspaceScope;
  selectionProps?: SelectionProps | undefined;
};

export const SecurityPolicyAssetsTab = ({
  scope,
  selectionProps,
}: SecurityPolicyAffectedAssetsAdaptorProps) => {
  const { searchFilters } = useSearch();
  const { policy } = usePolicyOutletContext();
  const {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount,
    pageInfo,
    fetchMore,
    error,
    loading,
  } = useAffectedAssets({
    scope,
    filter: {
      policyMrn: policy.mrn,
      types: [FindingType.Check],
      state: ScoreStateFilter.Open,
      queryTerms: searchFilters,
    },
  });

  if (error) {
    return <LoadingFailed what="Affected Assets" />;
  }

  if (loading) {
    return <Loading what="Affected Assets" />;
  }

  return (
    <Grid id="affected-assets" item xs={12}>
      {(assets?.length || 0) > 0 && !error ? (
        <AffectedAssetsTable
          scopeParams={scope.params}
          assets={assets}
          orderBy={orderBy}
          handleSortClick={handleSortClick}
          filteredTotalCount={filteredTotalCount || 0}
          pageInfo={pageInfo}
          fetchMore={fetchMore}
          selectionProps={selectionProps}
          urlContextType={"ASSET"}
          contextId={""}
        />
      ) : (
        <EmptySection
          id="affected-assets-list-empty"
          text={`There are currently no affected assets for this policy`}
        />
      )}
    </Grid>
  );
};
