import { IosShare } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import {
  Outlet,
  Link as RouterLink,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { PolicyDownloadButton } from "~/components/policy-download-button";
import { Flex } from "~/components/Flex";
import {
  CopyLinkIcon,
  EmailIcon,
  TabsVariant,
  VariantIcon,
} from "~/components/ui-library";
import { copyToClipboard } from "~/lib/clipboard";
import { PolicyIdToMrn, PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { Space } from "~/lib/types";
import { TestIamActionsQuery, useGetPolicyQuery } from "~/operations";
import { PolicyAssignButton } from "./components/PolicyAssignButton";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { Caption } from "./components/Caption";
import { ShowMoreSummary } from "~/components/ShowMore/ShowMoreSummary";

export type PoliciesAddDetailPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function PoliciesAddDetailPage({
  space,
  availablePermissions,
}: PoliciesAddDetailPageProps) {
  const { policyId = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const shareOpen = Boolean(shareAnchorEl);

  const mrn = PolicyIdToMrn(policyId);
  const selectedVariantId = searchParams.get("variant");

  const { data, loading, error, refetch } = useGetPolicyQuery({
    variables: { input: { mrn, spaceMrn: space.mrn } },
    skip: !policyId,
  });

  const loadingFailed = !policyId || error || !data?.policy;
  const policy = data?.policy;

  const variants = [
    {
      label: "Overall",
      value: "",
      icon: "",
    },
    ...(
      policy?.variantPlatformFilters?.map((variant) => ({
        label: variant?.title || "",
        value: variant?.id || "",
        icon: <VariantIcon type={variant?.icon || ""} />,
      })) || []
    ).sort((a, b) => (a.label > b.label ? 1 : -1)),
  ];

  const generateHref = (tab: string): string => {
    return `/space/findings/policies/add/${PolicyMrnToURIEncodedId(policy?.mrn || "")}/${tab}?spaceId=${space.id}`;
  };

  const tabList: TabListItem[] = [
    {
      label: "Checks",
      to: generateHref("checks"),
      route: "/checks",
    },
    {
      label: "Properties",
      to: generateHref("properties"),
      route: "/properties",
    },
  ];

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "checks",
  );

  const shareLink = window.location.href;
  const shareEmailLink = `mailto:hello@mondoo.com?subject=${encodeURIComponent("Take a look at this policy")}&body=${encodeURIComponent(shareLink)}`;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/findings/policies"
      component={RouterLink}
      to={`/space/findings/policies?spaceId=${space.id}`}
    >
      Policies
    </Link>,
    <Link
      key="/space/findings/policies/add"
      component={RouterLink}
      to={`/space/findings/policies/add?spaceId=${space.id}`}
    >
      Enable Policies
    </Link>,
    <Typography key="/space/findings/policies/add/policyId">
      {policy?.name}
    </Typography>,
  ];

  document.title = `${policy?.name} · Enable Policies · Policies · Security · Mondoo`;

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      {policy && (
        <>
          <Box
            id="policy-header"
            mb={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Flex flexDirection="column">
              <Typography
                variant="h4"
                fontWeight={700}
                textTransform="uppercase"
                sx={{ mb: 1 }}
              >
                {policy.name}
              </Typography>
              <Caption
                trustLevel={policy.trustLevel}
                version={policy.version}
                authors={policy.authors}
              />
            </Flex>
            <Flex id="policy-actions" alignItems="center" gap={4}>
              <Tooltip title="Share" placement="top" arrow>
                <IconButton onClick={(e) => setShareAnchorEl(e.currentTarget)}>
                  <IosShare />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={shareAnchorEl}
                open={shareOpen}
                onClose={() => setShareAnchorEl(null)}
                onClick={() => setShareAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem onClick={() => copyToClipboard(shareLink)}>
                  <ListItemIcon>
                    <CopyLinkIcon fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText>Copy Link</ListItemText>
                </MenuItem>
                <MenuItem component="a" href={shareEmailLink}>
                  <ListItemIcon>
                    <EmailIcon fontSize="inherit" />
                  </ListItemIcon>
                  <ListItemText>Email</ListItemText>
                </MenuItem>
              </Menu>
              <PolicyDownloadButton
                space={space}
                policy={policy}
                size="small"
                iconOnly
              />
              <Divider orientation="vertical" flexItem />
              <PolicyAssignButton
                spaceMrn={space.mrn}
                policyMrn={policy.mrn}
                assigned={policy.assigned}
                action={policy.action}
                onChange={() => {
                  refetch();
                }}
                advanced
              />
            </Flex>
          </Box>
          {variants.length > 1 && (
            <TabsVariant
              options={variants}
              selectedTab={selectedVariantId || ""}
              onTabChange={(variantId) => {
                variantId.length > 0
                  ? searchParams.set("variant", variantId)
                  : searchParams.delete("variant");
                setSearchParams(searchParams);
              }}
              mb={3}
            />
          )}
          {policy.docs && <ShowMoreSummary maxHeight={72} text={policy.docs} />}
        </>
      )}

      {loading && <LoadingPage what="Policy" />}
      {!loading && loadingFailed && <LoadingFailedPage what="Policy" />}
      {!loading && policy && (
        <Box>
          <TabNavigation {...{ id: "policy-tabs", tabList, currentTab }} />
          <Outlet
            context={{
              space,
              availablePermissions,
              policyId,
              variantPlatformFilter: selectedVariantId,
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export type PolicyAddDetailOutletContextType = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  policyId: string;
  variantPlatformFilter: string | null;
};

export function usePolicyAddDetailOutletContext() {
  return useOutletContext<PolicyAddDetailOutletContextType>();
}
