import { TableCell } from "@mui/material";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";
import { ScoreRating } from "~/operations";

type ImpactCellProps = {
  impact: number;
  rating: ScoreRating;
  isActive: boolean;
  hideTooltip?: boolean;
};

export function ImpactCell({
  impact,
  rating,
  isActive,
  ...props
}: ImpactCellProps) {
  return (
    <TableCell>
      <ImpactUpdated
        impact={{ rating, value: impact }}
        isActive={isActive}
        isRiskScore
        hideTooltip={props.hideTooltip}
      />
    </TableCell>
  );
}
