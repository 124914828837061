import { Divider } from "@mui/material";

export function ChecksRowDivider({ title }: { title: string }) {
  return (
    <Divider
      textAlign="left"
      sx={{
        pb: 2,
        fontSize: 16,
        fontWeight: 700,
        "& .MuiDivider-wrapper ": {
          pl: 0,
          pr: 3,
        },
        "&::before": { width: 0 },
        "&::after": {
          width: "100%",
          borderColor: (theme) => theme.palette.background.light,
        },
      }}
    >
      {title}
    </Divider>
  );
}
