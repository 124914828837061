import { Fragment, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { PasswordField } from "~/components/Form/components/PasswordField";

type PostgreSQLFormInput = {
  integrationName: string;
  host: string;
  database: string;
  user: string;
  password: string;
};

const defaultValues: PostgreSQLFormInput = {
  integrationName: "",
  host: "",
  database: "",
  user: "",
  password: "",
};

export function PostgreSQLIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    reset,
    getFieldState,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: {
          input: { mrn: integrationMrn, type: ActionType.RunExport },
        },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename ===
        "PostgresConfigurationOptions"
      ) {
        const { host, port, database, user } =
          updateFlow.integration.configurationOptions;
        reset({
          integrationName: updateFlow.integration.name,
          host,
          database,
          user,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<PostgreSQLFormInput> = async (data) => {
    const postgresConfigurationOptions = {
      host: data.host.trim(),
      port: "5432", // we only allow 5432
      database: data.database.trim(),
      user: data.user.trim(),
      password: data.password.trim(),
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              type: ClientIntegrationType.Postgres,
              configurationOptions: {
                postgresConfigurationOptions,
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/postgres/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Postgres,
              longLivedToken: false,
              configurationOptions: {
                postgresConfigurationOptions,
              },
            },
          },
        });
        navigate(`/space/integrations/postgres?spaceId=${space.id}`);
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  document.title = "PostgreSQL · Integrations Setup · Mondoo";

  return (
    <Fragment>
      <IntegrationAddHeader {...{ type: IntegrationType.Postgres }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Step 1 */}
          <Box pb={4}>
            <Command
              number={1}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Choose an integration name
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Please choose a descriptive name that lets you easily identify
                your integration.
              </Typography>
              <Controller
                name="integrationName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={Boolean(updateFlow)}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Your integration name..."
                    error={Boolean(errors.integrationName)}
                    helperText={
                      Boolean(errors.integrationName) && (
                        <ValidationMessage error={errors.integrationName} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>

          {/* Step 2 */}
          <Box pb={4}>
            <Command
              number={2}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Define the export destination
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                The information below must match your existing PostgreSQL
                configuration. To learn more, read the{" "}
                <Link
                  href="https://mondoo.com/docs/platform/maintain/export/postgresql/"
                  target="_blank"
                  rel="noopener"
                >
                  Mondoo documentation
                </Link>
                .
              </Typography>

              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Database server host
              </Typography>
              <Controller
                name="host"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Server IP or FQDN..."
                    error={Boolean(errors.host)}
                    helperText={
                      getFieldState("host").isTouched &&
                      Boolean(errors.host) && (
                        <ValidationMessage
                          error={errors.host}
                          integrationTypeId="postgres"
                        />
                      )
                    }
                  />
                )}
              />

              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Database
              </Typography>
              <Controller
                name="database"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Database name..."
                    error={Boolean(errors.database)}
                    helperText={
                      getFieldState("database").isTouched &&
                      Boolean(errors.database) && (
                        <ValidationMessage
                          error={errors.database}
                          integrationTypeId="postgres"
                        />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>
          {/* step 3 */}
          <Box pb={4}>
            <Command
              number={3}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Enter your credentials
            </Command>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, mt: 2 }}
            >
              Provide the credentials for your PostgreSQL database. To learn
              more, read the{" "}
              <Link
                href="https://mondoo.com/docs/platform/maintain/export/postgresql/"
                target="_blank"
                rel="noopener"
              >
                Mondoo documentation
              </Link>
              .
            </Typography>

            <Grid container columnSpacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="user"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      sx={{
                        mb: {
                          xs: 2,
                          md: 0,
                        },
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="User name ..."
                      error={Boolean(errors.user)}
                      helperText={
                        getFieldState("user").isTouched &&
                        Boolean(errors.user) && (
                          <ValidationMessage
                            error={errors.user}
                            integrationTypeId="postgres"
                          />
                        )
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <PasswordField
                      {...field}
                      fullWidth
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="Password ..."
                      error={Boolean(errors.password)}
                      helperText={
                        getFieldState("password").isTouched &&
                        Boolean(errors.password) && (
                          <ValidationMessage
                            error={errors.password}
                            integrationTypeId="postgres"
                          />
                        )
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              disabled={updateFlow ? !isValid || !isDirty : !isValid}
            >
              {updateFlow ? "update configuration" : "create export"}
            </Button>
          </Box>
        </form>
      </Box>
    </Fragment>
  );
}
