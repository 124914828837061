import { Container, Grid2 } from "@mui/material";
import { SectionHeading } from "../../components";
import { RiskFactors } from "../AssetReport/RiskFactors";
import { SimpleReportHeader } from "../components";
import { Assets } from "../lib/SecurityReport/Assets";
import {
  GroupSummary,
  InventoryList,
} from "../lib/SecurityReport/SecurityReport";
import { spacing } from "../lib/SharedVariables";
import { Stats } from "~/components/DetailsPage/components";
import { ReportType } from "~/reportApp/reportingApp";
import { useChecksOrVulnerabilitiesReport } from "./hooks/useChecksOrVulnerabilities";
import { ChecksTables } from "./ChecksTables";
import { VulnerabilitiesTables } from "./VulnerabilitiesTables";

type ChecksOrVulnerabilitiesReportProps = {
  reportType: ReportType;
};

export function ChecksOrVulnerabilitiesReport({
  reportType,
}: ChecksOrVulnerabilitiesReportProps) {
  const {
    title,
    stats,
    groupSummary,
    riskFactorsWithDocs,
    totalScanned,
    inventoryBreakdown,
  } = useChecksOrVulnerabilitiesReport({ reportType });

  return (
    <Container sx={{ p: 4 }}>
      <Grid2 container sx={{ mb: spacing[25] }}>
        <SimpleReportHeader title={title} />
        <Stats id="detail-content-stats" stats={stats} />
        <GroupSummary groupSummary={groupSummary} />
      </Grid2>
      <Grid2 size={12} sx={{ mb: spacing[25] }}>
        <RiskFactors dataType="security" riskFactors={riskFactorsWithDocs} />
      </Grid2>
      <Grid2 size={12} sx={{ mb: spacing[50] }}>
        <SectionHeading
          heading="Inventory breakdown"
          dividerContent={
            totalScanned ? `${totalScanned} Assets total` : undefined
          }
        />
        <InventoryList softwareEdges={inventoryBreakdown} />
      </Grid2>
      {reportType === ReportType.Checks && <ChecksTables />}
      {reportType === ReportType.Vulnerabilities && <VulnerabilitiesTables />}
    </Container>
  );
}
