// @ts-nocheck
// 🚧 TODO: after CloudSQL schema is ready, remove `@ts-nocheck` before un-feature flagging
import { Fragment, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronRightIcon,
  OpenInNewIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import { AnimatePresence, motion } from "framer-motion";
import { Dropzone, DropzoneProps, DropzoneText } from "~/components/dropzone";
import { fadeInOut } from "~/lib/animations";
import { GreenCheckCircleIcon } from "~/pages/invitation";
import { UpdateFlowData } from "../../types";
import { PasswordField } from "~/components/Form/components/PasswordField";

type CloudSQLFormInput = {
  integrationName: string;
  destination: string;
  username: string;
  password: string;
  jsonConfig: string;
};

const defaultValues: CloudSQLFormInput = {
  integrationName: "",
  destination: "",
  username: "",
  password: "",
  jsonConfig: "",
};

export function CloudSQLIntegrationForm({
  space,

  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const [jsonConfig] = watch(["jsonConfig"]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: {
          input: { mrn: integrationMrn, type: ActionType.RunExport },
        },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename ===
        "PostgreSQLConfigurationOptions"
      ) {
        const { host, port, databaseName, username } =
          updateFlow.integration.configurationOptions;
        reset({
          integrationName: updateFlow.integration.name,
          host,
          port,
          databaseName,
          username,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<CloudSQLFormInput> = async (data) => {
    const PostgreSQLConfigurationOptions = {
      host: data.host.trim(),
      port: data.port.trim(),
      databaseName: data.databaseName.trim(),
      username: data.username.trim(),
      password: data.password.trim(),
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              type: ClientIntegrationType.CloudSQL,
              configurationOptions: {
                PostgreSQLConfigurationOptions,
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/cloudsql/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.CloudSQL,
              longLivedToken: false,
              configurationOptions: {
                PostgreSQLConfigurationOptions,
              },
            },
          },
        });
        navigate(`/space/integrations/cloudsql?spaceId=${space.id}`);
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = async (files) => {
    setValue("jsonConfig", await files[0].text());
  };

  document.title = "CloudSQL · Integrations Setup · Mondoo";

  return (
    <Fragment>
      {/* <IntegrationAddHeader {...{ type: IntegrationType.CloudSQL }} /> */}
      <IntegrationAddHeader {...{ type: "CLOUDSQL" }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Step 1 */}
          <Box pb={4}>
            <Command
              number={1}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Choose an integration name
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Please choose a descriptive name that lets you easily identify
                your integration – you{" "}
                <Typography component="span" fontWeight="bold">
                  cannot
                </Typography>{" "}
                change this name later!
              </Typography>
              <Controller
                name="integrationName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={Boolean(updateFlow)}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Your integration name..."
                    error={Boolean(errors.integrationName)}
                    helperText={
                      Boolean(errors.integrationName) && (
                        <ValidationMessage error={errors.integrationName} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>

          {/* Step 2 */}
          <Box pb={4}>
            <Command
              number={2}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Define the export destination
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Provide the SQL connection name. To learn more, read the{" "}
                <Link
                  href="https://mondoo.com/docs/platform/maintain/export/cloudsql/"
                  target="_blank"
                  rel="noopener"
                >
                  Google documentation
                </Link>
                .
              </Typography>
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Database server host
              </Typography>
              <Controller
                name="destination"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Cloud SQL connection name..."
                    error={Boolean(errors.destination)}
                    helperText={
                      getFieldState("destination").isTouched &&
                      Boolean(errors.destination) && (
                        <ValidationMessage
                          error={errors.destination}
                          integrationTypeId="cloudsql"
                        />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>
          {/* step 3 */}
          <Box pb={4}>
            <Command
              number={3}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Enter your PostgreSQL database credentials
            </Command>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, mt: 2 }}
            >
              Provide the credentials to authenticate with your PostgreSQL
              database – the GCP credentials are contained in the service
              account in the next steps.
            </Typography>

            <Grid container columnSpacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      sx={{
                        mb: {
                          xs: 2,
                          md: 0,
                        },
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="User name ..."
                      error={Boolean(errors.username)}
                      helperText={
                        getFieldState("username").isTouched &&
                        Boolean(errors.username) && (
                          <ValidationMessage
                            error={errors.username}
                            integrationTypeId="cloudsql"
                          />
                        )
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <PasswordField
                      {...field}
                      fullWidth
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="Password ..."
                      error={Boolean(errors.password)}
                      helperText={
                        getFieldState("password").isTouched &&
                        Boolean(errors.password) && (
                          <ValidationMessage
                            error={errors.password}
                            integrationTypeId="cloudsql"
                          />
                        )
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          {/* step 4 */}
          <Box pb={4}>
            <Command
              number={4}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Create your Google Service Account
            </Command>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, mt: 2 }}
            >
              Before you can integrate your Google Cloud Platform integration or
              project with any third party service, you will need to create a
              Service Account. You can do this in the Google Cloud console. For
              more information on this step, read the Mondoo documentation and
              the Google documentation.
            </Typography>
            <Box>
              <Button
                variant="outlined"
                endIcon={<OpenInNewIcon />}
                sx={{ color: "#8797FF" }} //<==== new color?
              >
                Go to Google Cloud console
              </Button>
            </Box>
          </Box>

          {/* step 5 */}
          <Box pb={4}>
            <Command
              number={5}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Provide your Google service account config
            </Command>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 3, mt: 2 }}
            >
              Upload the .json file generated in the previous step. For more
              help, read the GCP documentation.
            </Typography>
            <AnimatePresence mode="wait">
              {!jsonConfig ? (
                <Box key="dropzone" component={motion.div} {...fadeInOut}>
                  <Dropzone
                    className="mondoo-dropzone-area"
                    accept={{
                      "application/x-x509-ca-cert": [".pem"],
                      "application/x-x509-user-cert": [".pem"],
                      "application/x-pkcs12": [".pem"],
                      "application/x-pem-file": [".pem"],
                    }}
                    multiple={false}
                    showAlerts={["error"]}
                    onDropAccepted={onDropAccepted}
                  >
                    <DropzoneText>
                      Drag and drop your .pem file here
                    </DropzoneText>
                  </Dropzone>
                </Box>
              ) : (
                <Box
                  key="success"
                  component={motion.div}
                  {...fadeInOut}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <Typography variant="h6">
                      Successfully loaded certificate
                    </Typography>
                    <GreenCheckCircleIcon fontSize="large" sx={{ ml: 2 }} />
                  </Box>

                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setValue("jsonConfig", "")}
                  >
                    Remove certificate
                  </Button>
                </Box>
              )}
            </AnimatePresence>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              disabled={updateFlow ? !isValid : !isValid || !jsonConfig}
            >
              {updateFlow ? "update configuration" : "create export"}
            </Button>
          </Box>
        </form>
      </Box>
    </Fragment>
  );
}
