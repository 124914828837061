import { Fragment, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  BucketOutputType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import {
  helperTextStyles,
  SECURE_HOST_URL_PATTERN,
  ValidationMessage,
} from "../../validations/helpers";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";

type AzureBlobFormInput = {
  integrationName: string;
  output: BucketOutputType;
  blobSasURL: string;
};

const defaultValues: AzureBlobFormInput = {
  integrationName: "",
  output: BucketOutputType.Jsonl,
  blobSasURL: "",
};

export function AzureBlobIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: {
          input: { mrn: integrationMrn, type: ActionType.RunExport },
        },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      const configOptions = updateFlow.integration.configurationOptions;
      if (configOptions?.__typename === "AzureBlobConfigurationOptions") {
        const { output } = configOptions;
        reset({
          integrationName: updateFlow.integration.name,
          output:
            output === "JSONL"
              ? BucketOutputType.Jsonl
              : output === "CSV"
                ? BucketOutputType.Csv
                : BucketOutputType.Unknown,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<AzureBlobFormInput> = async (data) => {
    const azureBlobConfigurationOptions = {
      output: data.output,
      blobSasURL: data.blobSasURL.trim(),
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.AzureBlob,
              configurationOptions: {
                azureBlobConfigurationOptions,
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/azure_blob/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.AzureBlob,
              longLivedToken: false,
              configurationOptions: {
                azureBlobConfigurationOptions,
              },
            },
          },
        });
        navigate(`/space/integrations/azure_blob?spaceId=${space.id}`);
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  document.title = "Azure Blob Storage · Integrations Setup · Mondoo";

  return (
    <Fragment>
      <IntegrationAddHeader {...{ type: IntegrationType.AzureBlob }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Step 1 */}
          <Box pb={4}>
            <Command
              number={1}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Choose an integration name
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Please choose a descriptive name that lets you easily identify
                your integration.
              </Typography>
              <Controller
                name="integrationName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Your integration name..."
                    error={Boolean(errors.integrationName)}
                    helperText={
                      Boolean(errors.integrationName) && (
                        <ValidationMessage error={errors.integrationName} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>

          {/* Step 2 */}
          <Box pb={4}>
            <Command
              number={2}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Define the export destination
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Paste the Blob SAS URL from the Azure Portal. To learn how, read
                the{" "}
                <Link
                  href="https://mondoo.com/docs/platform/maintain/export/azure-blob/"
                  target="_blank"
                  rel="noopener"
                >
                  Mondoo documentation
                </Link>
                .
              </Typography>
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Blob SAS URL
              </Typography>
              <Controller
                name="blobSasURL"
                control={control}
                rules={{ required: true, pattern: SECURE_HOST_URL_PATTERN }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="https://storage-account.blob.core.windows.net/blob-container?sp=r&..."
                    error={Boolean(errors.blobSasURL)}
                    helperText={
                      getFieldState("blobSasURL").isTouched &&
                      Boolean(errors.blobSasURL) && (
                        <ValidationMessage
                          error={errors.blobSasURL}
                          integrationTypeId="azure_blob"
                        />
                      )
                    }
                  />
                )}
              />
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Export as
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="filetype-select-label">
                  Select a filetype...
                </InputLabel>
                <Controller
                  name="output"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Fragment>
                      <Select
                        labelId="filetype-select-label"
                        id="filetype-select"
                        label="filetype"
                        error={Boolean(errors.output)}
                        sx={{
                          "& .MuiInputBase-input": {
                            background: theme.palette.code.background,
                          },
                          "& .MuiSelect-icon": {
                            color: "text.secondary",
                          },
                        }}
                        {...field}
                      >
                        <MenuItem value={BucketOutputType.Jsonl}>
                          .jsonl
                        </MenuItem>
                        <MenuItem value={BucketOutputType.Csv}>.csv</MenuItem>
                      </Select>
                      {getFieldState("output").isTouched &&
                        Boolean(errors.output) && (
                          <FormHelperText>
                            <ValidationMessage
                              error={errors.output}
                              integrationTypeId="azure_blob"
                            />
                          </FormHelperText>
                        )}
                    </Fragment>
                  )}
                />
              </FormControl>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              disabled={updateFlow ? !isValid : !isValid}
            >
              {updateFlow ? "update configuration" : "create export"}
            </Button>
          </Box>
        </form>
      </Box>
    </Fragment>
  );
}
