import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { ImpactRating } from "../impact-rating";
import { Impact as ImpactType } from "~/operations";
import { ImpactTooltipUpdated } from "./impact-tooltip-updated";
import { useImpactUpdated } from "./useImpactUpdated";

type ImpactRatioProps = {
  impact: Omit<ImpactType, "__typename">;
  adjustedColor?: string;
  hideTooltip?: boolean;
  showText?: boolean;
  isActive?: boolean;
  children?: ReactElement;
  isRiskScore?: boolean;
  isCVSS?: boolean;
  centerDots?: boolean;
  options?: ImpactRatingOptions;
};

export type ImpactRatingOptions = {
  size?: number;
  border?: number;
  fontSize?: number;
};

export const ImpactUpdated = ({
  impact,
  adjustedColor,
  hideTooltip = false,
  showText = true, // show the text label i.e "high", "medium", "low" etc.
  isActive = true,
  isRiskScore = false,
  isCVSS = false,
  centerDots = false,
  options,
}: ImpactRatioProps) => {
  const { impactData, color, label } = useImpactUpdated({
    impact,
    adjustedColor,
    isActive,
  });

  const fontSize = options?.fontSize || 12;

  const TooltipWrapper = ({ children }: { children: ReactElement }) =>
    hideTooltip ? (
      children
    ) : (
      <ImpactTooltipUpdated
        color={color}
        impact={impact}
        isRiskScore={isRiskScore}
        isCVSS={isCVSS}
      >
        {children}
      </ImpactTooltipUpdated>
    );

  return (
    <TooltipWrapper>
      <Box className="impact" display="inline-flex" flexDirection="column">
        {showText && (
          <Box className="impact-label" mb="4px">
            <Typography sx={{ fontWeight: 400, fontSize: `${fontSize}px` }}>
              {label}
            </Typography>
          </Box>
        )}
        <Box className="impact-rating">
          <ImpactRating
            impactData={impactData}
            color={color}
            options={options}
            centerDots={centerDots}
          />
        </Box>
      </Box>
    </TooltipWrapper>
  );
};
