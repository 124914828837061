import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { SubmitHandler, useForm } from "react-hook-form";
import { SvgIconProps } from "@mui/material";
import { getError } from "~/lib/handle-error";
import { Space } from "~/lib/types";
import {
  Asset,
  ComplianceControl,
  ComplianceFramework,
  DocumentFormat,
  DocumentType,
  useGenerateDocumentMutation,
} from "~/operations";

enum FormKeys {
  reportName = "reportName",
  options_outOfScope = "options.outOfScope",
  options_details_details = "options.details.details",
  options_details_platform = "options.details.platform",
  options_details_mrn = "options.details.mrn",
  options_details_cloudIdentifier = "options.details.cloudIdentifier",
  options_output = "options.output",
}

//fields that should be indented under the "Asset details" checkbox, as well as selected when the "Asset details" checkbox is selected
const shouldIndent = [
  FormKeys.options_details_platform,
  FormKeys.options_details_mrn,
  FormKeys.options_details_cloudIdentifier,
];

//the array of checkboxes that are rendered in the form
const formValuesArray: {
  name: FormKeys;
  label: string;
}[] = [
  {
    name: FormKeys.options_outOfScope,
    label: "Assets marked out of scope (includes justification)",
  },
  { name: FormKeys.options_details_details, label: "Asset details" },
  { name: FormKeys.options_details_platform, label: "Platform details" },
  { name: FormKeys.options_details_mrn, label: "MRN values" },
  {
    name: FormKeys.options_details_cloudIdentifier,
    label: "Cloud identifier IDs ",
  },
  { name: FormKeys.options_output, label: "Check output" },
];

//default values for the form
const defaultFormValues = {
  reportName: "",
  options: {
    outOfScope: false,
    details: {
      details: false,
      platform: false,
      mrn: false,
      cloudIdentifier: false,
    },
    output: false,
  },
};

type ExportFormInputs = typeof defaultFormValues;

type CommonProps = {
  title?: string;
  space: Space;
  IconProps?: SvgIconProps;
};

type frameworkProps = CommonProps & {
  documentType: DocumentType.FrameworkReport;
  frameworkMrn: ComplianceFramework["mrn"];
  controlMrn?: never;
  assetMrn?: never;
  advisoryId?: never;
  vulnerabilityId?: never;
  checkId?: never;
};

type controlProps = CommonProps & {
  documentType: DocumentType.ControlReport;
  frameworkMrn: ComplianceFramework["mrn"];
  controlMrn: ComplianceControl["mrn"];
  assetMrn?: never;
  advisoryId?: never;
  vulnerabilityId?: never;
  checkId?: never;
};

type assetProps = CommonProps & {
  documentType: DocumentType.AssetReport;
  frameworkMrn?: never;
  controlMrn?: never;
  assetMrn: Asset["mrn"];
  advisoryId?: never;
  vulnerabilityId?: never;
  checkId?: never;
};

type advisoryProps = CommonProps & {
  documentType: DocumentType.AdvisoryReport;
  frameworkMrn?: never;
  controlMrn?: never;
  assetMrn?: never;
  vulnerabilityId?: never;
  checkId?: never;
  advisoryId: string;
};

type vulnerabiilityProps = CommonProps & {
  documentType: DocumentType.VulnerabilityReport;
  frameworkMrn?: never;
  controlMrn?: never;
  assetMrn?: never;
  advisoryId?: never;
  checkId?: never;
  vulnerabilityId: string;
};

type checkProps = CommonProps & {
  documentType: DocumentType.CheckReport;
  frameworkMrn?: never;
  controlMrn?: never;
  assetMrn?: never;
  advisoryId?: never;
  vulnerabilityId?: never;
  checkId: string;
};

type securityProps = CommonProps & {
  documentType: DocumentType.SecurityReport;
  frameworkMrn?: never;
  controlMrn?: never;
  assetMrn?: never;
  advisoryId?: never;
  vulnerabilityId?: never;
  checkId?: never;
};

type checksProps = CommonProps & {
  documentType: DocumentType.ChecksReport;
  frameworkMrn?: never;
  controlMrn?: never;
  assetMrn?: never;
  advisoryId?: never;
  vulnerabilityId?: never;
  checkId?: never;
};

type vulnerabiilitiesProps = CommonProps & {
  documentType: DocumentType.VulnerabilitiesReport;
  frameworkMrn?: never;
  controlMrn?: never;
  assetMrn?: never;
  advisoryId?: never;
  vulnerabilityId?: never;
  checkId?: never;
};

type exportProps = CommonProps & {
  documentType: DocumentType.Export;
  frameworkMrn?: never;
  controlMrn?: never;
  assetMrn?: never;
  advisoryId?: never;
  vulnerabilityId?: never;
  checkId?: never;
};

export type UseExportButtonProps =
  | exportProps
  | frameworkProps
  | controlProps
  | assetProps
  | advisoryProps
  | vulnerabiilityProps
  | checkProps
  | securityProps
  | checksProps
  | vulnerabiilitiesProps;

export function UseExportButton({
  documentType,
  title,
  space,
  frameworkMrn,
  controlMrn,
  assetMrn,
  advisoryId,
  vulnerabilityId,
  checkId,
}: UseExportButtonProps) {
  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false);
  const [finishedDialogOpen, setFinishedDialogOpen] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  //if a title is provided in the props, set the default value of the reportName field to that title
  let defaultValues = {
    ...defaultFormValues,
    ...(title && { reportName: title }),
  };

  const { control, handleSubmit, watch, reset, formState, setValue } =
    useForm<ExportFormInputs>({
      mode: "onBlur",
      defaultValues,
    });

  const [options] = watch(["options"]);

  //array of all of the checkboxes in the form
  const allOptions = [
    options.outOfScope,
    options.details.details,
    options.details.platform,
    options.details.mrn,
    options.details.cloudIdentifier,
    options.output,
  ];

  //if any of the checkboxes are unchecked, set allSelected to false
  useEffect(() => {
    const notAllSelected = allOptions.some((values) => values === false);
    setAllSelected(!notAllSelected);
  }, allOptions);

  //if the "Asset details" checkbox is checked, check all of the checkboxes that should be indented
  useEffect(() => {
    if (options.details.details) {
      shouldIndent.forEach((key) => setValue(key, true));
    } else {
      shouldIndent.forEach((key) => setValue(key, false));
    }
  }, [options.details.details]);

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultValues);
    }
    handleClose();
  }, [formState.isSubmitSuccessful]);

  const [generateDocument] = useGenerateDocumentMutation();

  //open the dialog
  const handleOpen = () => {
    setExportDialogOpen(true);
  };

  //close the dialog
  const handleClose = () => {
    setExportDialogOpen(false);
  };

  const handleFinishedOpen = () => {
    setFinishedDialogOpen(true);
  };

  const handleFinishedClose = () => {
    setFinishedDialogOpen(false);
  };

  //select or deselect all of the checkboxes in the form
  const handleSelectAllOptions = () => {
    if (allSelected) {
      formValuesArray.forEach(({ name }) => setValue(name, false));
    } else {
      formValuesArray.forEach(({ name }) => setValue(name, true));
    }
  };

  //submit the form
  const onSubmit: SubmitHandler<ExportFormInputs> = async (data) => {
    try {
      const getOptions = () => {
        if (documentType === DocumentType.AssetReport) {
          return {
            assetReportOptions: {
              assetMrn: assetMrn,
            },
          };
        }

        if (documentType === DocumentType.FrameworkReport) {
          return {
            frameworkOptions: {
              frameworkMRN: frameworkMrn,
            },
          };
        }

        if (documentType === DocumentType.ControlReport) {
          return {
            controlOptions: {
              frameworkMRN: frameworkMrn,
              controlMRN: controlMrn,
            },
          };
        }

        if (documentType === DocumentType.AdvisoryReport) {
          return {
            advisoryReportOptions: {
              advisoryId: advisoryId,
              scopeMrn: space.mrn,
            },
          };
        }

        if (documentType === DocumentType.VulnerabilityReport) {
          return {
            vulnerabilityReportOptions: {
              vulnerabilityId: vulnerabilityId,
              scopeMrn: space.mrn,
            },
          };
        }

        if (documentType === DocumentType.CheckReport) {
          return {
            findingReportOptions: {
              findingMrn: checkId,
              scopeMrn: space.mrn,
            },
          };
        }
        if (
          documentType === DocumentType.SecurityReport ||
          documentType === DocumentType.ChecksReport ||
          documentType === DocumentType.VulnerabilitiesReport
        ) {
          {
            return {
              scopeReportOptions: {
                scopeMrn: space.mrn,
              },
            };
          }
        }

        return {};
      };

      const inputOptions = getOptions();
      if (!inputOptions) throw new Error("Missing valid mrn.");

      const generateInput = {
        scopeMRN: space.mrn,
        format: DocumentFormat.Pdf,
        type: documentType,
        name: data.reportName.trim(),
        ...inputOptions,
      };

      await generateDocument({ variables: { input: generateInput } });
      handleFinishedOpen();
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(
        `Something went wrong while attempting to generate your report: ${errorMessage}`,
        { variant: "error" },
      );
    }
  };

  return {
    exportDialog: {
      exportDialogOpen,
      handleClose,
      handleOpen,
    },
    finishedDialog: {
      finishedDialogOpen,
      handleFinishedOpen,
      handleFinishedClose,
    },
    form: {
      control,
      handleSubmit,
      reset,
      formState,
      shouldIndent,
      onSubmit,
      handleSelectAllOptions,
      allSelected,
      formValuesArray,
    },
  };
}

// Function to let the export button handle the prop type logic

type AllExportTypes = {
  documentType: DocumentType;
  frameworkMrn?: ComplianceFramework["mrn"];
  controlMrn?: ComplianceControl["mrn"];
  assetMrn?: Asset["mrn"];
  advisoryId?: string;
  vulnerabilityId?: string;
  checkId?: string;
} & CommonProps;

export function determineExportProps({
  ...props
}: AllExportTypes): UseExportButtonProps {
  switch (props.documentType) {
    case DocumentType.AssetReport:
      if (!props.assetMrn)
        throw new Error("Asset MRN is required for Asset Report");
      return {
        documentType: DocumentType.AssetReport,
        title: props.title,
        assetMrn: props.assetMrn,
        space: props.space,
      };
    case DocumentType.AdvisoryReport:
      if (!props.advisoryId)
        throw new Error("Advisory ID is required for Advisory Report");
      return {
        documentType: DocumentType.AdvisoryReport,
        title: props.title,
        advisoryId: props.advisoryId,
        space: props.space,
      };
    case DocumentType.VulnerabilityReport:
      if (!props.vulnerabilityId)
        throw new Error("CVE ID is required for Vulnerability Report");
      return {
        documentType: DocumentType.VulnerabilityReport,
        title: props.title,
        vulnerabilityId: props.vulnerabilityId,
        space: props.space,
      };
    case DocumentType.ControlReport:
      if (!props.frameworkMrn || !props.controlMrn)
        throw new Error(
          "Framework MRN and Control MRN are required for Control Report",
        );
      return {
        documentType: DocumentType.ControlReport,
        title: props.title,
        frameworkMrn: props.frameworkMrn,
        controlMrn: props.controlMrn,
        space: props.space,
      };
    case DocumentType.FrameworkReport:
      if (!props.frameworkMrn)
        throw new Error("Framework MRN is required for Framework Report");
      return {
        documentType: DocumentType.FrameworkReport,
        title: props.title,
        frameworkMrn: props.frameworkMrn,
        space: props.space,
      };

    case DocumentType.CheckReport:
      if (!props.checkId)
        throw new Error("Check ID is required for Check Report");
      return {
        documentType: DocumentType.CheckReport,
        title: props.title,
        checkId: props.checkId,
        space: props.space,
      };

    case DocumentType.SecurityReport:
    case DocumentType.ChecksReport:
    case DocumentType.VulnerabilitiesReport:
      return {
        documentType: props.documentType,
        title: props.title,
        space: props.space,
      };
    default:
      throw new Error("Invalid document type");
  }
}
