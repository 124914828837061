import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import { NavDrawerSpacesIcon } from "~/components/icons";
import { SpaceItemCard } from "~/components/space-cards";
import { TitleDivider } from "~/components/title-divider";
import { useListSharedSpacesQuery } from "~/operations";
import { Loading, LoadingFailedPage } from "~/components/loading";
import { SpaceData } from "~/lib/types";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { Metrics } from "~/utils/arrow";

type SharedSpacesByOrg = { [orgId: string]: SpaceData[] };

export function SharedSpacesPage() {
  const { setTree } = useAppNavDrawer();
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);
  const { data, loading, error, fetchMore } = useListSharedSpacesQuery({
    variables: { metricMrns: [Metrics.SecurityAssetsStats] },
  });
  const { pageInfo } = data?.sharedSpaces || {};

  useEffect(() => {
    setTree([
      {
        text: "Spaces",
        icon: <NavDrawerSpacesIcon />,
        link: {
          to: `/spaces`,
        },
      },
    ]);
    return () => setTree([]);
  }, []);

  document.title = `Shared Spaces · Mondoo`;

  if (loading) {
    return <Loading what="shared spaces" />;
  }

  if (error && (data?.sharedSpaces?.edges?.length || 0) === 0) {
    return <LoadingFailedPage what="shared spaces" />;
  }

  const sharedSpacesByOrg = () => {
    const sharedSpaces = data?.sharedSpaces?.edges || [];
    const sharedSpacesByOrg = sharedSpaces
      .slice(pageItems.from, pageItems.to)
      .reduce<SharedSpacesByOrg>((acc, { node }) => {
        const currOrgId = node.organization?.id || "";
        const currOrgSpaces = acc[currOrgId] || [];
        acc[currOrgId] = [...currOrgSpaces, node];
        return acc;
      }, {});
    return sharedSpacesByOrg;
  };

  return (
    <Box>
      {/* Heading */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h4" fontWeight="bold" textTransform="uppercase">
            Shared Spaces
          </Typography>
        </Box>
      </Box>
      {Object.entries(sharedSpacesByOrg()).map(([orgId, spaces]) => (
        <Box key={orgId}>
          <TitleDivider title={spaces.at(0)?.organization?.name || ""} />
          <Grid container spacing={3}>
            {spaces.map((space) => {
              const entry = {
                space,
                id: space.id,
                href: `/space/overview?spaceId=${space.id}`,
              };
              return (
                <Grid item xs={12} sm={6} md={4} key={entry.id}>
                  <SpaceItemCard entry={entry} />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ))}
      <Pagination
        fetchMore={fetchMore}
        pageInfo={pageInfo}
        totalCount={data?.sharedSpaces?.totalCount || 0}
        setPageItems={setPageItems}
        customPageSizes={[12, 24, 48]}
      />
    </Box>
  );
}
