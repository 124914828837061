import { Fragment, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  GetTicketContextDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../../headers/integration-add-header";
import { UpdateFlowData } from "../../../types";
import useGenerateIntegrationName from "../../../utils/useGenerateIntegrationName";
import { GitlabIssueFormInput } from "./types";
import { Command } from "~/components/guides/components";
import {
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { ChevronRightIcon } from "~/components/icons";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";
import { motion } from "framer-motion";
import { SubField } from "~/components/Form/components";
import { Flex } from "~/components/Flex";
import { PasswordField } from "~/components/Form/components/PasswordField";

const defaultValues: GitlabIssueFormInput = {
  integrationName: "",
  baseUrl: "",
  token: "",
  defaultProject: "",
  autoCloseTickets: false,
  autoCreateCases: false,
};

export function GitlabIssuesIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  const [searchParams] = useSearchParams();
  const redirectToParam = searchParams.get("redirectTo");
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const defaultIntegrationName = useGenerateIntegrationName({ space });
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [watchAutoCreateOnDrift] = watch(["autoCreateCases"]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      GetTicketContextDocument,
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      const configOptions = updateFlow.integration.configurationOptions;
      if (configOptions?.__typename !== "GitlabTicketingConfigurationOptions")
        return;

      reset({
        integrationName: updateFlow.integration.name,
        baseUrl: configOptions.gitlabBaseUrl || "",
        defaultProject: configOptions.gitlabDefaultProject || "",
        autoCloseTickets: configOptions.autoCloseTickets,
        autoCreateCases: configOptions.autoCreateTickets,
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<GitlabIssueFormInput> = async (data) => {
    const gitlabTicketingConfigurationOptions = {
      baseUrl: data.baseUrl.trim(),
      token: data.token?.trim?.() || "",
      defaultProject: data.defaultProject,
      autoCloseTickets: data.autoCloseTickets,
      autoCreateTickets: data.autoCreateCases,
    };

    const integrationName =
      data.integrationName?.trim() || updateFlow?.integration.name;

    try {
      if (updateFlow) {
        await updateIntegration({
          variables: {
            input: {
              name: integrationName,
              mrn: `//integration.api.mondoo.app/spaces/${
                space.id
              }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
              type: ClientIntegrationType.TicketSystemGitlab,
              configurationOptions: {
                gitlabTicketingConfigurationOptions,
              },
            },
          },
        });
        const integrationId = updateFlow?.integration.mrn.split("/").pop();
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });

        navigate(
          `/space/integrations/ticket_system_gitlab/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.TicketSystemGitlab,
              longLivedToken: false,
              configurationOptions: {
                gitlabTicketingConfigurationOptions,
              },
            },
          },
        });
      }
      if (redirectToParam) {
        navigate(redirectToParam);
      } else {
        navigate(
          `/space/integrations/ticket_system_gitlab?spaceId=${space.id}`,
        );
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  document.title = "GitLab Issues · Integrations Setup · Mondoo";

  return (
    <Fragment>
      <IntegrationAddHeader {...{ type: IntegrationType.TicketSystemGitlab }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <>
            {/* Step 1 */}
            <Box pb={4}>
              <Command
                number={1}
                options={{
                  fontSize: { xs: 16 },
                  dotColor: theme.palette.background.lightest,
                }}
              >
                Choose an integration name
              </Command>

              <Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2, mt: 2 }}
                >
                  Please choose a descriptive name that lets you easily identify
                  your integration.
                </Typography>
                <Controller
                  name="integrationName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      sx={{
                        background: (theme) => theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="Your integration name..."
                      error={Boolean(errors.integrationName)}
                      helperText={
                        Boolean(errors.integrationName) && (
                          <ValidationMessage error={errors.integrationName} />
                        )
                      }
                    />
                  )}
                />
              </Box>
            </Box>

            {/* Step 2 */}
            <Box pb={4}>
              <Command
                number={2}
                options={{
                  fontSize: { xs: 16 },
                  dotColor: theme.palette.background.lightest,
                }}
              >
                Provide your GitLab base URL{" "}
                <Typography
                  component="span"
                  sx={{ color: "text.secondary", fontStyle: "italic" }}
                >
                  (optional)
                </Typography>
              </Command>

              <Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2, mt: 2 }}
                >
                  If you have a GitLab Enterprise account, specify the URL for
                  the account. The default is{" "}
                  <Link
                    href="https://mondoo.com/docs/platform/ticketing/setup-gitlab/"
                    target="_blank"
                    rel="noopener"
                  >
                    gitlab.com
                  </Link>
                  .
                </Typography>
                <Controller
                  name="baseUrl"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="Your GitLab base URL..."
                      error={Boolean(errors.baseUrl)}
                      helperText={
                        Boolean(errors.baseUrl) && (
                          <ValidationMessage error={errors.baseUrl} />
                        )
                      }
                    />
                  )}
                />
              </Box>
            </Box>
            {/*  Step 3 */}
            <Box pb={4}>
              <Command
                number={3}
                options={{
                  fontSize: { xs: 16 },
                  dotColor: theme.palette.background.lightest,
                }}
              >
                Provide your personal access token
              </Command>

              <Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2, mt: 2 }}
                >
                  To learn about creating a personal access token, read the{" "}
                  <Link
                    href="https://mondoo.com/docs/platform/ticketing/setup-gitlab/"
                    target="_blank"
                    rel="noopener"
                  >
                    Mondoo documentation
                  </Link>
                  .
                </Typography>
                <Controller
                  name="token"
                  control={control}
                  rules={{
                    required: !updateFlow,
                  }}
                  render={({ field }) => (
                    <PasswordField
                      {...field}
                      fullWidth
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder={"xxxx-xxxxxxxxxxxx-xxxx"}
                      error={
                        getFieldState("token").isTouched &&
                        Boolean(errors.token)
                      }
                      helperText={
                        Boolean(errors.token) &&
                        getFieldState("token").isTouched && (
                          <ValidationMessage error={errors.token} />
                        )
                      }
                    />
                  )}
                />
              </Box>
            </Box>
            {/* Step 4 */}
            <Box pb={4}>
              <Command
                number={4}
                options={{
                  fontSize: { xs: 16 },
                  dotColor: theme.palette.background.lightest,
                }}
              >
                Configure preferences
              </Command>
              <Box mt={3}>
                <AutoConfigurationSection
                  control={control}
                  showAutoCreateSection={true}
                  autoCreateSubField={
                    <Box
                      component={motion.div}
                      initial={false}
                      transition={{
                        duration: 0.35,
                        staggerChildren: 0.25,
                      }}
                      sx={{ overflow: "hidden" }}
                    >
                      <SubField
                        configuration="Select a drift group and project"
                        caption="Specify the GitLab group and project in which to create an issue for each new drift case."
                        disabled={!watchAutoCreateOnDrift}
                        field={
                          <Flex
                            flex="1 0 auto"
                            sx={{ pl: 2 }}
                            alignItems="flex-end"
                          >
                            <Controller
                              name="defaultProject"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  disabled={!watchAutoCreateOnDrift}
                                  sx={{
                                    background: theme.palette.code.background,
                                    borderRadius: 1,
                                    color: "text.primary",
                                    ...helperTextStyles,
                                  }}
                                  placeholder={"your-group/your-project ..."}
                                  error={
                                    getFieldState("defaultProject").isTouched &&
                                    Boolean(errors.defaultProject)
                                  }
                                  helperText={
                                    Boolean(errors.defaultProject) &&
                                    getFieldState("defaultProject")
                                      .isTouched && (
                                      <ValidationMessage
                                        error={errors.defaultProject}
                                      />
                                    )
                                  }
                                />
                              )}
                            />
                          </Flex>
                        }
                      />
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Box width={1}>
              <Divider />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", mt: 5 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                // setValue doesn't trigger the required validation so we're doing it manually here
                disabled={!isValid}
              >
                {updateFlow ? "update configuration" : "create integration"}
              </Button>
            </Box>
          </>
        </form>
      </Box>
    </Fragment>
  );
}
