import { Box } from "@mui/material";
import { DonutChart } from "~/components/charts/donut-chart";
import { CardSizes, HubCard } from "~/components/hub-card";
import { NavDrawerPolicyIcon } from "~/components/icons";
import { Flex } from "~/components/Flex";
import { SpaceOrWorkspaceScope, useScope } from "~/hooks/useScope";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";

type Props = {
  sizes?: CardSizes;
};

export function PolicyOverviewCard({ sizes }: Props) {
  const { activeScope } = useScope();

  const { policyGrades, isLoading } = useMetrics({
    entityMrn: activeScope?.mrn || "",
    metricMrns: [
      Metrics.PolicyGradeDistribution,
      Metrics.PolicyCategoryDistribution,
      Metrics.SecurityAssetsStats,
      Metrics.ChecksResultDistribution,
    ],
  });

  return (
    <Flex gap={2} flexDirection="row">
      <HubCard
        to={`policies?${activeScope?.params?.toString()}`}
        {...{
          loading: isLoading,
          sizes,
          title: "Policy Overview",
          icon: <NavDrawerPolicyIcon fontSize="small" />,
        }}
        PaperProps={{ height: 264 }}
        data-testid="policy-overview-card"
      >
        <Box sx={{ mt: 2 }}>
          <DonutChart data={policyGrades} dataType="policies" />
        </Box>
      </HubCard>
    </Flex>
  );
}
