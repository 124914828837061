import { Box, TableCell } from "@mui/material";
import { rankFormat } from "~/lib/number-k-formatting";

export function RankCell({ id }: { id: number | string }) {
  let rank = id;
  if (typeof id === "number") {
    rank = rankFormat(id);
  }

  return (
    <TableCell>
      <RankCircle id={rank} />
    </TableCell>
  );
}

export function RankCircle({ id }: { id: number | string }) {
  let rank = id;
  if (typeof id === "number") {
    rank = rankFormat(id);
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: (theme) => theme.spacing(3),
        height: (theme) => theme.spacing(3),
        borderRadius: "50%",
        backgroundColor: "background.lightest",
        fontSize: 12,
        fontWeight: 700,
        textTransform: "lowercase",
      }}
    >
      {rank}
    </Box>
  );
}
