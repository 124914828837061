import {
  alpha,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Asset, CheckFindingsCheckNode } from "~/pages/inventory/asset/types";
import { createPath, parsePath, useNavigate } from "react-router-dom";
import { Loading, LoadingFailed } from "~/components/loading";
import { DashboardCard } from "~/pages/organization/dashboard/dashboard-card";
import { MondooIcon } from "~/components/icons/mondoo/mondoo-primary";
import {
  FindingsOrderField,
  FindingType,
  OrderDirection,
  ScoreRating,
  ScoreStateFilter,
  useGetChecksQuery,
} from "~/operations";
import { ImpactUpdated } from "~/components/impact/Updated/impact-updated";

type Props = {
  asset: Asset;
  assetPath: string | null;
};

export function AssetRecommendedActions({ assetPath, asset }: Props) {
  const navigate = useNavigate();

  const { data, error, loading } = useGetChecksQuery({
    variables: {
      scopeMrn: asset.mrn,
      first: 5,
      filter: {
        types: [FindingType.Check],
        state: ScoreStateFilter.Open,
      },
      orderBy: {
        field: FindingsOrderField.RiskScore,
        direction: OrderDirection.Asc,
      },
    },
  });

  if (loading || !data || !assetPath) {
    return <Loading what="Recommended Actions" />;
  }

  if (error) {
    return <LoadingFailed what="Recommended Actions" />;
  }

  const findingsUnion =
    data?.findings?.__typename === "FindingsConnection"
      ? data.findings
      : undefined;
  const checkEdges = findingsUnion?.edges || [];
  const recommendedActions: CheckFindingsCheckNode[] = checkEdges.flatMap(
    (e) => (e?.node?.__typename === "CheckFinding" ? e.node : []),
  );

  const totalCount = findingsUnion?.totalCount || 0;
  const hasRecommendedActions = totalCount > 0;

  const handleClick = (mrn: string) => {
    const parsedPath = parsePath(assetPath);
    const pathname = `${parsedPath.pathname}/checks`;
    const search = `${parsedPath.search}&queryTerms=${encodeURIComponent(mrn)}`;
    return navigate(createPath({ pathname, search }));
  };

  return (
    <DashboardCard
      title="RECOMMENDED ACTIONS"
      icon={<MondooIcon />}
      sx={{ height: 1 }}
    >
      <TableContainer
        sx={[
          {
            "td, th": {
              fontSize: 12,
              borderBottomColor: "transparent",
            },
          },
        ]}
      >
        <Table size="small" id="recommended-actions-table">
          <TableHead
            sx={[
              { backgroundColor: "transparent", boxShadow: "none" },
              {
                "tr:first-of-type th": {
                  borderBottom: "none",
                },
              },
              {
                "tr:last-child th": {
                  borderBottom: "none",
                },
              },
            ]}
          >
            <TableRow>
              <TableCell
                sx={{
                  pl: 0,
                  fontWeight: 700,
                  textTransform: "uppercase",
                  pointerEvents: "none",
                }}
                colSpan={3}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recommendedActions?.map((action) => {
              const key = action?.id || action?.mrn;

              return (
                <TableRow
                  key={key}
                  sx={[
                    { td: { px: 0.5 } },
                    {
                      "&:hover": {
                        cursor: "pointer",
                      },
                    },
                  ]}
                  onClick={() => handleClick(action?.mrn || "")}
                >
                  <TableCell sx={{ p: 0, width: 80 }}>
                    <ImpactUpdated
                      showText={false}
                      isRiskScore
                      impact={{
                        rating: action?.rating || ScoreRating.None,
                        value: action?.riskScore || 0,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      maxWidth: "200px",
                    }}
                  >
                    <Tooltip
                      title={
                        <Box sx={{ textAlign: "center" }}>
                          CHECK: {action?.title}
                        </Box>
                      }
                      placement="top-start"
                      arrow
                    >
                      <Box>{action?.title}</Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {!hasRecommendedActions && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            color: (theme) => alpha(theme.palette.text.primary, 0.33),
            minHeight: 148,
            borderRadius: 1,
          }}
        >
          <Typography
            sx={{
              width: 1,
              maxWidth: 500,
              mt: 1,
              textAlign: "center",
              fontWeight: 700,
            }}
          >
            Add more policies for this space in order to get a list of
            prioritized recommendations here
          </Typography>
        </Box>
      )}
    </DashboardCard>
  );
}
