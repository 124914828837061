import { useTheme } from "@mui/material";
import { RiskFactorsTitle } from "./risk-factors-title";

export function useRiskFactorsContentStatus() {
  const theme = useTheme();
  const positiveRiskFactors = ["defensive", "asset-in-use"]; // positive risk factors should show up green

  const getRiskStatus = (
    affected = 0,
    total = 0,
    indicator = "",
  ): { status: "active" | "inactive"; color: string } => {
    const isPositive = positiveRiskFactors.includes(indicator);
    const isAffected = affected > 0;
    const isAllAffected = affected === total;
    const isSuccess = isPositive ? isAllAffected : !isAffected;
    const status = isAffected ? "active" : "inactive";
    const color = isSuccess ? "success" : "critical";
    return { status, color: theme.palette[color].main };
  };

  const createRiskFactorSectionTitle = (
    riskFactors: RiskFactorsTitle["riskFactors"],
  ) => {
    return (
      riskFactors
        ?.map((rf) => `${rf?.affected}/${rf?.total} ${rf?.title}`)
        .join(", ") || ""
    );
  };

  return {
    getRiskStatus,
    createRiskFactorSectionTitle,
  };
}
