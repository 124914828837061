import {
  ConfigurationList,
  ConfigurationPaper,
} from "~/components/configuration-items";
import { LoadingPage } from "~/components/loading";
import { RiskFactorAction, useGetSpaceRiskFactorsQuery } from "~/operations";
import { useSpaceSettingsOutletContext } from "../../space-settings";
import {
  fieldId,
  formatMagnitude,
  RiskFactorsConfigurationForm,
  RiskFactorsConfigurationInput,
} from "./RiskFactorsConfigurationForm";

export function RiskFactorsConfiguration() {
  const { space } = useSpaceSettingsOutletContext();
  const spaceMrn = space.mrn;

  const { data, loading } = useGetSpaceRiskFactorsQuery({
    variables: { spaceMrn },
    fetchPolicy: "network-only",
  });

  const allRiskFactors = data?.riskFactors.edges || [];

  // Filter out "//policy.api.mondoo.app/spaces/:spaceId/risks/low-priority"
  // and "//policy.api.mondoo.app/spaces/:spaceId/risks/high-priority"
  // until we have the form updated to handle them.
  const riskFactors = allRiskFactors.filter(
    (rf) => !rf.mrn.endsWith("-priority"),
  );

  const defaultValues =
    riskFactors.reduce<RiskFactorsConfigurationInput>(
      (acc, { mrn, action, magnitude }) => {
        const { value, isToxic } = magnitude;
        const rawMagnitude = formatMagnitude(value);
        acc[fieldId(mrn)] = {
          enabled: action === RiskFactorAction.Enable,
          magnitude: value,
          rawMagnitude,
          isToxic,
        };
        return acc;
      },
      {},
    ) || {};

  return (
    <ConfigurationPaper>
      <ConfigurationList title="Risk configuration">
        {loading && <LoadingPage what="Risk Factors" />}
        {!loading && riskFactors.length > 0 && (
          <RiskFactorsConfigurationForm
            riskFactors={riskFactors}
            defaultValues={defaultValues}
          />
        )}
      </ConfigurationList>
    </ConfigurationPaper>
  );
}
