import { SmallDonutCard, SmallDonutData } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { CVEIcon } from "~/components/icons";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";

export function CvesDonutChart({ scope }: DonutChartCardProps) {
  const { cvesSeverity, isLoading } = useMetrics({
    entityMrn: scope?.mrn || "",
    metricMrns: [Metrics.CVEsSeverity],
  });

  return (
    <SmallDonutCard
      to={`cves?${scope?.params?.toString()}`}
      loading={isLoading}
      data={cvesSeverity}
      data-testid="cves-donut-chart"
      title="CVES"
      icon={<CVEIcon />}
    />
  );
}
