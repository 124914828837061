import { useEffect, useState } from "react";
import { Space } from "~/lib/types";
import { NetworkStatus } from "@apollo/client";
import {
  AggregateScoreOrder,
  AggregateScoreOrderField,
  AggregateScoreType,
  OrderDirection,
  useGetAggregateScoresQuery,
} from "~/operations";
import { FirewatchLayout } from "../FirewatchLayout";
import { FirewatchList } from "../FirewatchList";
import { INITIAL_PAGE_RANGE } from "../pagination";
import { useSearch } from "../search/useSearch";
import { pageNameByType } from "./lib";
import { LoadingPage } from "../loading";
import { useSort } from "~/pages/inventory/hooks/useSort";
import { useFirewatchSearchParams } from "./hooks/UseFirewatchSearchParams";
import { useFirewatchPage } from "./hooks/useFirewatchPage";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { isFeatureEnabled } from "~/login/features";

type FirewatchPageProps = {
  space: Space;
  scope: SpaceOrWorkspaceScope | undefined;
  pageType: AggregateScoreType;
};

export function FirewatchPage({ space, scope, pageType }: FirewatchPageProps) {
  const { searchFilters, handleFilterQuery } = useSearch();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const { risksAnd, selectedRiskFactor } = useFirewatchSearchParams();
  const { maxRiskScore, findingMrn, documentType } = useFirewatchPage({
    pageType,
  });

  const { handleSortClick, orderBy } = useSort<AggregateScoreOrder>({
    defaultSort: {
      field: AggregateScoreOrderField.Rank,
      direction: OrderDirection.Asc,
    },
    validFields: ["RISK_SCORE", "RANK", "BLAST_RADIUS", "TITLE"],
  });

  // when the risk grouping changes, we need to reset the page items
  useEffect(() => {
    setPageItems(INITIAL_PAGE_RANGE);
  }, [selectedRiskFactor, pageType]);

  const { data, error, loading, fetchMore, networkStatus } =
    useGetAggregateScoresQuery({
      variables: {
        entityMrn: scope?.mrn || space.mrn,
        first: 10,
        after: null,
        orderBy,
        filter: {
          scoreType: pageType,
          queryTerms: searchFilters,
          maxRiskScore,
          findingMrn,
          risks: {
            indicators: [],
            mrns: {
              and: risksAnd ?? [],
              or: [],
            },
          },
        },
      },
      fetchPolicy: "network-only",
    });

  if (error) {
    return <div>Error loading risk scores</div>;
  }
  if (loading && networkStatus === NetworkStatus.loading)
    return <LoadingPage what={pageNameByType(pageType)} />;

  const aggregateScores =
    data?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? data.aggregateScores
      : undefined;

  const pageInfo = aggregateScores?.pageInfo;
  const totalCount = aggregateScores?.totalCount || 0;
  const edges = aggregateScores?.edges || [];
  const noTotal = data && Boolean(totalCount === 0);
  const noEntriesWithFilters = noTotal && Boolean(edges?.length === 0);
  const noEntries = noEntriesWithFilters
    ? "No entries found with the current filter selection."
    : noTotal
      ? "No entries found"
      : undefined;

  return (
    <FirewatchLayout
      searchProps={{
        searchId: `space-vulns-${pageType.toLowerCase()}`,
        placeholder: `space_vulns_${pageType.toLowerCase()}`,
        searchFilters,
        handleFilterQuery,
      }}
      pageProps={{
        space,
        scope,
        pageType,
        noEntries,
        ...(isFeatureEnabled("Reporting") && documentType && { documentType }),
        views: {
          list: (
            <FirewatchList
              space={space}
              scope={scope}
              pageType={pageType}
              loading={
                (loading && !data) ||
                networkStatus === NetworkStatus.fetchMore ||
                networkStatus === NetworkStatus.setVariables
              }
              data={edges?.slice(pageItems.from, pageItems.to)}
              field={orderBy.field}
              direction={orderBy.direction}
              handleSortClick={handleSortClick}
              paginationProps={{
                fetchMore,
                pageInfo,
                setPageItems,
                totalCount,
              }}
            />
          ),
        },
      }}
    />
  );
}
