import { AggregateScoreType } from "~/operations";
import { AdjustedAggregateScoreType } from ".";
import { SxProps } from "@mui/material";

export type Header = {
  /**
   * IDs are used to identify the column in the table
   */
  id: string;
  /**
   * Field is used to identify the column for filtering and sorting
   */
  field: string;
  /**
   * Label is the text that will be displayed in the table header
   */
  label: React.ReactNode;
  /**
   * ColSpan is used to merge columns
   * @default 1
   */
  colSpan?: number;
  /**
   * Sortable is used to determine if the column is sortable
   */
  sortable?: boolean;
  /**
   * Options is used to set the text alignment and width of the column
   */
  // TODO: roll all options into SXProps
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
    colSpan?: number;
    sx?: SxProps;
  };
};

type FirewatchColumnProps = {
  [key: string]: Header;
};

export function getFirewatchColumns(
  pageType: AdjustedAggregateScoreType,
  component: string = "list",
  isSearchResults?: boolean,
  isOrg?: boolean,
): Header[] {
  const FIREWATCH_COLUMNS: FirewatchColumnProps = {
    Rank: {
      id: "RANK",
      field: "RANK",
      label: "Rank",
      sortable: true,
      options: { width: 50 },
    },
    // Index is a copy of rank that will use the index of a list instead of true rank
    // it is meant for joined lists
    Index: {
      id: "INDEX",
      field: "RANK",
      label: "Rank",
      sortable: true,
      options: { width: 50 },
    },
    Impact: {
      id: "IMPACT",
      field: "RISK_SCORE", // Risk score is the appropriate field for impact component for sorting purposes
      label: "Risk",
      sortable: true,
      options: { width: component === "small" ? 75 : 130 },
    },
    BlastRadius: {
      id: "BLAST_RADIUS",
      field: "BLAST_RADIUS",
      label: "Blast radius",
      sortable: true,
      options: { width: component === "small" ? 100 : 140, sx: { pr: 0.5 } },
    },
    Vulnerability: {
      id: "VULNERABILITY",
      field: "TITLE",
      label: "Vulnerability",
      sortable: true,
    },
    Asset: {
      id: "ASSET",
      field: "TITLE",
      label: "Asset",
      sortable: true,
    },
    Check: { id: "CHECK", field: "TITLE", label: "Check", sortable: true },
    Control: {
      id: "CONTROL",
      field: "CONTROL",
      label: "Control",
      sortable: true,
    },
    Framework: {
      id: "FRAMEWORK",
      field: "FRAMEWORK",
      label: "Framework",
      sortable: true,
    },
    Advisory: {
      id: "ADVISORY",
      field: "TITLE",
      label: "Advisory",
      sortable: true,
    },
    AdvisoryCount: {
      id: "ADVISORY_COUNT",
      field: "ADVISORY_COUNT",
      label: "Advisories",
      sortable: false,
      options: { width: 100 },
    },
    CveCount: {
      id: "CVE_COUNT",
      field: "CVE_COUNT",
      label: "CVEs",
      sortable: false,
      options: { width: 100 },
    },
    Policy: {
      id: "POLICY",
      field: "POLICY",
      label: "Policy name",
      sortable: component === "small" ? false : true,
    },
    Software: {
      id: "SOFTWARE",
      field: "TITLE",
      label: "Software",
      sortable: true,
    },
    RiskFactors: {
      id: "RISK_FACTORS",
      field: "RISK_FACTORS",
      label: "Risk factors",
      sortable: false,
      options: { width: 180 },
    },
    RiskScore: {
      id: "RISK_SCORE",
      field: "RISK_SCORE",
      label: "Risk",
      sortable: true,
      options: { width: 75 },
    },
    FirstFound: {
      id: "FIRST_FOUND",
      field: "FIRST_FOUND",
      label: "First found",
      sortable: false,
      options: { width: 180 },
    },
    Space: {
      id: "SPACE",
      field: "SPACE",
      label: "Space",
      sortable: false,
    },
    Date: {
      id: "DATE",
      field: "FIRST_FOUND",
      label: "Date",
      sortable: false,
      options: { width: component === "small" ? 90 : 100 },
    },
    Title: {
      id: "TITLE",
      field: "TITLE",
      label: "Name",
      sortable: false,
    },
    LastUpdated: {
      id: "LAST_UPDATED",
      field: "LAST_UPDATED",
      label: "Last updated",
      sortable: false,
      options: { width: 180 },
    },
    AssetsCount: {
      id: "ASSETS_COUNT",
      field: "ASSETS_COUNT",
      label: "Assets",
      sortable: false,
      options: { width: 100 },
    },
    Type: {
      id: "TYPE",
      field: "TYPE",
      label: "Type",
      sortable: false,
    },
  };

  const cveColumns: Header[] = [
    ...(isSearchResults ? [] : [FIREWATCH_COLUMNS.Rank]),
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.BlastRadius,
    FIREWATCH_COLUMNS.Vulnerability,
    ...(component === "list"
      ? [FIREWATCH_COLUMNS.RiskFactors, FIREWATCH_COLUMNS.FirstFound]
      : []),
  ].flatMap((x) => x ?? []);

  const advisoryColumns: Header[] = [
    ...(isSearchResults ? [] : [FIREWATCH_COLUMNS.Rank]),
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.BlastRadius,
    FIREWATCH_COLUMNS.Advisory,
    FIREWATCH_COLUMNS.RiskFactors,
    FIREWATCH_COLUMNS.FirstFound,
  ].flatMap((x) => x ?? []);

  const affectedAssetsColumns: Header[] = [
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.Asset,
    FIREWATCH_COLUMNS.RiskFactors,
    FIREWATCH_COLUMNS.LastUpdated,
  ].flatMap((x) => x ?? []);

  const checksColumns: Header[] = [
    ...(isSearchResults ? [] : [FIREWATCH_COLUMNS.Rank]),
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.BlastRadius,
    FIREWATCH_COLUMNS.Check,
    ...(component === "list" ? [FIREWATCH_COLUMNS.RiskFactors] : []),
  ].flatMap((x) => x ?? []);

  const topSecurityFindingsColumns: Header[] = [
    FIREWATCH_COLUMNS.Index,
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.BlastRadius,
    FIREWATCH_COLUMNS.Check,
  ].flatMap((x) => x ?? []);

  const controlsColumns: Header[] = [
    ...(isSearchResults ? [] : [FIREWATCH_COLUMNS.Rank]),
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.BlastRadius,
    FIREWATCH_COLUMNS.Control,
    FIREWATCH_COLUMNS.RiskFactors,
  ].flatMap((x) => x ?? []);

  const frameworksColumns: Header[] = [
    ...(isSearchResults ? [] : [FIREWATCH_COLUMNS.Rank]),
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.BlastRadius,
    FIREWATCH_COLUMNS.Framework,
    FIREWATCH_COLUMNS.RiskFactors,
  ].flatMap((x) => x ?? []);

  const policyColumns: Header[] = [
    ...(isSearchResults ? [] : [FIREWATCH_COLUMNS.Rank]),
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.BlastRadius,
    FIREWATCH_COLUMNS.Policy,
  ].flatMap((x) => x ?? []);

  const softwareColumns: Header[] = [
    ...(isSearchResults ? [] : [FIREWATCH_COLUMNS.Rank]),
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.BlastRadius,
    FIREWATCH_COLUMNS.Software,
    FIREWATCH_COLUMNS.RiskFactors,
  ];

  const versionedSoftwareColumns: Header[] = [
    FIREWATCH_COLUMNS.Rank,
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.BlastRadius,
    FIREWATCH_COLUMNS.Software,
    FIREWATCH_COLUMNS.AssetsCount,
  ];

  const newestRelevantVulnerabilitiesColumns: Header[] = [
    FIREWATCH_COLUMNS.Date,
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.Vulnerability,
  ].flatMap((x) => x ?? []);

  const searchAllColumns: Header[] = [
    FIREWATCH_COLUMNS.Impact,
    FIREWATCH_COLUMNS.Type,
    FIREWATCH_COLUMNS.Title,
    ...(isOrg ? [FIREWATCH_COLUMNS.Space] : []),
    FIREWATCH_COLUMNS.RiskFactors,
    FIREWATCH_COLUMNS.FirstFound,
  ].flatMap((x) => x ?? []);

  switch (pageType) {
    case "ALL":
      return searchAllColumns;
    case "NewestRelevantVulnerabilities":
      return newestRelevantVulnerabilitiesColumns;
    case "TopSecurityFindings":
      return topSecurityFindingsColumns;
    case AggregateScoreType.Advisory:
      return advisoryColumns;
    case AggregateScoreType.Asset:
      return affectedAssetsColumns;
    case AggregateScoreType.Check:
      return checksColumns;
    case AggregateScoreType.Control:
      return controlsColumns;
    case AggregateScoreType.Framework:
      return frameworksColumns;
    case AggregateScoreType.Vulnerability:
      return cveColumns;
    case AggregateScoreType.Policy:
      return policyColumns;
    case AggregateScoreType.Software:
      return softwareColumns;
    case AggregateScoreType.VersionedSoftware:
      return versionedSoftwareColumns;
    default:
      return [];
  }
}
