import { Grid2, TableRow } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import {
  ImpactCell,
  TitleCell,
  RiskFactorsCell,
  PublishedCell,
  RankCell,
  BlastRadiusCell,
} from "~/components/Cells";
import { GenericTable } from "~/components/GenericTable/GenericTable";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { ScoreRating } from "~/operations";
import { SectionHeading } from "../../components";
import { NoItemsSection } from "../components";
import { spacing } from "../lib/SharedVariables";
import { useChecksOrVulnerabilitiesReport } from "./hooks/useChecksOrVulnerabilities";
import { ReportType } from "~/reportApp/reportingApp";
import { BlastRadius } from "~/components/blast/blast-radius";

export function ChecksTables() {
  const { failingChecks, passingChecks } = useChecksOrVulnerabilitiesReport({
    reportType: ReportType.Checks,
  });

  return (
    <Fragment>
      <Grid2 size={12} sx={{ mb: spacing[50] }}>
        {[failingChecks, passingChecks].map((group) => {
          if (!group) return null;
          return (
            <Fragment>
              <SectionHeading
                heading={group.heading}
                dividerContent={`${group.total} Total`}
              />
              <Grid2 size={12} sx={{ my: 3 }}>
                {false ? (
                  <NoItemsSection target="Checks" />
                ) : (
                  <GenericTable
                    items={0}
                    tableName="CHECKS_REPORT_CHECKS"
                    validSortFields={[]}
                    printView
                  >
                    {group.items?.map((check, index) => {
                      const {
                        id,
                        riskScore,
                        rating,
                        title,
                        tags,
                        riskFactors,
                        blastRadius,
                        lastScannedAt,
                      } = check.node || {};

                      return (
                        <TableRow key={id}>
                          <BlastRadiusCell
                            blastRadius={blastRadius}
                            impact={riskScore || 0}
                            rating={rating || ScoreRating.None}
                            isActive={rating == null ? false : true}
                          />
                          <ImpactCell
                            impact={riskScore || 0}
                            rating={rating || ScoreRating.Critical}
                            isActive={rating == null ? false : true}
                            hideTooltip
                          />

                          <TitleCell
                            title={title || ""}
                            tags={tags}
                            options={{ fontSize: 14 }}
                          />
                          <RiskFactorsCell
                            riskFactors={riskFactors}
                            printView
                          />
                          <PublishedCell date={lastScannedAt || "Never"} />
                        </TableRow>
                      );
                    })}
                  </GenericTable>
                )}
              </Grid2>
            </Fragment>
          );
        })}
      </Grid2>
    </Fragment>
  );
}
