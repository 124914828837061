import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Space } from "~/lib/types";
import { HomeIcon } from "~/components/icons";
import {
  TestIamActionsQuery,
  useGetActivePoliciesQuery,
  DocumentType,
} from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import { usePolicies } from "./hooks/usePolicies";
import { LayoutSwitch } from "~/components/LayoutSwitch/LayoutSwitch";
import { List } from "./list";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { AddButton } from "~/components/add-button";
import { NoSearchResults } from "~/pages/Search";
import { ScopeType, SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { isFeatureEnabled } from "~/login/features";
import { ExportButton } from "~/pages/compliance/components/export-framework";

type Props = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Policies({ space, scope, availablePermissions }: Props) {
  const { permissions, state, handle, orderBy, searchFilters } = usePolicies({
    availablePermissions,
    space,
  });

  const { data, error, loading, fetchMore, networkStatus } =
    useGetActivePoliciesQuery({
      variables: {
        input: {
          query: searchFilters.join(" "),
          scopeMrn: scope.mrn,
          orderBy,
        },
      },
      skip: !scope.mrn,
      fetchPolicy: "cache-and-network",
    });

  // show a loading spinner on initial load only
  if (networkStatus === 1) {
    return <Loading what="enabled policies" />;
  }

  // if fetching data fails, we'll show a failure
  if (error) {
    return <LoadingFailed what="enabled policies" />;
  }

  // Keep one copy of the original data and copy the data returned into a new array
  const activePolicies = data?.activePolicies?.edges || [];
  const totalPolicies = data?.activePolicies?.totalCount || 0;
  const pageInfo = data?.activePolicies?.pageInfo || undefined;
  const hasPolicies = totalPolicies > 0;
  const isFiltering = searchFilters.length > 0;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?${scope.params}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key={2}>Policies</Typography>,
  ];

  document.title = "Policies · Mondoo";

  return (
    <Fragment>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        id="security-policies-header"
        mb={6}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" fontWeight={700} textTransform="uppercase">
          Policies
        </Typography>
        <Stack direction="row">
          {isFeatureEnabled("Reporting") && (
            <Fragment>
              <ExportButton
                documentType={DocumentType.ChecksReport}
                title={"Generated Checks Report"}
                space={space}
              />
              <Divider orientation="vertical" flexItem sx={{ ml: 2, mr: 3 }} />
            </Fragment>
          )}
          {scope.type === ScopeType.Space && permissions.assign && (
            <AddButton
              id="policy"
              href={`/space/findings/policies/add?${scope.params}`}
              aria-label="Add Policy"
            />
          )}
        </Stack>
      </Box>

      <Grid container spacing={3}>
        <LayoutSwitch
          filterBar={
            hasPolicies || isFiltering ? (
              <FilterBar
                searchId="security-policies"
                placeholder="security_policies"
                searchFilters={searchFilters}
                handleFilterQuery={handle.filterQuery}
                mb={0}
              />
            ) : undefined
          }
          views={{
            list: (
              <Box>
                {loading ? (
                  <Loading what="enabled policies" />
                ) : isFiltering && !hasPolicies ? (
                  <NoSearchResults
                    searchQuery={searchFilters.join(" + ")}
                    name={space.name}
                  />
                ) : (
                  <List
                    {...{
                      space,
                      scope,
                      handle,
                      state,
                      orderBy,
                      handleCheckAll: handle.checkAll,
                      permissions,
                      fetchMore,
                      totalPolicies,
                      activePolicies: activePolicies.slice(
                        state.pageItems.from,
                        state.pageItems.to,
                      ),
                      selectedItems: state.selectedItems,
                      PaginationProps: {
                        fetchMore,
                        setPageItems: state.setPageItems,
                        pageInfo,
                        totalCount: totalPolicies,
                      },
                    }}
                  />
                )}
              </Box>
            ),
          }}
        />
      </Grid>
    </Fragment>
  );
}
