import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid2,
  InputAdornment,
  Link,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Fragment, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  GetSpaceRiskFactorsDocument,
  GetSpaceRiskFactorsQuery,
  RiskFactorAction,
  useModifySpaceRiskFactorsMutation,
} from "~/operations";
import { useSpaceSettingsOutletContext } from "../../space-settings";
import { RiskFactorsActionRow } from "./RiskFactorsActionRow";

export const formatMagnitude = new Intl.NumberFormat("en-US", {
  signDisplay: "exceptZero",
}).format;

export const idFromMrn = (mrn: string) => mrn.split("/").pop() || "";

export const fieldId = (mrn: string) => {
  const id = idFromMrn(mrn);
  return id.replaceAll(".", "");
};

const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const min = -100;
const max = 100;
const marks = range(min, max, 20).map((value) => ({ label: "", value }));

export type RiskFactorItemInput = {
  enabled: boolean;
  magnitude: number;
  rawMagnitude: string;
  isToxic: boolean;
};

export type RiskFactorsConfigurationInput = {
  [id: string]: RiskFactorItemInput;
};

export type RiskFactorItems = NonNullable<
  GetSpaceRiskFactorsQuery["riskFactors"]["edges"]
>;

export type RiskFactorsConfigurationFormProps = {
  riskFactors: RiskFactorItems;
  defaultValues: RiskFactorsConfigurationInput;
};

export function RiskFactorsConfigurationForm({
  riskFactors,
  defaultValues,
}: RiskFactorsConfigurationFormProps) {
  const { space } = useSpaceSettingsOutletContext();
  const spaceMrn = space.mrn;
  const { enqueueSnackbar } = useSnackbar();
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [modifySpaceRiskFactors] = useModifySpaceRiskFactorsMutation({
    refetchQueries: [GetSpaceRiskFactorsDocument],
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getFieldState,
    getValues,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = useForm<RiskFactorsConfigurationInput>({
    mode: "onBlur",
    defaultValues,
  });

  // Disable "Reset to Defaults" button if every field matches the Mondoo defaults
  const isDefaults = riskFactors.every((rf) => {
    const { mrn } = rf;
    const { magnitude, isToxic, enabled } = getValues(`${fieldId(mrn)}`);
    const defaultEnabled = true;
    const defaultMagnitude = rf.defaultMagnitude.value;
    const defaultIsToxic = rf.defaultMagnitude.isToxic;
    return (
      enabled === defaultEnabled &&
      magnitude === defaultMagnitude &&
      isToxic === defaultIsToxic
    );
  });

  const onSubmit: SubmitHandler<RiskFactorsConfigurationInput> = async () => {
    try {
      const riskFactors = Object.entries(dirtyFields).map(([id, field]) => {
        const action = field?.enabled
          ? getValues(`${id}.enabled`)
            ? RiskFactorAction.Enable
            : RiskFactorAction.Disable
          : undefined;
        const value = field?.magnitude
          ? getValues(`${id}.magnitude`)
          : undefined;
        const isToxic = field?.isToxic ? getValues(`${id}.isToxic`) : undefined;
        const magnitude =
          value !== undefined || isToxic !== undefined
            ? {
                value: getValues(`${id}.magnitude`),
                isToxic: getValues(`${id}.isToxic`),
              }
            : undefined;
        return {
          mrn: "//policy.api.mondoo.app/risks/" + id,
          ...(action !== undefined && { action }),
          ...(magnitude !== undefined && { magnitude }),
        };
      });
      await modifySpaceRiskFactors({ variables: { spaceMrn, riskFactors } });
      reset(getValues());
      enqueueSnackbar("Successfully updated configuration", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Failed to update configuration", { variant: "error" });
    }
  };

  const onCancel = () => {
    reset();
  };

  const onResetToDefault = () => {
    riskFactors.forEach((rf) => {
      const { mrn } = rf;
      const { magnitude, isToxic, enabled } = getValues(`${fieldId(mrn)}`);
      const defaultEnabled = true;
      const defaultMagnitude = rf.defaultMagnitude.value;
      const defaultIsToxic = rf.defaultMagnitude.isToxic;
      if (enabled !== defaultEnabled) {
        setValue(`${fieldId(mrn)}.enabled`, defaultEnabled, {
          shouldDirty: true,
        });
      }
      if (magnitude !== defaultMagnitude) {
        setValue(`${fieldId(mrn)}.magnitude`, defaultMagnitude, {
          shouldDirty: true,
        });
        setValue(
          `${fieldId(mrn)}.rawMagnitude`,
          formatMagnitude(defaultMagnitude),
          { shouldDirty: true },
        );
      }
      if (isToxic !== defaultIsToxic) {
        setValue(`${fieldId(mrn)}.isToxic`, defaultIsToxic, {
          shouldDirty: true,
        });
      }
    });
  };

  return (
    <Box className="risk-factors-config" sx={{ p: 2 }}>
      <Grid2 container spacing={3} className="risk-factors-config-header">
        <Grid2 size="grow" className="risk-factors-config-description">
          <Typography>
            Mondoo considers multiple risk factors when evaluating the security
            of your assets. To customize per your business's security
            priorities, you can choose which risk factors affect your assets'
            scores. For even finer control, select SHOW ADVANCED SETTINGS. To
            learn more, read the{" "}
            <Link
              href={"https://mondoo.com/docs/platform/security/customize/risk/"}
              target="_blank"
              rel="noopener"
            >
              Mondoo documentation
            </Link>
            .
          </Typography>
        </Grid2>
        <Grid2 size="auto" className="risk-factors-config-actions">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowAdvanced(!showAdvanced)}
          >
            {showAdvanced ? "Hide" : "Show"} Advanced Settings
          </Button>
        </Grid2>
      </Grid2>
      <Box className="risk-factors-config-content">
        <Box
          className="risk-factors-config-form"
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          {(showAdvanced || isDirty) && (
            <RiskFactorsActionRow
              {...{
                isDefaults,
                isValid,
                isDirty,
                onCancel,
                ...(showAdvanced && { onResetToDefault }),
              }}
            />
          )}
          <Divider sx={{ py: 1 }} />
          <Box className="risk-factors-config-form-content" sx={{ pt: 2 }}>
            {/* When its time to group the risk factors, use the <TitleAccordion /> Component */}
            {riskFactors.map((rf) => (
              <Box
                key={rf.mrn}
                className="risk-factor-config-item"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: 2,
                  mb: 3,
                  borderLeft: "2px solid",
                  borderColor: getFieldState(fieldId(rf.mrn)).isDirty
                    ? "primary.main"
                    : "transparent",
                }}
              >
                <Box
                  className="risk-factor-config-item-toggle"
                  sx={{ display: "flex" }}
                >
                  <Controller
                    name={`${fieldId(rf.mrn)}.enabled`}
                    control={control}
                    render={({ field }) => (
                      <Switch checked={field.value} {...field} />
                    )}
                  />
                </Box>
                <Box
                  className="risk-factor-config-item-info"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5,
                  }}
                >
                  <Box
                    className="risk-factor-config-item-header"
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    {/* TODO: Risk Factor indicator icons */}
                    {/* <InternetExposed /> */}
                    <Typography
                      fontWeight={700}
                      color={
                        getValues(`${fieldId(rf.mrn)}.enabled`)
                          ? "text.primary"
                          : "text.secondary"
                      }
                    >
                      {rf.title}
                    </Typography>
                    {/* TODO: Risk Factor asset stats */}
                    {/* <Typography
                      fontWeight={700}
                      fontSize={14}
                      color="text.secondary"
                    >
                      1109 Assets
                    </Typography> */}
                  </Box>
                  <Box
                    className="risk-factor-config-item-description"
                    sx={{ flex: 1 }}
                  >
                    <Typography color="text.secondary" fontSize={14}>
                      {getValues(`${fieldId(rf.mrn)}.enabled`)
                        ? rf.docs?.active
                        : rf.docs?.inactive}
                    </Typography>
                  </Box>
                </Box>
                {showAdvanced && (
                  <Fragment>
                    {getValues(`${fieldId(rf.mrn)}.enabled`) && (
                      <Box
                        className="risk-factor-config-item-adjuster"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          ml: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <Typography
                            fontSize={14}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setValue(`${fieldId(rf.mrn)}.magnitude`, min, {
                                shouldDirty: true,
                              });
                              setValue(
                                `${fieldId(rf.mrn)}.rawMagnitude`,
                                formatMagnitude(min),
                                {
                                  shouldDirty: true,
                                },
                              );
                            }}
                          >
                            {formatMagnitude(min)}%
                          </Typography>
                          <Controller
                            name={`${fieldId(rf.mrn)}.magnitude`}
                            control={control}
                            render={({ field }) => (
                              <Slider
                                {...field}
                                min={min}
                                max={max}
                                marks={marks}
                                sx={{ width: "226px" }}
                                onChange={(e, v) => {
                                  field.onChange(e, v);
                                  setValue(
                                    `${fieldId(rf.mrn)}.rawMagnitude`,
                                    formatMagnitude(v as number),
                                  );
                                }}
                              />
                            )}
                          />
                          <Typography
                            fontSize={14}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setValue(`${fieldId(rf.mrn)}.magnitude`, max, {
                                shouldDirty: true,
                              });
                              setValue(
                                `${fieldId(rf.mrn)}.rawMagnitude`,
                                formatMagnitude(max),
                                {
                                  shouldDirty: true,
                                },
                              );
                            }}
                          >
                            {formatMagnitude(max)}%
                          </Typography>
                        </Box>
                        <Controller
                          name={`${fieldId(rf.mrn)}.rawMagnitude`}
                          control={control}
                          rules={{
                            required: "Required",
                            validate: {
                              numberWithinRange: (v) => {
                                const n = parseInt(v);
                                const isNumber = !isNaN(n);
                                const numberWithinRange =
                                  isNumber && n >= min && n <= max;
                                return (
                                  numberWithinRange ||
                                  `Must be a number between ${min} and ${max}`
                                );
                              },
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              size="small"
                              sx={{ width: "80px" }}
                              InputProps={{
                                sx: {
                                  "&, .MuiTypography-root": {
                                    fontSize: 14,
                                  },
                                },
                                endAdornment: (
                                  <InputAdornment position="end" children="%" />
                                ),
                              }}
                              onChange={(e) => {
                                field.onChange(e);
                                const magnitude = parseInt(e.target.value);
                                if (!isNaN(magnitude)) {
                                  setValue(
                                    `${fieldId(rf.mrn)}.magnitude`,
                                    magnitude,
                                    { shouldDirty: true },
                                  );
                                }
                              }}
                              error={Boolean(
                                errors[`${fieldId(rf.mrn)}`]?.rawMagnitude,
                              )}
                            />
                          )}
                        />

                        <FormControlLabel
                          control={
                            <Controller
                              name={`${fieldId(rf.mrn)}.isToxic`}
                              control={control}
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  {...field}
                                  size="small"
                                />
                              )}
                            />
                          }
                          label="Critical risk"
                          sx={{
                            mr: 0,
                            ".MuiFormControlLabel-label": {
                              fontSize: 14,
                              whiteSpace: "nowrap",
                            },
                          }}
                        />
                      </Box>
                    )}
                  </Fragment>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
