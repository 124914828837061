import { useSearchParams } from "react-router-dom";
import {
  AggregateScoreType,
  FindingsFilter,
  FindingsOrderField,
  FindingType,
  OrderDirection,
  ScoreStateFilter,
  useGetAffectedAssetsQuery,
  useGetAggregateScoresQuery,
  useLoadMqueryQuery,
} from "~/operations";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { ScoreBlastRadius } from "~/pages/space/security/components/Check/ScoreBlock/ScoreBlastRadius";
import { getCheckStats } from "~/pages/space/security/components/Check/utils";
import { useReportingPageLoaded } from "~/pages/reporting/reports/hooks/useReportingPageLoaded";
import { AffectedAssetsEdge } from "~/pages/space/vulnerabilities/components/AffectedAssets";

type UseCheckReportParams = {};

export function useCheckReport({}: UseCheckReportParams) {
  const [searchParams] = useSearchParams();
  const scopeMrn = searchParams.get("scopeMrn") || "";
  const findingMrn = searchParams.get("findingMrn") || "";

  const {
    data: checkData,
    loading: checkLoading,
    error: checkError,
    networkStatus: mqueryNetworkStatus,
  } = useLoadMqueryQuery({
    variables: {
      input: {
        mrn: findingMrn,
      },
    },
    skip: !findingMrn,
  });

  const check = checkData?.mquery;
  const title = check?.title;
  const description = check?.docs?.desc;

  const {
    data: aggData,
    loading: aggLoading,
    error: aggError,
    networkStatus: checkAggregateScoreNetworkStatus,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: scopeMrn,
      filter: {
        findingMrn: findingMrn,
        scoreType: AggregateScoreType.Check,
      },
    },
    skip: !scopeMrn,
  });

  const commonFilter: FindingsFilter = {
    types: [FindingType.Check],
    mrn: check?.mrn,
    queryTerms: [],
  };

  const commonOrderBy = {
    field: FindingsOrderField.RiskScore,
    direction: OrderDirection.Asc,
  };

  const {
    data: failedAffectedAssetsData,
    error: failedAffectedAssetsError,
    loading: failedAffectedAssetsLoading,
    networkStatus: failedAffectedAssetsNetworkStatus,
  } = useGetAffectedAssetsQuery({
    variables: {
      scopeMrn,
      orderBy: commonOrderBy,
      filter: {
        ...commonFilter,
        state: ScoreStateFilter.Open,
      },
    },
    skip: Boolean(!check?.mrn),
  });

  const {
    data: passedAffectedAssetsData,
    error: passedAffectedAssetsError,
    loading: passedAffectedAssetsLoading,
    networkStatus: passedAffectedAssetsNetworkStatus,
  } = useGetAffectedAssetsQuery({
    variables: {
      scopeMrn,
      orderBy: commonOrderBy,
      filter: {
        ...commonFilter,
        state: ScoreStateFilter.Closed,
      },
    },
    skip: Boolean(!check?.mrn),
  });

  const aggregateNodeData =
    aggData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? aggData.aggregateScores.edges?.at(0)?.node
      : undefined;

  const riskScore = aggregateNodeData?.riskScore;
  const rating = aggregateNodeData?.rating;
  const cvss = aggregateNodeData?.cvss;

  const remediations =
    check?.docs?.remediations?.__typename === "Remediations"
      ? check?.docs?.remediations
      : { entries: [] };

  const hasRemediations =
    remediations.entries.length > 0 && (check?.variants || []).length === 0;

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
    networkStatuses: riskFactorsNetworkStatuses,
  } = useFindingRiskFactors({
    spaceMrn: scopeMrn,
    findingMrn: findingMrn || "",
    scoreType: AggregateScoreType.Check,
  });

  const networkStatuses = [
    mqueryNetworkStatus,
    checkAggregateScoreNetworkStatus,
    failedAffectedAssetsNetworkStatus,
    passedAffectedAssetsNetworkStatus,
    ...riskFactorsNetworkStatuses,
  ];

  // Determine if all network requests are ready
  useReportingPageLoaded(networkStatuses);

  const blastRadius = aggregateNodeData?.blastRadius;

  const stats = getCheckStats({ blastRadius });

  const failedFindingsUnion =
    failedAffectedAssetsData?.findings?.__typename === "FindingsConnection"
      ? failedAffectedAssetsData.findings
      : undefined;
  const failedCheckEdges = failedFindingsUnion?.edges || [];

  const passedFindingsUnion =
    passedAffectedAssetsData?.findings?.__typename === "FindingsConnection"
      ? passedAffectedAssetsData.findings
      : undefined;
  const passedCheckEdges = passedFindingsUnion?.edges || [];

  function mapCheckScoresToAffectedAssets(checkScores: AffectedAssetsEdge[]) {
    return checkScores?.flatMap(({ node }) => {
      if (!node || node.__typename !== "CheckFinding") return [];

      const asset = {
        id: node?.asset?.id || "",
        mrn: node?.asset?.mrn || "",
        score: node.riskScore || 0,
        lastUpdated: node.lastUpdated,
        riskFactors: node.riskFactors,
        title: node?.asset?.name || "",
        iconId: node?.asset?.icon,
        tags: node?.asset?.tags,
        rating: node.rating,
      };

      return [asset];
    });
  }

  const failedAssets = mapCheckScoresToAffectedAssets(failedCheckEdges || []);
  const passedAssets = mapCheckScoresToAffectedAssets(passedCheckEdges || []);

  const mappings = [
    { group: "Risk Factors", value: riskFactors?.length || 0 },
    ...(aggregateNodeData?.blastRadius && aggregateNodeData.rating
      ? [
          {
            group: "Blast Radius",
            chart: (
              <ScoreBlastRadius
                blastRadius={aggregateNodeData?.blastRadius}
                score={aggregateNodeData?.riskScore || 0}
                rating={aggregateNodeData?.rating}
              />
            ),
            value: aggregateNodeData.blastRadius.affected || 0,
          },
        ]
      : []),
  ];

  return {
    isLoading:
      checkLoading ||
      aggLoading ||
      passedAffectedAssetsLoading ||
      failedAffectedAssetsLoading ||
      riskFactorsLoading,
    hasError:
      checkError ||
      aggError ||
      passedAffectedAssetsError ||
      failedAffectedAssetsError,
    title,
    description,
    stats,
    riskScore,
    rating,
    cvss,
    remediations,
    hasRemediations,
    riskFactorsWithDocs,
    mappings,
    failedAssets,
    passedAssets,
  };
}
