import { ReactNode } from "react";
import { Box, Grid2, Typography } from "@mui/material";
import { ShowDetails } from "~/components/ShowDetails/ShowDetails";
import { Flex } from "~/components/Flex";
import { isFeatureEnabled } from "~/login/features";
import { ExportButton } from "~/pages/compliance/components/export-framework";
import { DocumentType } from "~/operations";
import { Space } from "~/lib/types";

type OverviewHeaderProps = {
  title: ReactNode | string;
  description?: ReactNode | string;
  additionalContent?: ReactNode;
  space?: Space;
};

export const DashboardHeader = ({
  title,
  description,
  additionalContent,
  space,
}: OverviewHeaderProps) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Grid2 size={12}>
        <Flex justifyContent="space-between" alignItems="center">
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", textTransform: "uppercase" }}
          >
            {title}
          </Typography>
          {isFeatureEnabled("Reporting") && space && (
            <ExportButton
              documentType={DocumentType.SecurityReport}
              title={"Generated Security Report"}
              space={space}
            />
          )}
        </Flex>
      </Grid2>

      <Box sx={{ color: "text.secondary" }}>
        <ShowDetails
          children={description}
          additionalContent={additionalContent}
        />
      </Box>
    </Box>
  );
};
