import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { TestIamActionsQuery } from "~/operations";

export type FindingsPageProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function FindingsPage({}: FindingsPageProps) {
  document.title = "Findings · Mondoo";

  return (
    <Box>
      <Outlet />
    </Box>
  );
}
