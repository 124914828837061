import { Box, Container, Grid2, Typography } from "@mui/material";
import { spacing } from "~/pages/reporting/reports/lib/SharedVariables";
import { SectionHeading } from "~/pages/reporting/components";
import { Stats } from "~/components/DetailsPage/components";
import { PrintScoreBlock } from "~/pages/reporting/reports/components";
import {
  CvssRiskContent,
  CvssRiskProvider,
  CvssVector,
} from "~/components/vulnerabilities";
import { RiskFactors } from "~/pages/reporting/reports/AssetReport/RiskFactors";
import { Markdown } from "~/components/markdown";
import { AffectedAssets } from "./AffectedAssets";
import { useCheckReport } from "~/pages/reporting/reports/CheckReport/hooks";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";

export const CheckReport = () => {
  const {
    title,
    description,
    hasRemediations,
    riskScore,
    rating,
    cvss,
    stats,
    failedAssets,
    passedAssets,
    riskFactorsWithDocs,
    mappings,
    remediations,
    isLoading,
    hasError,
  } = useCheckReport({});

  if (isLoading) {
    return <LoadingPage what="Check Report" />;
  }

  if (hasError) {
    return <LoadingFailedPage what="Check Report" />;
  }

  return (
    <Container sx={{ p: 4 }}>
      <Grid2 container>
        <Grid2 size={12} sx={{ mb: spacing[50] }}>
          <Typography variant="h4" fontWeight={700}>
            {title}
          </Typography>
        </Grid2>
        <Grid2 size={12}>
          <SectionHeading heading="Summary" disableUpperPadding />
        </Grid2>
        <Grid2
          container
          size={12}
          spacing={spacing[25]}
          sx={{ justifyContent: "space-between", mb: spacing[25] }}
        >
          <Grid2 size="grow">
            <Stats id="detail-content-stats" stats={stats} />
            {description && (
              <Markdown
                source={description}
                externalLinksInNew
                copyButton={false}
                sx={{
                  my: spacing[25],
                  "& p": {
                    fontSize: 14,
                    fontFamily: "Roboto",
                    lineHeight: "1.43",
                  },
                }}
              />
            )}
          </Grid2>
          <Grid2
            size={4}
            sx={{
              display: "flex",
              justifyContent: "right",
            }}
          >
            {riskScore != null && rating && (
              <PrintScoreBlock
                mappings={mappings}
                ratingValues={{
                  value: riskScore,
                  rating: rating,
                }}
              />
            )}
          </Grid2>
        </Grid2>

        <Grid2 size={12} sx={{ mb: spacing[50] }}>
          <RiskFactors dataType="check" riskFactors={riskFactorsWithDocs} />
        </Grid2>

        {cvss?.value && (
          <Grid2 size={12} sx={{ mb: spacing[50] }}>
            <CvssRiskProvider vector={cvss.vector} isPrintView>
              <SectionHeading heading="CVSS 3 risk graph" sx={{ pb: 0 }} />
              <Box sx={{ pt: 1, pb: spacing[25] }}>
                <CvssVector />
              </Box>
              <CvssRiskContent />
            </CvssRiskProvider>
          </Grid2>
        )}

        {hasRemediations && (
          <Grid2 size={12}>
            <SectionHeading heading="Remediation" />
            <Box>
              {remediations.entries.map((remediation) => (
                <Markdown
                  key={remediation.id}
                  source={remediation.desc}
                  externalLinksInNew
                  copyButton={false}
                />
              ))}
            </Box>
          </Grid2>
        )}

        {failedAssets.length > 0 && (
          <Grid2 size={12}>
            <AffectedAssets assets={failedAssets} heading="Failed assets" />
          </Grid2>
        )}

        {passedAssets.length > 0 && (
          <Grid2 size={12} sx={{ mb: spacing[50] }}>
            <AffectedAssets assets={passedAssets} heading="Passing assets" />
          </Grid2>
        )}
      </Grid2>
    </Container>
  );
};
