import { Grid } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { ResourcesIcon } from "~/components/icons/mondoo/resources";
import { GridSegment } from "../GridSegment";
import { Metrics } from "~/utils/arrow";
import { useMetrics } from "~/hooks";
import { useScope } from "~/hooks/useScope";

type Props = {
  sizes?: CardSizes;
};

export function PolicyDistributionCard({ sizes }: Props) {
  const { activeScope } = useScope();
  const policyCategoriesToDisplay = [
    "security",
    "compliance",
    "best-practices",
    "total",
  ];

  const { policyCategories, isLoading } = useMetrics({
    entityMrn: activeScope?.mrn || "",
    metricMrns: [Metrics.PolicyCategoryDistribution],
  });

  return (
    <HubCard
      {...{
        loading: isLoading,
        sizes,
        title: "Policy Distribution",
        icon: <ResourcesIcon fontSize="small" />,
      }}
      PaperProps={{ minHeight: 264 }}
      data-testid="policy-distribution-card"
    >
      <Grid container spacing={3} sx={{ pt: 2, justifyContent: "flex-end" }}>
        {policyCategories
          ?.filter((x) =>
            policyCategoriesToDisplay.includes(x.title.toLowerCase()),
          )
          ?.sort((a, b) => {
            return (
              policyCategoriesToDisplay.indexOf(a.title) -
              policyCategoriesToDisplay.indexOf(b.title)
            );
          })
          .map((data) => (
            <GridSegment key={data.title} data={data} showTotal={false} />
          ))}
      </Grid>
    </HubCard>
  );
}
