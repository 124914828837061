import { Button, Paper, Typography } from "@mui/material";
import { Flex } from "~/components/Flex";
import { Link as RouterLink } from "react-router-dom";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";
import { CasesSilhouette } from "../CreateTicketingIntegration/CasesSilhouette";

type CreateFirstCaseProps = {
  scope: SpaceOrWorkspaceScope;
};

export const CreateFirstCase = ({ scope }: CreateFirstCaseProps) => {
  return (
    <Paper
      sx={{ width: "100%", display: "flex", justifyContent: "center", p: 5 }}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        sx={{ maxWidth: "700px" }}
      >
        <CasesSilhouette
          sx={{ width: 335, height: 225, borderRadius: "4px" }}
          hasStepNumber={false}
        />
        <Typography mt={2} textAlign="center" fontSize={24} fontWeight={700}>
          Time to create your first ticket!
        </Typography>
        <Typography mt={2} color="text.secondary" textAlign="center">
          You're ready to get started with ticketing! When you see a finding you
          want your team to fix, create a new ticket to track progress.
        </Typography>
        <Button
          component={RouterLink}
          to={`/space/security?${scope.params}`}
          variant="contained"
          color="primary"
          sx={{ mt: 5 }}
        >
          GO TO SECURITY
        </Button>
      </Flex>
    </Paper>
  );
};
