import { alpha, Divider, Grid, Typography } from "@mui/material";
import { Header } from "~/components/DetailsPage/components/Header";
import { useCaseDetailsPage } from "~/components/cases/hooks";
import { Space } from "~/lib/types";
import { Flex } from "~/components/Flex";
import { LoadingButton } from "~/components/loading-button";
import { TestIamActionsQuery } from "~/operations";
import {
  CaseActivitiesSection,
  CaseDetailsBreadcrumbs,
  ContainedFindingsSection,
  HeaderTags,
} from "./components";
import { ScrollToTop } from "~/lib/scroll-to-top";
import { AffectedAssets } from "~/components/cases/components/CaseDetailsPage/components/AssetsSection/AffectedAssets";
import { MitigatedAssets } from "~/components/cases/components/CaseDetailsPage/components/AssetsSection/MitigatedAssets";
import { CaseTickets } from "~/components/cases/components/CaseTickets";
import { LoadingFailed } from "~/components/loading";
import { EmptySection } from "~/components/vulnerabilities";
import { SpaceOrWorkspaceScope } from "~/hooks/useScope";

type CaseDetailsPage = {
  space: Space;
  scope: SpaceOrWorkspaceScope;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const CaseDetailsPage = ({
  scope,
  space,
  availablePermissions,
}: CaseDetailsPage) => {
  const {
    caseDetails,
    isLoading,
    isCloseCaseLoading,
    handleCloseCase,
    finding,
    affectedAssetsProps,
    mitigatedAssetsProps,
    tickets,
    caseError,
    totalRefsCount,
    closedRefsCount,
  } = useCaseDetailsPage({ availablePermissions, scope });

  if (caseError || (!caseDetails && !isLoading)) {
    return <LoadingFailed what="Case" />;
  }

  return (
    <Grid container>
      <ScrollToTop />
      <CaseDetailsBreadcrumbs scope={scope} title={caseDetails?.title || ""} />
      <Grid item xs={12}>
        <Header
          id="case-title"
          title={caseDetails?.title || ""}
          created={caseDetails?.createdAt}
          lastModified={
            caseDetails?.status !== "CLOSED"
              ? caseDetails?.modifiedAt
              : undefined
          }
          tags={
            <HeaderTags
              totalAssets={caseDetails?.assetsCount || 0}
              createdBy="Mondoo (Automatic)"
              closedAt={
                caseDetails?.status === "CLOSED"
                  ? caseDetails?.modifiedAt
                  : undefined
              }
            />
          }
          rightSection={
            <>
              <Divider orientation="vertical" flexItem sx={{ mx: 4 }} />
              {caseDetails?.status === "OPEN" ? (
                <LoadingButton
                  variant="outlined"
                  loading={isCloseCaseLoading}
                  color="primary"
                  onClick={handleCloseCase}
                  data-testid="close-case"
                  buttonText="Close Ticket"
                  sx={{
                    background: (theme) =>
                      alpha(theme.palette.primary.main, 0.2),
                    borderColor: (theme) => theme.palette.primary.main,
                    color: "text.primary",
                  }}
                />
              ) : (
                <Flex justifyContent="flex-end">
                  <Typography color="text.disabled" fontSize={13}>
                    CLOSED
                  </Typography>
                </Flex>
              )}
            </>
          }
        />
      </Grid>
      <CaseTickets tickets={tickets} />
      <CaseActivitiesSection
        totalRefs={totalRefsCount}
        fixedRefs={closedRefsCount}
      />
      {finding?.node && totalRefsCount > 0 && (
        <ContainedFindingsSection
          space={space}
          pageType={finding.node.scoreType}
          finding={finding}
          scope={scope}
          loading={isLoading}
        />
      )}
      {totalRefsCount > 0 ? (
        <AffectedAssets {...affectedAssetsProps} />
      ) : (
        <Grid item xs={12}>
          <EmptySection
            id="no-affected-assets"
            text="All assets that were appended to this case have been deleted."
          />
        </Grid>
      )}
      {totalRefsCount > 0 && <MitigatedAssets {...mitigatedAssetsProps} />}
    </Grid>
  );
};
