import { Grid, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import {
  CheckFindingsCheckNode,
  ListPolicyEdge,
} from "~/pages/inventory/asset/types";
import {
  FindingsOrderField,
  FindingType,
  OrderDirection,
  useGetChecksQuery,
} from "~/operations";
import { CheckResultIcon } from "./CheckResultIcon";
import { Loading } from "../loading";
import { FailedChecks } from "./FailedChecks";
import { PrintPolicySummaryHeader } from "./PrintPolicySummaryHeader";

type PrintPolicyReportProps = {
  listPolicyEdge: ListPolicyEdge;
  assetMrn: string;
};

export function PrintPolicyReport({
  listPolicyEdge,
  assetMrn,
}: PrintPolicyReportProps) {
  const { data, loading } = useGetChecksQuery({
    variables: {
      scopeMrn: assetMrn,
      first: 100,
      after: null,
      orderBy: {
        field: FindingsOrderField.RiskScore,
        direction: OrderDirection.Asc,
      },
      filter: {
        policyMrn: listPolicyEdge.node.mrn,
        types: [FindingType.Check],
      },
    },
  });

  if (loading || !data) {
    return (
      <Grid
        container
        item
        sx={{
          pb: 4,
          borderBottom: "1px solid #DBDADD",
        }}
      >
        <Grid item xs>
          <Loading what="policy" />
        </Grid>
      </Grid>
    );
  }

  const findingsUnion =
    data?.findings?.__typename === "FindingsConnection"
      ? data.findings
      : undefined;
  const checkEdges = findingsUnion?.edges || [];
  const checkFindings: CheckFindingsCheckNode[] = checkEdges.flatMap((e) =>
    e?.node?.__typename === "CheckFinding" ? e.node : [],
  );

  return (
    <Grid container item>
      <PrintPolicySummaryHeader listPolicyEdge={listPolicyEdge} />
      {/* Checks */}
      <Grid
        item
        xs={12}
        sx={{
          py: 4,
          px: 4,
          borderBottom: "1px solid #DBDADD",
        }}
      >
        <Typography variant="h5" component="h4">
          Checks
        </Typography>
        <List>
          {checkFindings?.map((check) => {
            return (
              <ListItem disableGutters key={check?.mrn}>
                <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                  <CheckResultIcon
                    result={check?.resultType}
                    scoreState={check?.state}
                  />
                </ListItemIcon>
                <Typography variant="body2">{check?.title}</Typography>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      {/* failed Checks */}
      <FailedChecks assetMrn={assetMrn} listPolicyEdge={listPolicyEdge} />
    </Grid>
  );
}
