import { FirewatchList } from "~/components/FirewatchList";
import { HubCard } from "~/components/hub-card";
import { ChecklistIcon } from "~/components/icons";
import { useScope } from "~/hooks/useScope";
import { Space } from "~/lib/types";
import {
  AggregateScoreOrder,
  AggregateScoreOrderField,
  OrderDirection,
  useGetAggregateScoresQuery,
  AggregateScoreType,
} from "~/operations";
import { useSort } from "~/pages/inventory/hooks/useSort";

type TopSecurityFindingsCardProps = { space: Space };

export function TopSecurityFindingsCard({
  space,
}: TopSecurityFindingsCardProps) {
  const { activeScope, spaceScope } = useScope();

  const { orderBy } = useSort<AggregateScoreOrder>({
    defaultSort: {
      field: AggregateScoreOrderField.Rank,
      direction: OrderDirection.Asc,
    },
  });

  const {
    data: checkData,
    error: checkError,
    loading: checkLoading,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: activeScope?.mrn || "",
      first: 10,
      after: null,
      filter: {
        maxRiskScore: 99,
        scoreType: AggregateScoreType.Check,
      },
      orderBy,
    },
  });

  const {
    data: cveData,
    error: cveError,
    loading: cveLoading,
  } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: activeScope?.mrn || "",
      first: 10,
      after: null,
      filter: {
        maxRiskScore: 99,
        scoreType: AggregateScoreType.Vulnerability,
      },
      orderBy,
    },
  });

  const loading = checkLoading || cveLoading;
  const error = checkError || cveError;

  const checkEdges =
    checkData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? checkData.aggregateScores.edges
      : [];
  const cveEdges =
    cveData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? cveData.aggregateScores.edges
      : [];

  const edges = [
    ...(checkEdges ? checkEdges : []),
    ...(cveEdges ? cveEdges : []),
  ];

  //   sort edges by rank_score, descending, and limit to 10
  const mashedEdges = edges
    .sort((a, b) => {
      const aScore = a.node?.rankScore || 0;
      const bScore = b.node?.rankScore || 0;
      return bScore - aScore;
    })
    .slice(0, 10);

  return (
    <HubCard
      loading={loading}
      title="Top Security Findings"
      icon={<ChecklistIcon fontSize="small" />}
      //   successCard={null}
      sx={{ height: "100%", minHeight: 346 }}
      data-testid="top-security-findings-card"
    >
      <FirewatchList
        size="small"
        field={orderBy.field}
        direction={orderBy.direction}
        {...{
          space: space,
          scope: spaceScope,
          pageType: "TopSecurityFindings",
          data: mashedEdges,
          handleSortClick: () => {},
          loading,
          orderBy,
          disableSorting: true,
        }}
      />
    </HubCard>
  );
}
