import { Fragment, ReactNode, useCallback } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { EmptyStateIcon } from "./empty-state-icon";
import { Space } from "~/lib/types";
import { ComplianceFramework } from "~/operations";
import { EmptyExceptionsIcon } from "./empty-exceptions-icon";
import { docsHref } from "~/lib/docs-href";
import {
  ClipboardPassIcon,
  DataObjectIcon,
  NavDrawerFleetIcon,
  NavDrawerVulnerabilitiesIcon,
} from "../icons";
import { ExceptionTarget } from "~/components/exceptions/types";
import { renderAssetEmptyStateContent } from "~/pages/inventory/asset/utils";
import { organizationSpacesEmptyStateContent } from "~/pages/organization/utils/emptyStateContent";
import EmptyAddButton from "~/images/empty-add-button.png";
import { CheckClipboardIcon } from "../icons/mondoo/dashboard";

type Props = {
  space?: Space;
  frameworkMrn?: ComplianceFramework["mrn"];
};

type actionButton = {
  to: string;
  text: string;
};

type EmptyStateContent = {
  icon: ReactNode;
  headline: string;
  textContent?: ReactNode;
  actionButtons?: actionButton[];
};

export function useEmptyState({ space, frameworkMrn }: Props) {
  const emptyStateContent = useCallback(
    (contentType: string) => {
      return getContent(contentType);
    },
    [frameworkMrn, space],
  );

  function renderExceptionsEmptyStateContent({
    target,
  }: {
    target: ExceptionTarget | "framework";
  }): EmptyStateContent {
    const targetLabelMap: Record<typeof target, string> = {
      check: "these checks",
      control: "these controls",
      framework: "this framework",
      asset: "this asset",
      advisory: "this advisory",
      cve: "this CVE",
    };

    return {
      icon: <EmptyExceptionsIcon sx={{ fontSize: 38 }} />,
      headline: `There are no exceptions to ${targetLabelMap[target]}.`,
      textContent: (
        <Fragment>
          <Typography color="text.secondary" sx={{ textAlign: "center" }}>
            Mondoo lets you disable and snooze{" "}
            {target === "framework"
              ? "controls in a framework"
              : targetLabelMap[target]}
            .
          </Typography>
          <Typography color="text.secondary" sx={{ textAlign: "center" }}>
            To learn about these exceptions and how to set them, read the{" "}
            <Link
              href={docsHref("/platform/compliance/customize/exceptions/")}
              target="_blank"
              rel="noopener"
            >
              Mondoo documentation
            </Link>
            .
          </Typography>
        </Fragment>
      ),
    };
  }

  const getContent = (contentType: string): EmptyStateContent => {
    switch (contentType) {
      case "security-policy-assets":
      case "control-assets":
      case "framework-assets":
        return {
          icon: <EmptyStateIcon sx={{ fontSize: 38 }} />,
          headline: "There are no assets to show yet.",
          textContent: (
            <Fragment>
              <Typography color="text.secondary" sx={{ textAlign: "center" }}>
                After you add integrations, assets will appear here.
              </Typography>
            </Fragment>
          ),
          actionButtons: space && [
            {
              text: "Add Integration",
              to: `/space/integrations/add?spaceId=${space.id}`,
            },
          ],
        };

      case "data-query-assets":
        return {
          icon: <EmptyStateIcon sx={{ fontSize: 38 }} />,
          headline: "There are no assets to show yet.",
          textContent: (
            <Fragment>
              <Typography color="text.secondary" sx={{ textAlign: "center" }}>
                After you add integrations, assets will appear here.
              </Typography>
            </Fragment>
          ),
          actionButtons: space && [
            {
              text: "Add Integration",
              to: `/space/integrations/add?spaceId=${space.id}`,
            },
          ],
        };

      case "control-checks":
        const frameworkId = useParams<{ frameworkId: string }>().frameworkId;
        return {
          icon: <EmptyStateIcon sx={{ fontSize: 38 }} />,
          headline: "There are no checks for this control.",
          textContent: (
            <Fragment>
              <Typography color="text.secondary" sx={{ textAlign: "center" }}>
                Checks exist in policies. Enable one or more recommended
                policies
              </Typography>
              <Typography color="text.secondary" sx={{ textAlign: "center" }}>
                to see checks for this control.
              </Typography>
            </Fragment>
          ),
          // if space, frameworkId, frameworkMrn exists, show actionButton
          ...(space &&
            frameworkId &&
            frameworkMrn && {
              actionButtons: [
                {
                  text: "View Policies",
                  to: `/space/compliance/${frameworkId}/policies?spaceId=${space.id}&frameworkMrn=${frameworkMrn}`,
                },
              ],
            }),
        };

      case "asset-checks-tab":
        return renderAssetEmptyStateContent({ target: "checks", space: space });

      case "asset-policies-tab":
        return renderAssetEmptyStateContent({
          target: "policies",
          space: space,
        });

      case "control-exceptions":
        return renderExceptionsEmptyStateContent({ target: "control" });

      case "check-exceptions":
        return renderExceptionsEmptyStateContent({ target: "check" });

      case "framework-exceptions":
        return renderExceptionsEmptyStateContent({ target: "framework" });

      case "data-queries":
        return {
          icon: <DataObjectIcon sx={{ fontSize: 38, color: "primary.main" }} />,
          headline: "There are no data queries to show.",
          textContent: (
            <Fragment>
              <Typography color="text.secondary" sx={{ textAlign: "center" }}>
                Don't worry; not all controls include data queries.
              </Typography>
            </Fragment>
          ),
          actionButtons: space && [
            {
              text: "Enable Query Packs",
              to: `/space/query-packs/add?spaceId=${space.id}`,
            },
          ],
        };

      case "policy-assets":
        return {
          icon: (
            <NavDrawerFleetIcon sx={{ fontSize: 38, color: "primary.main" }} />
          ),
          headline: `There are 0 assets this check applies to.`,
          textContent: (
            <Typography color="text.secondary" sx={{ textAlign: "center" }}>
              If you feel there should be something here{", "}
              <Link
                key="/space/integrations"
                component={RouterLink}
                to={`/space/integrations?spaceId=${space?.id}`}
                sx={{ textDecoration: "underline" }}
                color="text.secondary"
              >
                check your integrations
              </Link>
              .
            </Typography>
          ),
        };

      case "CVEs":
      case "advisories":
      case "affected assets":
        return {
          icon: (
            <NavDrawerVulnerabilitiesIcon
              sx={{ fontSize: 38, color: "primary.main" }}
            />
          ),
          headline: `No ${contentType} found.`,
        };

      case "organization-spaces":
        return organizationSpacesEmptyStateContent;

      case "policies":
        return {
          icon: <Box component="img" src={EmptyAddButton} width={380} />,
          headline: "Time to add your first policy!",
          textContent: (
            <Typography color="text.secondary" sx={{ textAlign: "center" }}>
              Add policies that match your infrastructure to your space, and run
              them in preview or scored mode
              <br /> to improve your security posture and benchmark your
              successes
            </Typography>
          ),
        };
      case "query-packs":
        return {
          icon: <Box component="img" src={EmptyAddButton} width={380} />,
          headline: "Time to add your first query pack!",
          textContent: (
            <Typography color="text.secondary" sx={{ textAlign: "center" }}>
              Add query packs that match your infrastructure to your space,
              <br /> and gain unprecedented insight into your infrastructure's
              metadata
            </Typography>
          ),
        };

      case "integrations-finalize":
        return {
          icon: <ClipboardPassIcon sx={{ fontSize: 38, color: "none.main" }} />,
          headline: "All recommended policies are enabled!",
          textContent: (
            <Fragment>
              <Typography color="text.secondary" sx={{ textAlign: "center" }}>
                Browse our policies and query packs to find more content that
                matches your security goals and infrastructure
              </Typography>
            </Fragment>
          ),
          actionButtons: space && [
            {
              text: "Browse Policies",
              to: `/space/findings/policies/add?spaceId=${space.id}`,
            },
            {
              text: "Browse Query Packs",
              to: `/space/inventory/query-packs/add?spaceId=${space.id}`,
            },
          ],
        };

      case "detected-assets":
        return {
          icon: <EmptyStateIcon sx={{ fontSize: 38 }} />,
          headline: "No detected assets found.",
        };

      default:
        return {
          icon: <EmptyStateIcon sx={{ fontSize: 38 }} />,
          headline: "No results found.",
        };
    }
  };

  return { emptyStateContent };
}
