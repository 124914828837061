import { SmallDonutCard } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { AdvisoriesIcon } from "~/components/icons";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";

export function AdvisoriesDonutChart({ scope }: DonutChartCardProps) {
  const { advisoriesSeverity, isLoading } = useMetrics({
    entityMrn: scope?.mrn || "",
    metricMrns: [Metrics.AdvisoriesSeverity],
  });

  return (
    <SmallDonutCard
      to={`advisories?${scope?.params?.toString()}`}
      loading={isLoading}
      data={advisoriesSeverity}
      data-testid="advisories-donut-chart"
      title="Advisories"
      icon={<AdvisoriesIcon />}
    />
  );
}
