import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { LibraryHref } from "~/lib/libraryhref";
import { ImpactCell } from "./ImpactCell";
import { TitleCell } from "./TitleCell";
import { PublishedCell } from "./PublishedCell";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { Header } from "~/types/table";
import { FindingsOrder, PageInfo, useGetCvEsQuery } from "~/operations";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { useState } from "react";
import { RiskFactorsCell } from "~/components/Cells";
import { CVEFindingsNode } from "~/pages/inventory/components/Vulnerabilities/types";

type VulnerabilitiesTableProps = {
  onSort: (id: string) => void;
  orderBy: FindingsOrder;
  fetchMore: ReturnType<typeof useGetCvEsQuery>["fetchMore"];
  cveFindings: CVEFindingsNode[];
  pageInfo: PageInfo | undefined;
  totalCount: number;
};

export const VulnerabilitiesTable = ({
  onSort,
  orderBy,
  fetchMore,
  cveFindings,
  pageInfo,
  totalCount,
}: VulnerabilitiesTableProps) => {
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);

  const cves = cveFindings.slice(pageItems.from, pageItems.to);

  const tableHeaders: Header[] = [
    {
      id: "RISK_SCORE",
      label: "Risk",
      options: { width: "10%" },
    },
    {
      id: "ID",
      label: "Vulnerability",
      options: { width: "50%" },
      sortable: false,
    },
    {
      id: "RISKFACTORS",
      label: "Risk factors",
      sortable: false,
    },
    {
      id: "PUBLISHED_DATE",
      label: "Published",
      options: { width: "15%" },
      // Backend does not support sorting by this column yet
      sortable: false,
    },
  ];

  return (
    // TODO @JAMIE: unite with AdvisoriesTable
    <Paper sx={{ my: 3 }}>
      <TableContainer>
        <Table>
          <TableHead
            tableHeaders={tableHeaders}
            onSort={onSort}
            orderBy={orderBy}
          />
          <TableBody>
            {cves.map((cve) => {
              return (
                <TableRow
                  id={cve.id}
                  key={cve.id}
                  component={RouterLink}
                  to={LibraryHref("cve", cve.id)}
                  sx={{
                    "&.MuiTableRow-root:hover .firewatch-chip": {
                      backgroundColor: "background.lighter",
                    },
                  }}
                >
                  <ImpactCell data-testid="cve-impact" cve={cve} />
                  <TitleCell data-testid="cve-title" cve={cve} />
                  <RiskFactorsCell
                    data-testid="cve-risk-factors"
                    riskFactors={cve.riskFactors}
                  />
                  <PublishedCell data-testid="cve-published" cve={cve} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        fetchMore={fetchMore}
        pageInfo={pageInfo}
        totalCount={totalCount}
        setPageItems={setPageItems}
      />
    </Paper>
  );
};
