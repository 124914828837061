import {
  Box,
  Grid2,
  GridBaseProps,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { ScoreSource } from "~/operations";
import {
  mapDetectedByIcon,
  mapDetectedByKeys,
  mapDetectedByToolName,
  mapDetectedByValues,
} from "./lib";
import { FirstToFindTrophy } from "./FirstToFindTrophy";
import { useDetectedBy } from "./hooks/useDetectedBy";
import { Fragment } from "react/jsx-runtime";

type DetectedByProps = {
  detectedBy: ScoreSource[] | undefined;
  widthOverride?: GridBaseProps["size"];
};

export function DetectedBy({ detectedBy, widthOverride }: DetectedByProps) {
  if (!detectedBy || detectedBy.length === 0) {
    return <Fragment key="no-detected-sources" />;
  }

  const { firstFound, orderSources } = useDetectedBy({ detectedBy });
  const sizes = widthOverride ? widthOverride : { xs: 12, md: 6 };

  return (
    <Grid2 size={sizes}>
      <Typography sx={{ mb: 2.5, textTransform: "uppercase", fontWeight: 700 }}>
        Detected By
      </Typography>
      {detectedBy.map((source) => {
        const { __typename, name, ...sources } = source;

        const orderedSources = orderSources(sources);
        const keyId = name.split(" ").join("-").toLowerCase();

        return (
          <List
            key={keyId}
            dense
            disablePadding
            subheader={
              <ListSubheader
                disableGutters
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  color: "text.primary",
                  background: "inherit",
                  fontWeight: 600,
                }}
              >
                {mapDetectedByIcon(name)}
                <Typography sx={{ fontSize: 16, fontWeight: "inherit" }}>
                  {mapDetectedByToolName(name)}
                </Typography>
              </ListSubheader>
            }
          >
            {orderedSources.map(({ key, value }) => (
              <ListItem
                key={key}
                disablePadding
                sx={{
                  ml: 4,
                  fontSize: 12,
                  color: "text.secondary",
                  fontWeight: 700,
                }}
              >
                <Grid2 container sx={{ width: 1 }}>
                  <Grid2 size={4}>
                    <ListItemText>{mapDetectedByKeys(key)}</ListItemText>
                  </Grid2>
                  <Grid2
                    size="auto"
                    sx={{
                      display: "inline-flex",
                      flexShrink: 0,
                      alignItems: "center",
                    }}
                  >
                    <ListItemText
                      sx={{
                        textAlign: "left",
                        mr: 1,
                      }}
                    >
                      {mapDetectedByValues(key, value)}
                    </ListItemText>
                    <FirstToFindTrophy
                      {...{ sourceKey: key, sourceValue: value, firstFound }}
                    />
                  </Grid2>
                </Grid2>
              </ListItem>
            ))}
          </List>
        );
      })}
    </Grid2>
  );
}
