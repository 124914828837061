import { SmallDonutCard, SmallDonutData } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { NavDrawerVulnerabilitiesIcon } from "~/components/icons";
import { useMetrics } from "~/hooks";
import { Metrics } from "~/utils/arrow";

export function VulnerabilitiesDonutChart({ scope }: DonutChartCardProps) {
  const { vulnerableAssetsSeverity, isLoading } = useMetrics({
    entityMrn: scope?.mrn || "",
    metricMrns: [Metrics.VulnerableAssetsSeverity],
  });

  return (
    <SmallDonutCard
      to={`affected-assets?${scope?.params?.toString()}`}
      data-testid="vulnerable-assets-donut-chart"
      loading={isLoading}
      data={vulnerableAssetsSeverity}
      title="Vulnerable Assets"
      icon={<NavDrawerVulnerabilitiesIcon />}
    />
  );
}
