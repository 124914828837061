import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Link,
  List,
  ListItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormGetFieldState,
  UseFormGetValues,
} from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { ChevronRightIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { getError } from "~/lib/handle-error";
import {
  helperTextStyles,
  isIpOrDomainDomainRangeOrEmpty,
  ValidationMessage,
} from "../../validations/helpers";
import { RecommendedPolicies } from "../../components/recommended-policies";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";
import { CircularIconButton } from "~/pages/compliance/components/CircularIconButton";
import { IconButtonType } from "~/pages/compliance/components/DynamicButtonIcon";
import { PasswordField } from "~/components/Form/components/PasswordField";

type ShodanTarget = { name: string; domain: string };

type ShodanFormInput = {
  integrationName: string;
  token: string;
  targets: ShodanTarget[];
};

const defaultValues: ShodanFormInput = {
  integrationName: "",
  token: "",
  targets: [{ name: "target1", domain: "" }],
};

export function ShodanIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState<"setup" | "add policies">("setup");
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getFieldState,
    getValues,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: "targets",
  });

  const [targets] = watch(["targets"]);
  // loop through each of the targets to check if there are any domains
  const hasTargets = targets?.some((target) => target.domain);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename !==
        "ShodanConfigurationOptions"
      )
        return;
      reset({
        integrationName: updateFlow.integration.name,
        token: "",
        targets: updateFlow.integration.configurationOptions.targets?.map(
          (target, index) => {
            return {
              target: `target${index + 1}`,
              domain: target,
            };
          },
        ),
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  // When a user clicks to add a new domain, we generate a new form field
  const generateDomainField = () => {
    append({ name: `target${fields.length + 1}`, domain: "" });
  };

  const onSubmit: SubmitHandler<ShodanFormInput> = async (data) => {
    const shodanConfigurationOptions = {
      token: data.token,
      // filter out empty domains and then map to only get the domain as an array of strings
      targets: data.targets
        .filter((target) => target.domain)
        .map((target) => target.domain),
    };

    try {
      if (updateFlow) {
        await updateIntegration({
          variables: {
            input: {
              name: data.integrationName.trim(),
              mrn: `//integration.api.mondoo.app/spaces/${
                space.id
              }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
              type: ClientIntegrationType.Shodan,
              configurationOptions: {
                shodanConfigurationOptions,
              },
            },
          },
        });
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/shodan/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Shodan,
              longLivedToken: false,
              configurationOptions: {
                shodanConfigurationOptions,
              },
            },
          },
        });
        setView("add policies");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  document.title = "Shodan · Integrations Setup · Mondoo";

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ type: IntegrationType.Shodan }} />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Choose an integration name
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Please choose a descriptive name that lets you easily
                    identify your integration.
                  </Typography>
                  <Controller
                    name="integrationName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your integration name..."
                        error={Boolean(errors.integrationName)}
                        helperText={
                          Boolean(errors.integrationName) && (
                            <ValidationMessage error={errors.integrationName} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide your Shodan API key
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    For more information, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/networking/shodan/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>
                  <Controller
                    name="token"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <PasswordField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder={"Your API key"}
                        error={
                          getFieldState("token").isTouched &&
                          Boolean(errors.token)
                        }
                        helperText={
                          Boolean(errors.token) &&
                          getFieldState("token").isTouched && (
                            <ValidationMessage error={errors.token} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 3 */}
              <Box pb={4}>
                <Command
                  number={3}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Add domains or IP addresses
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2, "& ul": { paddingInlineStart: 3.25 } }}
                  >
                    Add the domains and/or addresses to scan. You can enter any
                    of these:
                    <ul>
                      <li>Domain, such as www.example.com</li>
                      <li>Single IP address, such as 63.192.209.236</li>
                      <li>IP address block, such as 210.57.57.128/28</li>
                    </ul>
                    To add more, select the plus (+) icon.
                  </Typography>
                  <List>
                    {fields.map((item, index) => {
                      return (
                        <DomainField
                          key={item.id}
                          {...{
                            index,
                            name: `targets.${index}.domain`,
                            control,
                            getFieldState,
                            getValues,
                            errors,
                            hasTargets,
                            generateField: generateDomainField,
                          }}
                        />
                      );
                    })}
                  </List>
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  disabled={!isValid}
                >
                  {updateFlow ? "update configuration" : "start scanning"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <RecommendedPolicies {...{ space }} filterTypes={["shodan"]} />
      )}
    </Fragment>
  );
}

type DomainFieldProps = {
  name: `targets.${number}.domain`;
  index: number;
  control: Control<ShodanFormInput>;
  getFieldState: UseFormGetFieldState<ShodanFormInput>;
  getValues: UseFormGetValues<ShodanFormInput>;
  errors: FieldErrors<ShodanFormInput>;
  generateField: () => void;
  hasTargets: boolean;
};

export function DomainField({
  name,
  index,
  control,
  getFieldState,
  errors,
  generateField,
  hasTargets,
}: DomainFieldProps) {
  return (
    <ListItem disableGutters sx={{ gap: 2 }}>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: {
            required: (value) => {
              // if there are no domains and no IP addresses, show error
              if (!value && !hasTargets)
                return "At least one domain or IP address is required";
              return true;
            },
            // the domain may be empty if at least one domain exists
            matchIpOrDomain: isIpOrDomainDomainRangeOrEmpty,
          },
        }}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            fullWidth
            sx={{
              background: (theme) => theme.palette.code.background,
              borderRadius: 1,
              color: "text.primary",
              ...helperTextStyles,
            }}
            placeholder={"Your domain/IP address"}
            error={
              getFieldState(name).isTouched &&
              Boolean(errors.targets?.[index]?.domain)
            }
            helperText={
              Boolean(errors.targets?.[index]?.domain) &&
              getFieldState(name).isTouched && (
                <ValidationMessage error={errors.targets?.[index]?.domain} />
              )
            }
          />
        )}
      />
      <CircularIconButton
        title="Add another domain"
        iconType={IconButtonType.Plus}
        onClick={generateField}
      />
    </ListItem>
  );
}
